import { get, post, put, del, patch } from "../api_helper";
import * as url from "../url_helper";
import { convertObjectToParams } from "utils";

export const apiGetPartner = (data) => {
  let urlApi = url.API_PARTNER;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};

export const apiPatchPartner = (id, data) =>
  patch(url.API_PARTNER + id, data);
export const apiPutPartner = (data) => put(url.API_PARTNER, data);

export const apiCreatePartner = (data) => put(url.API_PARTNER, data);
export const apiDeletePartner = (id) => del(url.API_PARTNER + id);
