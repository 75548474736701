import React, { useState, useEffect } from "react";
import "./styles.scss";
import Select from "components/select/Select";
import { convertOption } from "utils";
import Menu from "components/menu/Menu";
import { useLocation } from "react-router-dom";
import { getPatients, getPatientByFacilities, resetPatientList } from "store/patients/actions";

import {
  getMaster,
  getGroupByFacility
} from "store/master/actions";
import { useDispatch } from "react-redux";
import MenuList from "components/menu/MenuList";
import MenuListAdmin from "components/menu/MenuListAdmin";
import MenuListPartner from "components/menu/MenuListPartner";
import PartnerApiHook from "hooks/partner/partnerApiHook";
import PartnerHook from "hooks/partner/partnerHook";
import FacilityHook from "hooks/facility/facilityHook";
import FacilityApiHook from "hooks/facility/facilityApiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import i18n from "../../i18n";
import { MenuOnlyFirst } from "components/menu/MenuOnlyFirst";
import HomeHook from "hooks/home/homeHook";


const langOpt = [
  { label: "JP", value: "JP" },
  { label: "KR", value: "KR" },
];

function AppHeader() {
  const { t } = useTranslation();
  const location = useLocation();
  const menuList = MenuList();
  const menuListAdmin = MenuListAdmin();
  const menuListPartner = MenuListPartner();
  const menuFirstOnly = MenuOnlyFirst();
  let dispatch = useDispatch();
  const lang = localStorage.getItem("LANG");
  const isMainScreen = location.pathname === "/";

  const { staffs, groups } = useSelector((state) => state.MasterReducer);
  const { devices } = useSelector((state) => state.DeviceReducer);
  const { patients } = useSelector((state) => state.PatientReducer);

  const { getListPartner } = PartnerApiHook();
  const { partnerList } = PartnerHook();
  const { getListFacilityByPartner } = FacilityApiHook();
  const { facilityListByPartner, facilityList } = FacilityHook();
  const { usageLicense } = HomeHook();

  const userInfoString = Cookies.get("USER_INFO");

  const [userInfo, setUserInfo] = useState({});
  const [staff, setStaff] = useState(null);
  const [group, setGroup] = useState(null);

  const [staffOptions, setStaffOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [screenName, setScreenName] = useState("");

  const [numActive, setNumActive] = useState(0);

  const param = localStorage.getItem("PATIENT_PARAM_ADMIN");
  // TODO : use Store reduce
  // total_use_licenses
  const [numInactive, setNumInactive] = useState(0);
  const [numNotRegisted, setNumNotRegisted] = useState(0);
  const [numAlert, setNumAlert] = useState(0);
  const [partner, setPartner] = useState("SANWA");
  const [roleAdmin, setRoleAdmin] = useState(false);
  const [rolePartner, setRolePartner] = useState(false);
  const [roleFacility, setRoleFacility] = useState(false);
  const [facility, setFacility] = useState(null);

  const [language, setLanguage] = useState("");

  useEffect(() => {
    if (lang) {
      setLanguage(lang);
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    const roleAdmin = userInfo && userInfo.is_sys_admin;
    if (roleAdmin) {
      getListPartner();
    }
    if (userInfo) {
      setRoleAdmin(userInfo && userInfo.is_sys_admin);
      setRolePartner(userInfo && !userInfo.is_sys_admin && userInfo.role === 'ROLE_PARTNER');
      setRoleFacility(userInfo && !userInfo.is_sys_admin && userInfo.role == 'ROLE_FACILITY');
    }
  }, [userInfo]);
  useEffect(() => {
    const roleAdmin = userInfo && userInfo.is_sys_admin;
    const rolePartner = userInfo && !userInfo.is_sys_admin && userInfo.role === 'ROLE_PARTNER';
    if (roleAdmin) {
      getListFacilityByPartner({ partner_id: partner == "SANWA" ? "DEFAULT_SANWA" : partner });
    }
    if (rolePartner) {
      getListFacilityByPartner({ partner_id: userInfo.id });
    }
  }, [partner, userInfo, facilityList]);
  useEffect(() => {
    if (patients?.length > 0) {
      let nActive = 0;
      let nInactive = 0;
      let nNotRegisted = 0;
      let nAlert = 0;
      patients.forEach((pa) => {
        const foundDevice = devices.find((d) => d.patient_id === pa.id);
        if (foundDevice) {

          if (foundDevice?.diaper) {
            nActive++;
          } else if (foundDevice?.mac && foundDevice?.transmitter_mac && foundDevice?.is_removed_diaper) {
            nInactive++;
          }
          if (foundDevice?.notify_temp_clothing == true || foundDevice?.notify_sleeping_pos == true || foundDevice?.diaper_status == 'R' ||
            (foundDevice?.battery_status == 'AD_BAT_VOL_LOWER_THRS' || foundDevice?.battery_status == 'AD_BAT_VOL_POWER_OFF')) {
            nAlert++;
          }
          if (foundDevice?.transmitter_mac == undefined) {
            nNotRegisted++;
          }
        } else {
          nNotRegisted++;
        }
      });
      setNumActive(nActive);
      setNumInactive(nInactive);
      setNumNotRegisted(nNotRegisted);
      setNumAlert(nAlert);
    }
    if (patients?.length == 0) {
      setNumActive(0);
      setNumInactive(0);
      setNumNotRegisted(0);
      setNumAlert(0);
    }
  }, [devices, patients]);

  useEffect(() => {
    if (userInfoString) {
      setUserInfo(JSON.parse(userInfoString));
    }
  }, [userInfoString]);
  useEffect(() => {
    if (staffs?.length > 0) {
      let array = [{ label: t("all"), value: null }];
      const convert = convertOption(staffs);
      setStaffOptions(array.concat(convert));
    } else {
      setStaffOptions([]);
    }
  }, [staffs, language]);
  useEffect(() => {
    if (groups?.length > 0) {
      let array = [{ label: t("all"), value: null }];
      const convert = convertOption(
        groups?.filter((g) => g.name !== "DEFAULT")
      );
      setGroupOptions(array.concat(convert));
    } else {
      setGroupOptions([]);
    }
  }, [groups, language]);
  useEffect(() => {
    if (location.pathname) {
      let menus = null;
      if (!userInfo.confirm_info && !isAdminSanwa(userInfo)) {
        menus = menuFirstOnly;
      } else
        if (userInfo.role === "ROLE_FACILITY" && userInfo.is_sys_admin) {
          menus = menuListAdmin;
        } else if (userInfo.role === "ROLE_FACILITY" && !userInfo.is_sys_admin) {
          setFacility(userInfo.id);
          menus = menuList;
        } else if ((userInfo.role === "ROLE_PARTNER" || userInfo.role) && !userInfo.is_sys_admin) {
          setPartner(userInfo.id);
          menus = menuListPartner;
        }
      if (menus) {
        const foundMenu = menus.findLast((menu) =>
          location.pathname.includes(menu.url)
        );
        if (foundMenu) {
          if (foundMenu.childs?.length > 0) {
            const foundChild = foundMenu.childs.find((c) =>
              location.pathname.includes(c.url)
            );
            if (foundChild) setScreenName(foundChild.title);
          } else {
            setScreenName(foundMenu.title);
          }
          if (location.pathname?.includes("analytic-urine")) {
            const title = lang && lang === 'KR' ? "분석 그래프" : "分析グラフ";
            setScreenName(title);
          }

          if (location.pathname?.includes("payment-detail")) {
            const title = lang && lang === 'KR' ? "연간 유지 보수 요금 조회" : "年間保守料金照会";
            setScreenName(title);
          }
        } else {
          setScreenName("");
        }
      }
    }
  }, [menuListAdmin, location.pathname, userInfo]);

  useEffect(() => {
    const roleAdmin = userInfo && userInfo.is_sys_admin
    if (roleAdmin) {
      if (partnerList) {
        const data = [{ value: "SANWA", label: t('no_partner') }, { value: "ALL", label: t('all') }]
        const partnerMap = partnerList.map((item, index) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        data.push(...partnerMap);
        setPartnerOptions(data);
        setPartner(data[0].value);
      }
    }
  }, [partnerList]);

  useEffect(() => {
    if (facilityListByPartner) {
      let data = []
      if (partner == "SANWA" && isAdminSanwa(userInfo)) {
        data = [{
          value: "ALL_PERSONAL",
          label: t('personal'),
        }]
      }
      if (partner != "SANWA") {
        if (facilityListByPartner.length > 0) {
          data = [{
            value: "ALL",
            label: t('all'),
          }, ...facilityListByPartner.map((item, index) => {
            return {
              value: item.id,
              label: item.name,
            }
          })]
        }
      }
      setFacilityOptions(data);
      if (data.length > 0) {
        console.log(data);

        setFacility(data[0]);
        dispatch(getPatientByFacilities({ facility_id: data[0].value === "ALL_PERSONAL" ? undefined : data[0].value, group_id: group, partner_id: partner, patient_id: data[0].value == "ALL_PERSONAL" ? "ALL" : undefined }));
        dispatch(getGroupByFacility({ facilityId: data[0].value, partnerId: partner }))
        localStorage.setItem("PATIENT_PARAM_ADMIN", JSON.stringify({ facility_id: data[0].value === "ALL_PERSONAL" ? undefined : data[0].value, group_id: group, partner_id: partner, patient_id: data[0].value == "ALL_PERSONAL" ? "ALL" : undefined }));
        localStorage.setItem("PATIENT_PARAM_ADMIN2", JSON.stringify({ facility_id: data[0].value === "ALL_PERSONAL" ? undefined : data[0].value, group_id: group, partner_id: partner, patient_id: data[0].value == "ALL_PERSONAL" ? "ALL" : undefined }));
        localStorage.setItem("FACILITY_PARAM", data[0].value === "ALL_PERSONAL" ? undefined : data[0].value);
        localStorage.setItem("PARTNER_PARAM", partner);
        console.log(data[0].value === "ALL_PERSONAL" ? undefined : data[0].value, partner);

      } else {
        setFacility(null);
        // localStorage.removeItem("PATIENT_PARAM_ADMIN")
        dispatch(resetPatientList())
      }
    }
  }, [facilityListByPartner]);

  const onChangeLang = (value) => {
    setLanguage(value);
    localStorage.setItem("LANG", value);
    i18n.changeLanguage(value);
    dispatch(getMaster());
  };
  const goHome = () => {
    window.location.href = "/";
  };
  const onChangeStaff = (value) => {
    setStaff(value);
    const data = {
      name: "",
      staff_id: value || "",
      group_id: group || "",
    };
    dispatch(getPatients(data));
    localStorage.setItem("PATIENT_PARAM", JSON.stringify(data));
  };
  const onChangeGroup = (value) => {
    setGroup(value);
    const data = {
      name: "",
      staff_id: staff || "",
      group_id: value || "",
    };
    dispatch(getPatients(data));
    localStorage.setItem("PATIENT_PARAM", JSON.stringify(data));
  };
  const onChangePartner = (value) => {
    setPartner(value)
    dispatch(getPatientByFacilities({ partner_id: value, facility_id: facility?.id, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));

    localStorage.setItem("PATIENT_PARAM_ADMIN", JSON.stringify({ partner_id: value, facility_id: facility?.id, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));
    localStorage.setItem("PATIENT_PARAM_ADMIN2", JSON.stringify({ partner_id: value, facility_id: facility?.id, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));

  };

  const onChangeFacility = (value) => {
    setFacility(value)
    dispatch(getPatientByFacilities({ partner_id: partner, facility_id: value === "ALL_PERSONAL" ? undefined : value, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));
    dispatch(getGroupByFacility({ facilityId: value, partnerId: partner }))

    localStorage.setItem("PATIENT_PARAM_ADMIN", JSON.stringify({ partner_id: partner, facility_id: value === "ALL_PERSONAL" ? undefined : value, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));
    localStorage.setItem("PATIENT_PARAM_ADMIN2", JSON.stringify({ partner_id: partner, facility_id: value === "ALL_PERSONAL" ? undefined : value, group_id: group, patient_id: value == "ALL_PERSONAL" ? "ALL" : undefined }));
    localStorage.setItem("FACILITY_PARAM", value === "ALL_PERSONAL" ? undefined : value);
    localStorage.setItem("PARTNER_PARAM", partner);
    console.log(value === "ALL_PERSONAL" ? undefined : value, partner);
  };
  const isAdminSanwa = (userInfo) => userInfo.role == "ROLE_FACILITY" && userInfo.is_sys_admin;


  if (!isMainScreen) {
    return (
      <div className="header-container">
        <div className="header-container-left">
          <Menu />
          <div className="header-item">
            <img src="/icons/logo.png" className="logo-icon" onClick={goHome} />
          </div>
          <div className="header-container-item">
            <div className="header-screen-name">{screenName}</div>
          </div>
        </div>
        {!isAdminSanwa(userInfo) && <Select
          value={language}
          options={langOpt}
          onChange={onChangeLang}
          width={70}
        />}
      </div>
    );
  }
  return (
    <div className="header-container-main">
      <Menu />
      <div className="header-item">
        <img src="/icons/logo.png" className="logo-icon" onClick={goHome} />
      </div>
      {roleFacility &&
        <div className="header-item-center">
          <div className="header-item-row">{t("number_of_licenses")}</div>
          <div className="header-item-row">
            {Math.min(usageLicense ?? 0, userInfo?.number_of_licenses ?? 0)}/
            {userInfo?.number_of_licenses || 0}
          </div>
        </div>
      }
      <div className="header-item-filter">
        {roleFacility &&
          <div className="header-item-row">
            <div className="header-item-row-label">{t("pic")}：</div>
            <div className="header-item-row-content">
              <Select
                value={staff}
                options={staffOptions}
                onChange={onChangeStaff}
                width={150}
              />
            </div>
          </div>
        }
        {roleFacility &&
          <div className="header-item-row">
            <div className="header-item-row-label">{t("group")}：</div>
            <div className="header-item-row-content">
              <Select
                value={group}
                options={groupOptions}
                onChange={onChangeGroup}
                width={150}
              />
            </div>
          </div>
        }
        {roleAdmin &&
          <div className="header-item-row">
            <div className="header-item-row-label">{t("filter.partner")}：</div>
            <div className="header-item-row-content">
              <Select
                value={partner}
                options={partnerOptions}
                onChange={onChangePartner}
                width={150}
              />
            </div>
          </div>
        }
        {(roleAdmin || rolePartner) &&
          <div className="header-item-row">
            <div className="header-item-row-label">{t("filter.facility")}：</div>
            <div className="header-item-row-content">
              <Select
                value={facility}
                options={facilityOptions}
                onChange={onChangeFacility}
                width={150}
              />
            </div>
          </div>
        }
      </div>
      <div className="header-item">
        <div className="header-item-row-text" style={{ color: "#FDBA2C" }}>
          {t("active")}：{numActive}
          {t("name")}
        </div>
        <div className="header-item-row-text" style={{ color: "#0E6FBA" }}>
          {t("inactive")}：{numInactive}
          {t("name")}
        </div>
        <div className="header-item-row-text">
          {t("sensor_not_registered")}：{numNotRegisted}
          {t("name")}
        </div>
      </div>

      <div className="header-item">
        <div className="header-item-row" style={{ color: "#FF3131" }}>
          {t("alert")}：{numAlert}
          {t("name")}
        </div>
      </div>
      {!isAdminSanwa(userInfo) && <Select
        value={language}
        options={langOpt}
        onChange={onChangeLang}
        width={70}
      />}
    </div>
  );
}

export default AppHeader;
