import { get, patch, put, del } from "../../api_helper";
import * as url from "../../url_helper";
import { convertObjectToParams } from "utils";

export const apiGetDiapers = (data) => {
  let urlApi = url.API_DIAPERS;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiPutDiapers = (data) => put(url.API_DIAPERS, data);
export const apiPatchDiapers = (id, data) => patch(url.API_DIAPERS + id, data);
export const apiDelDiapers = (id) => del(url.API_DIAPERS + id);
