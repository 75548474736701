import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const GroupHook = () => {
  const { groups, groups2, success, error } = useSelector(
    (state) => state.MasterReducer
  );

  const [groupList, setGroupList] = useState([]);
  const [groupList2, setGroupList2] = useState([]);

  useEffect(() => {
    setGroupList(groups);
  }, [groups]);

  useEffect(() => {
    setGroupList2([]);
    setGroupList2(groups2);
  }, [groups2]);

  return { groupList, groupList2, success, error };
};
export default GroupHook;
