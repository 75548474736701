import { takeLatest, put, call } from "redux-saga/effects";

import { ADD_FACILITY, GET_FACILITIES, UPDATE_FACILITY, UPDATE_FACILITY_PAYMENT, GET_FACILITIES_BY_PARTNER } from "./actionTypes";

import {
  getFacilitiesSuccess,
  getFacilitiesFail,
  getFacilitiesByPartnerSuccess,
  getFacilityByPartnerFail,
  updateFacilitySuccess,
  updateFacilityFail,
  addFacilitySuccess,
  addFacilityFail,
  updatePaymentStatusSuccess,
  updatePaymentStatusFail,
} from "./actions";

import {
  apiGetFacility,
  apiPatchFacility,
  apiPutFacility,
  apiMaintenancePayment
} from "../../helpers/api/facility";

function* onGetFacilities(payload) {
  try {
    const response = yield call(apiGetFacility, payload?.payload);
    if (response) yield put(getFacilitiesSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetFacilities(payload);
    }
    yield put(getFacilitiesFail(error.response));
  }
}
function* onGetFacilitieByPartner(payload) {
  try {
    const response = yield call(apiGetFacility, payload?.payload);
    if (response) yield put(getFacilitiesByPartnerSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetFacilitieByPartner(payload);
    }
    yield put(getFacilityByPartnerFail(error.response));
  }
}
function* onUpdateFacility({ payload }) {
  try {
    const response = yield call(apiPatchFacility, payload?.id, payload?.data);
    if (response)
      yield put(
        updateFacilitySuccess({
          message: response.data || "",
          type: "UPDATE",
        })
      );
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateFacility(payload);
    }
    yield put(updateFacilityFail(error?.data?.error));
  }
}


function* onUpdatePaymentMaintenace({ payload }) {
  try {
    const response = yield call(apiMaintenancePayment, payload?.id, {
      status: payload?.data,
      price: payload?.price
    });
    yield put(
      updatePaymentStatusSuccess({
        message: response.data || "",
        type: "PATCH",
      })
    );
  } catch (error) {
    // if (error?.response?.status === 401) {
    //   onUpdatePaymentMaintenace(payload);
    // }
    yield put(updatePaymentStatusFail(error?.data?.error));
  }
}

function* onAddFacility({ payload }) {
  try {
    console.log("onAddFacility", payload);
    const response = yield call(apiPutFacility, payload);
    if (response)
      yield put(
        addFacilitySuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddFacility(payload);
    }
    yield put(addFacilityFail(error?.data?.error));
  }
}
function* FacilitySaga() {
  yield takeLatest(GET_FACILITIES, onGetFacilities);
  yield takeLatest(GET_FACILITIES_BY_PARTNER, onGetFacilitieByPartner);
  yield takeLatest(UPDATE_FACILITY_PAYMENT, onUpdatePaymentMaintenace);
  yield takeLatest(UPDATE_FACILITY, onUpdateFacility);
  yield takeLatest(ADD_FACILITY, onAddFacility);

}

export default FacilitySaga;
