import {
  RESET_PARTNER_MESSAGE,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAIL,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  ADD_PARTNER,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAIL,
} from "./actionTypes";

export const resetPartnerMessage = () => {
  return {
    type: RESET_PARTNER_MESSAGE,
  };
};

export const getPartner = (payload) => {
  return {
    type: GET_PARTNER,
    payload,
  };
};

export const getPartnerSuccess = (partners) => {
  return {
    type: GET_PARTNER_SUCCESS,
    payload: partners,
  };
};

export const getPartnerFail = (error) => {
  return {
    type: GET_PARTNER_FAIL,
    payload: error,
  };
};

export const updatePartner = (payload) => {
  return {
    type: UPDATE_PARTNER,
    payload,
  };
};

export const updatePartnerSuccess = (partner) => {
  return {
    type: UPDATE_PARTNER_SUCCESS,
    payload: partner,
  };
};

export const updatePartnerFail = (error) => {
  return {
    type: UPDATE_PARTNER_FAIL,
    payload: error,
  };
};

export const addPartner = (payload) => {
  return {
    type: ADD_PARTNER,
    payload,
  };
};

export const addPartnerSuccess = (partner) => {
  return {
    type: ADD_PARTNER_SUCCESS,
    payload: partner,
  };
};

export const addPartnerFail = (error) => {
  return {
    type: ADD_PARTNER_FAIL,
    payload: error,
  };
};
