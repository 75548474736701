import { combineReducers } from "redux";
import PatientReducer from "./patients/reducer";
import DeviceReducer from "./devices/reducer";
import MasterReducer from "./master/reducer";
import LoadingReducer from "./loading/reducer";
import UserReducer from "./user/reducer";
import FacilityReducer from "./facilities/reducer";
import PartnerReducer from "./partners/reducer";

import ExportCsvReducer from "./csv/reducer";

const rootReducer = combineReducers({
  PatientReducer,
  DeviceReducer,
  MasterReducer,
  LoadingReducer,
  UserReducer,
  FacilityReducer,
  PartnerReducer,
  ExportCsvReducer,
});

export default rootReducer;
