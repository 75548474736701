import { takeLatest, put, call } from "redux-saga/effects";

import { ADD_PARTNER, GET_PARTNER, UPDATE_PARTNER } from "./actionTypes";
import {
  getPartnerSuccess,
  getPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
  addPartnerSuccess,
  addPartnerFail,
} from "./actions";

import {
  apiGetPartner,
  apiPatchPartner,
  apiPutPartner,
} from "../../helpers/api/partners";

function* onGetPartners(payload) {
  try {
    const response = yield call(apiGetPartner, payload?.payload);
    if (response) yield put(getPartnerSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetPartners(payload);
    }
    yield put(getPartnerFail(error.response));
  }
}
function* onUpdatePartner({ payload }) {
  try {
    const response = yield call(apiPatchPartner, payload?.id, payload?.data);
    if (response)
      yield put(
        updatePartnerSuccess({
          message: response.data || "",
          type: "UPDATE",
        })
      );
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdatePartner(payload);
    }
    yield put(updatePartnerFail(error?.data?.error));
  }
}
function* onAddPartner({ payload }) {
  try {
    console.log("onAddPartner", payload);
    const response = yield call(apiPutPartner, payload);
    if (response)
      yield put(
        addPartnerSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddPartner(payload);
    }
    yield put(addPartnerFail(error?.data?.error));
  }
}
function* PartnerSaga() {
  yield takeLatest(GET_PARTNER, onGetPartners);
  yield takeLatest(UPDATE_PARTNER, onUpdatePartner);
  yield takeLatest(ADD_PARTNER, onAddPartner);
}

export default PartnerSaga;
