import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const CSVHook = () => {
  const { link, inProgressExport, success, error } = useSelector(
    (state) => state.ExportCsvReducer
  );

  const [exportedLink, setExportedLink] = useState('');
  const [inPE, setInProgressExport] = useState('');

  useEffect(() => {
    setExportedLink(link);
  }, [link]);

  useEffect(() => {
    setInProgressExport(inProgressExport);
  }, [inProgressExport])

  return { exportedLink, inPE, success, error };
};
export default CSVHook;
