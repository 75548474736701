import {
  getPatients,
  updatePatient,
  getPatientDetail,
  addPatient,
  deletePatient,
  getPatientByFacilities
} from "store/patients/actions";
import { getDevicesByPatient, unpairDevices } from "store/devices/actions";
import {
  getGroups,
  getMaster,
  getMasterArea,
  getMasterDiaper,
  getMasterStaff,
} from "store/master/actions";
import { useDispatch } from "react-redux";

const HomeApiHook = () => {
  let dispatch = useDispatch();
  const pageInit = () => {
    // dispatch(getPatients());
    dispatch(getGroups());
    dispatch(getMaster());
    dispatch(getMasterArea());
    dispatch(getMasterDiaper());
    dispatch(getMasterStaff());
  };

  const pageInitAdmin = () => {
    dispatch(getMaster());
    dispatch(getMasterArea());
    dispatch(getMasterDiaper());
  };

  const callGetPatients = (payload) => {
    dispatch(getPatients(payload));
  };
  const callGetPatientByFacility = (payload) => {
    dispatch(getPatientByFacilities(payload));
  };
  const callGetDevices = (payload) => {
    dispatch(getDevicesByPatient(payload));
  };
  const callGetGroups = () => {
    dispatch(getGroups());
  };
  const callGetMaster = () => {
    dispatch(getMaster());
  };
  const callGetMasterArea = () => {
    dispatch(getMasterArea());
  };
  const callUpdatePatients = (id, data) => {
    dispatch(updatePatient({ id, data }));
  };
  const callAddPatients = (data) => {
    dispatch(addPatient(data));
  };
  const callDeletePatient = (id) => {
    dispatch(deletePatient(id));
  };
  const callGetPatientDetail = (id) => {
    dispatch(getPatientDetail(id));
  };
  const callUnpairDevice = (id, data) => {
    dispatch(unpairDevices({ id, data }));
  };
  return {
    pageInit,
    pageInitAdmin,
    callGetPatients,
    callGetDevices,
    callGetGroups,
    callGetMaster,
    callGetMasterArea,
    callUpdatePatients,
    callGetPatientDetail,
    callAddPatients,
    callDeletePatient,
    callUnpairDevice,
    callGetPatientByFacility
  };
};
export default HomeApiHook;
