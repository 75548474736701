import Button from "components/button/Button";
import FormInput from "components/formInput/Input";
import { useFormik } from "formik";
import useNotify from "hooks/useNotify";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getMasterDeg,
  resetMasterMessage,
  updateMasterDeg,
} from "store/master/actions";
import * as Yup from "yup";
import "./styles.scss";
import FacilityHook from "hooks/facility/facilityHook";
import FacilityApiHook from "hooks/facility/facilityApiHook";
import { Col, Container, Row } from "react-grid";
import Lable from "components/lable/lable";
import Select from "components/select/Select";
import { PaymentState, classPaymentState } from "pages/facilityInformationList/PaymentState";
import { resetMessages } from "store/devices/actions";

import currencyFormatter from 'currency-formatter';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import InputComponent from "components/input/Input";

const DetailPayment = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { showToastSuccess, showToastError } = useNotify();
  const [paymentPrice, setPaymentPrice] = useState('0');

  const [facility, setFacility] = useState({});
  const [paymentState, setPaymentState] = useState(PaymentState.Pendding);

  const { getListFacility, updatePaymentMaintenance } = FacilityApiHook();
  const { facilityList, success, error } = FacilityHook();

  const [selectClssName, setSelectClssName] = useState('');
  const [handling, setHandling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (facilityList.length >= 0) {
      const facility = facilityList.find((e) => e.id == props.match.params.facilityId);
      if (facility != null) {
        setFacility(facility);
        setSelectClssName(classPaymentState(facility.payment_status))
        setPaymentState(facility.payment_status);

        if (!facility.edit_price) {
          setPaymentPrice(facility.payment_price)
        }
      }
    }
  }, [facilityList]);

  useEffect(() => {
    if (success.message?.type === "PATCH") {
      showToastSuccess(t("msg.update_payment_successfully"));
      resetMessages();

      setTimeout(() => {
        history.goBack();
      }, 2000);

    }
    console.log(error)
    if (error?.code === 400) {
      showToastError(t("msg.update_payment_failed"));
      resetMessages();
    }
    setIsLoading(false)
  }, [success, error]);

  useEffect(() => {
    getListFacility();
  }, []);

  const paymentStates = [
    { label: t('payment_status.pendding'), value: PaymentState.Pendding, style: { color: '#ff5757' }, className: "pendding" },
    { label: t('payment_status.paided'), value: PaymentState.Paided, style: { color: '#2abf63' } },
    { label: t('payment_status.archived'), value: PaymentState.Confirmed, style: { color: '#f7b42f' } },
  ];

  const paymentStateModePaided = [
    { label: t('payment_status.paided'), value: PaymentState.Paided, style: { color: '#2abf63' } },
    { label: t('payment_status.archived'), value: PaymentState.Confirmed, style: { color: '#f7b42f' } },
  ];
  const localDate = moment(new Date(facility.exp_date + "Z")).subtract(1, 'months').endOf('month');

  const definedPaymentState = (v) => {
    switch (v) {
      case PaymentState.Confirmed:
        return t('payment_status.archived');
        break;

      case PaymentState.Paided:
        return t('payment_status.paided');
        break;

      case PaymentState.Pendding:
      default:
        return t('payment_status.pendding');
        break;
    }
  };

  const canEdit = (v) => {
    switch (v.payment_status) {
      case PaymentState.Pendding:
      case PaymentState.Paided:
        return true;
      case PaymentState.Confirmed:
      default:
        return false;
    }
  }

  const onChangeState = (v) => {
    console.log(facility.payment_status);
    if (facility.payment_status == PaymentState.Confirmed) {
      return;
    }

    if (facility.payment_status == PaymentState.Paided) {
      if (v == PaymentState.Pendding) {
        return;
      }

      setSelectClssName(classPaymentState(v))
      setPaymentState(v)
    }

    if (facility.payment_status == PaymentState.Pendding) {

      setSelectClssName(classPaymentState(v))
      setPaymentState(v)
    }

  }

  return (
    <div className="deg-container">
      <Container>
        <Row>
          <Col md={5} offset={{ md: 1 }}>
            <div className="block-padding">
              <Row>
                <Col md={5}>
                  <Lable lable={t('facility_name')} />
                </Col>
                <Col md={4}>
                  <span className="value-text">
                    {facility.name}
                  </span>
                </Col>
              </Row>
            </div>

            <div className="block-padding">
              <Row>
                <Col md={5}>
                  <Lable lable={t('number_of_licenses_payment')} />
                </Col>
                <Col md={4}>
                  <span className="value-text">
                    {facility.number_of_licenses}
                  </span>
                </Col>
              </Row>
            </div>

            <div className="block-padding">
              <Row>
                <Col md={5}>
                  <Lable lable={t('contract_price')} />
                </Col>
                <Col md={4}>
                  {(facility.edit_price && facility.payment_price) &&
                    <span className="value-text cost-box">
                      {currencyFormatter.format(facility.payment_price, { locale: 'ja-JP' })}
                    </span>
                  }

                  {!facility.edit_price &&
                    <InputComponent
                      type="number"
                      value={paymentPrice}
                      onChange={(v) => {
                        console.log('cahnged price : ' + v);
                        setPaymentPrice(v)
                      }}
                    />
                  }

                  {(facility.edit_price && !facility.payment_price) &&
                    <span className="value-text cost-box">
                      {currencyFormatter.format(12000, { locale: 'ja-JP' })}
                    </span>
                  }
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={5} offset={{ md: 0 }}>
            {facility.partner_name != undefined && <div className="block-padding">
              <Row>
                <Col md={5}>
                  <Lable lable={t('partner_name1')} />
                </Col>
                <Col md={4}>
                  <span className="value-text">
                    {facility.partner_name}
                  </span>
                </Col>
              </Row>
            </div>}
            {facility.exp_date &&
              <div className="block-padding">
                <Row>
                  <Col md={5}>
                    <Lable lable={t('facility_registed_date')} />
                  </Col>
                  <Col md={7}>
                    <span className="value-text">
                      {`${localDate.year()} / ${localDate.month() + 1} / ${localDate.date()}`}
                    </span>
                  </Col>
                </Row>
              </div>
            }
            {facility.payment_status &&
              <div className="block-padding">
                <Row>
                  <Col md={5}>
                    <Lable lable={t('facility_payment_state')} />
                  </Col>
                  <Col md={7}>
                    {facility.payment_status == PaymentState.Confirmed && <span style={{ color: '#f7b42f' }}>{t('payment_status.archived')}</span>}
                    {facility.payment_status != PaymentState.Confirmed && <span className={`value-text select-options ${selectClssName}`}>
                      <Select
                        value={definedPaymentState(paymentState)}
                        onChange={(v) => {
                          onChangeState(v)
                        }}
                        options={facility.payment_status == PaymentState.Paided ? paymentStateModePaided : paymentStates}
                      />
                    </span>}

                  </Col>
                </Row>
              </div>
            }
          </Col>
        </Row>
        {facility.payment_url &&
          <Row>
            <Col offset={{ md: 1 }}>
              <div className="block-padding">
                <Row>
                  <Col md={2}>
                    <Lable lable={t('exported_link')} />
                  </Col>
                  <Col md={9}>
                    <a className="value-text link-payment" target="_blank" href={facility.payment_url}>
                      {facility.payment_url.split('/')[facility.payment_url.split('/').length - 1]}
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        }
      </Container>
      <Button
        key="back"
        text={(canEdit(facility) || !facility.edit_price) ? t("button.edit") : t("button.back")}
        className="deg-submit-button"
        background="#0E6FBA"
        width={100}
        disable={isLoading}
        onClick={() => {

          if (canEdit(facility) || !facility.edit_price) {
            if (!facility.edit_price) {
              setIsLoading(true)
              updatePaymentMaintenance({ id: facility.id, data: null, price: paymentPrice })
              setFacility({ ...facility, ...{ edit_price: true, payment_price: paymentPrice } })
            }
            if (canEdit(facility)) {
              setIsLoading(true)
              updatePaymentMaintenance({ id: facility.id, data: paymentState, price: null })
            }
          } else {
            history.goBack();
          }
        }}
      />
    </div>
  );
};
export default DetailPayment;
