import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const HomeHook = () => {
  const { patients, patient, loadingPatients, totalUseLisences } = useSelector(
    (state) => state.PatientReducer
  );
  const { devices } = useSelector((state) => state.DeviceReducer);
  const { groups, master, areas, diapers } = useSelector(
    (state) => state.MasterReducer
  );

  // total_use_licenses

  const [state, setState] = useState({
    patients: null,
    patientDetail: {},
    loadingPatients: false,
    groups: [],
    master: {},
    devices: [],
    areas: [],
    diapers: [],
    usageLicense: 0
  });
  const [usageLicense, setUsageLicense] = useState(0);
  const [patientList, setPatientList] = useState([]);
  const [patientDetail, setPatientDetail] = useState([]);
  useEffect(() => {
    if (patients) {
      setPatientList([...patients]);
    }
  }, [patients]);
  useEffect(() => {
    setState({
      ...state,
      devices,
    });
  }, [devices]);
  useEffect(() => {
    setState({
      ...state,
      loadingPatients,
    });
  }, [loadingPatients]);

  useEffect(() => {
    setState({
      ...state,
      groups,
    });
  }, [groups]);
  useEffect(() => {
    setState({
      ...state,
      master,
    });
  }, [master]);
  useEffect(() => {
    setState({
      ...state,
      areas,
    });
  }, [areas]);
  useEffect(() => {
    setState({
      ...state,
      diapers,
    });
  }, [diapers]);
  useEffect(() => {
    setPatientDetail(patient);
  }, [patient]);

  useEffect(() => {
    setUsageLicense(totalUseLisences);
    setState({
      ...state,
      usageLicense: totalUseLisences,
    });
  }, [totalUseLisences]);

  return { state, patientList, patientDetail, usageLicense };
};
export default HomeHook;
