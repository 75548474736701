import {
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUPS_BY_FACILITY_SUCCESS,
  GET_GROUPS_BY_FACILITY_FAIL,
  GET_MASTER_SUCCESS,
  GET_MASTER_FAIL,
  GET_MASTER_AREA_SUCCESS,
  GET_MASTER_AREA_FAIL,
  GET_MASTER_DIAPER_SUCCESS,
  GET_MASTER_DIAPER_FAIL,
  GET_MASTER_STAFF_SUCCESS,
  GET_MASTER_STAFF_FAIL,
  UPDATE_MASTER_STAFF_SUCCESS,
  UPDATE_MASTER_STAFF_FAIL,
  RESET_MASTER_MESSAGE,
  ADD_MASTER_STAFF_SUCCESS,
  ADD_MASTER_STAFF_FAIL,
  DELETE_MASTER_STAFF_SUCCESS,
  DELETE_MASTER_STAFF_FAIL,
  UPDATE_MASTER_GROUP_SUCCESS,
  UPDATE_MASTER_GROUP_FAIL,
  ADD_MASTER_GROUP_SUCCESS,
  ADD_MASTER_GROUP_FAIL,
  UPDATE_MASTER_DIAPER_SUCCESS,
  UPDATE_MASTER_DIAPER_FAIL,
  ADD_MASTER_DIAPER_SUCCESS,
  ADD_MASTER_DIAPER_FAIL,
  DELETE_MASTER_DIAPER_SUCCESS,
  DELETE_MASTER_DIAPER_FAIL,
  GET_MASTER_DEG_SUCCESS,
  GET_MASTER_DEG_FAIL,
  UPDATE_MASTER_DEG_SUCCESS,
  UPDATE_MASTER_DEG_FAIL,
  GET_GROUPS_SUCCESS2,
} from "./actionTypes";

const initialState = {
  groups: [],
  groups2: [],
  master: {},
  areas: {},
  diapers: [],
  staffs: [],
  degs: [],
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const MasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MASTER_MESSAGE:
      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case GET_GROUPS_SUCCESS:
      state = { ...state, groups: action.payload?.data };
      break;

    case GET_GROUPS_SUCCESS2:
      state = { ...state, groups2: action.payload?.data };
      break;
    case GET_GROUPS_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_GROUPS_BY_FACILITY_SUCCESS:
      state = { ...state, groups: action.payload?.data };
      break;
    case GET_GROUPS_BY_FACILITY_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_MASTER_GROUP_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_MASTER_GROUP_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ADD_MASTER_GROUP_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case ADD_MASTER_GROUP_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_MASTER_SUCCESS:
      state = { ...state, master: action.payload?.data || {} };
      break;
    case GET_MASTER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_MASTER_AREA_SUCCESS:
      state = { ...state, areas: action.payload?.data || [] };
      break;
    case GET_MASTER_AREA_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_MASTER_DIAPER_SUCCESS:
      state = { ...state, diapers: action.payload?.data || [] };
      break;
    case GET_MASTER_DIAPER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_MASTER_DIAPER_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_MASTER_DIAPER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ADD_MASTER_DIAPER_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case ADD_MASTER_DIAPER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case DELETE_MASTER_DIAPER_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case DELETE_MASTER_DIAPER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_MASTER_STAFF_SUCCESS:
      state = { ...state, staffs: action.payload?.data || [] };
      break;
    case GET_MASTER_STAFF_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_MASTER_STAFF_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_MASTER_STAFF_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ADD_MASTER_STAFF_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case ADD_MASTER_STAFF_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case DELETE_MASTER_STAFF_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case DELETE_MASTER_STAFF_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_MASTER_DEG_SUCCESS:
      state = { ...state, degs: action.payload || [] };
      break;
    case GET_MASTER_DEG_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_MASTER_DEG_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_MASTER_DEG_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MasterReducer;
