export const RESET_DEVICE_MESSAGE = "RESET_DEVICE_MESSAGE";

export const GET_DEVICES = "GET_DEVICES";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL";

export const GET_DEVICES_LIST = "GET_DEVICES_LIST";
export const GET_DEVICES_LIST_SUCCESS = "GET_DEVICES_LIST_SUCCESS";
export const GET_DEVICES_LIST_FAIL = "GET_DEVICES_LIST_FAIL";

export const GET_DEVICES_BY_PATIENT = "GET_DEVICES_BY_PATIENT";
export const GET_DEVICES_BY_PATIENT_SUCCESS = "GET_DEVICES_BY_PATIENT_SUCCESS";
export const GET_DEVICES_BY_PATIENT_FAIL = "GET_DEVICES_BY_PATIENT_FAIL";

export const ADD_DEVICES = "ADD_DEVICES";
export const ADD_DEVICES_SUCCESS = "ADD_DEVICES_SUCCESS";
export const ADD_DEVICES_FAIL = "ADD_DEVICES_FAIL";

export const UPDATE_DEVICES = "UPDATE_DEVICES";
export const UPDATE_DEVICES_SUCCESS = "UPDATE_DEVICES_SUCCESS";
export const UPDATE_DEVICES_FAIL = "UPDATE_DEVICES_FAIL";

export const UNPAIR_DEVICES = "UNPAIR_DEVICES";
export const UNPAIR_DEVICES_SUCCESS = "UNPAIR_DEVICES_SUCCESS";
export const UNPAIR_DEVICES_FAIL = "UNPAIR_DEVICES_FAIL";

export const GET_DEVICES_SETTING_BATVOL = "GET_DEVICES_SETTING_BATVOL";
export const GET_DEVICES_SETTING_BATVOL_SUCCESS = "GET_DEVICES_SETTING_BATVOL_SUCCESS";
export const GET_DEVICES_SETTING_BATVOL_FAIL = "GET_DEVICES_SETTING_BATVOL_FAIL";

export const UPDATE_DEVICES_SETTING_BATVOL = "UPDATE_DEVICES_SETTING_BATVOL";
export const UPDATE_DEVICES_SETTING_BATVOL_SUCCESS = "UPDATE_DEVICES_SETTING_BATVOL_SUCCESS";
export const UPDATE_DEVICES_SETTING_BATVOL_FAIL = "UPDATE_DEVICES_SETTING_BATVOL_FAIL";

export const UPDATE_DEVICES_SETTING_ACCELERATION = "UPDATE_DEVICES_SETTING_ACCELERATION";
export const UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS = "UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS";
export const UPDATE_DEVICES_SETTING_ACCELERATION_FAIL = "UPDATE_DEVICES_SETTING_ACCELERATION_FAIL";
