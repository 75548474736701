import React from "react";
import { Select, ConfigProvider } from "antd";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const SelectComponent = (props) => {
  const { t } = useTranslation();
  const {
    value,
    options,
    onChange,
    showSearch = false,
    title,
    placeholder,
    filterOption,
    allowClear,
    onClear,
    disabled,
    width,
    mode,
    error,
    name,
    style,
  } = props;
  return (
    <div className="select-container" style={{ width: width || "100%" }}>
      {title && <div className="select-title">{title}</div>}
      <ConfigProvider renderEmpty={() => t("empty_data")}>
        <Select
          defaultValue=""
          showSearch={showSearch}
          name={name}
          mode={mode}
          style={{
            width: width || "100%",
            border: error ? "1px solid red" : "",
            borderRadius: "6px",
          }}
          onChange={onChange}
          value={value}
          filterOption={filterOption}
          options={options}
          disabled={disabled}
          allowClear={allowClear}
          onClear={onClear}
          placeholder={placeholder ? placeholder : t("select")}
        />
      </ConfigProvider>
      {error ? <span className="select-error-message">{error}</span> : null}
    </div>
  );
};
export default SelectComponent;
