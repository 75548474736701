import { CheckOutlined } from "@ant-design/icons";
import Button from "components/button/Button";
import DatePicker from "components/datepicker/Datepicker";
import FormInput from "components/formInput/Input";
import Modal from "components/modal/Modal";
import Select from "components/select/Select";
import { useFormik } from "formik";
import HomeApiHook from "hooks/home/homeApiHook";
import HomeHook from "hooks/home/homeHook";
import useNotify from "hooks/useNotify";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetMessages } from "store/devices/actions";
import { convertOption, convertOptionDiaper, convertOptionMaster, convertOptionAreaEdit, isAdminFacility } from "utils";
import * as Yup from "yup";
import Cookies from "js-cookie";
import "./styles.scss";

const FormPatientDetail = (props) => {
  let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const lang = localStorage.getItem("LANG");
  const masterData = localStorage.getItem("MASTER");
  const { t } = useTranslation();
  const GENDER = [
    { label: t("gender.male"), value: "MALE" },
    { label: t("gender.female"), value: "FEMALE" },
  ];
  const history = useHistory();
  const {
    master,
    groups,
    areas,
    devices,
    patientEditting,
    setPatientEditting,
    diapers,
  } = props;

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      firstName: "",
      lastName: "",
      birthday: null,
      gender: "",
      area: "",
      group: "",
      levelCare: 0,
      livingSituation: "",
      diaperType: "",
      diseases: [],
      symptoms: [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required(t("required_field.last_name"))
        .max(255, `255${t("error_msg.error_max_length")}`),
      lastName: Yup.string()
        .required(t("required_field.first_name"))
        .max(255, `255${t("error_msg.error_max_length")}`),
      birthday: Yup.date()
        .required(t("required_field.birthday"))
        .max(yesterday, t("error_msg.error_current_date")),
      gender: Yup.string().required(t("required_field.gender")),
      area: Yup.string().required(t("required_field.area")),
      group: Yup.string().required(t("required_field.group")),
      levelCare: Yup.string().required(t("required_field.level_care")),
      livingSituation: Yup.string().required(
        t("required_field.living_situation")
      ),
      diseases: Yup.array()
        .required(t("required_field.diseases"))
        .min(1, t("required_field.diseases")),
      symptoms: Yup.array()
        .required(t("required_field.symptoms"))
        .min(1, t("required_field.symptoms")),
    }),
    onSubmit: (values, { setErrors, resetForm }) => {
      const diseasesData = [];
      diseases.forEach((di) => {
        const foundDisease = diseasesOpts?.find((ele) => ele.value === di);
        if (foundDisease) {
          diseasesData.push({
            disease_code: foundDisease.value,
            disease_text: foundDisease.label,
          });
        } else {
          diseasesData.push({
            disease_code: 99,
            disease_text: di,
          });
        }
      });
      const data = {
        area_id: area,
        group_id: group,
        relationship_code: patientEditting.relationship_code,
        last_name: lastName,
        name: firstName,
        date_of_birth: moment(birthday).format("YYYY-MM-DD"),
        gender: gender,
        diaper_id: diaperType,
        level_care: levelCare,
        living_situation: livingSituation,
        diseases: diseasesData,
        symptoms: symptoms,
      };
      callUpdatePatients(patientEditting.id, data);
      setShowModalUpdatePatient(true);
    },
  });
  let dispatch = useDispatch();
  const { showToastSuccess } = useNotify();
  const {
    id,
    firstName,
    lastName,
    birthday,
    gender,
    area,
    group,
    levelCare,
    diaperType,
    livingSituation,
    diseases,
    symptoms,
  } = formik.values;
  const { callUpdatePatients, callDeletePatient, callUnpairDevice } =
    HomeApiHook();
  const { patientDetail } = HomeHook();
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [showModalSearchResult, setShowModalSearchResult] = useState(false);
  const [showModalRegisterSuccess, setShowModalRegisterSuccess] =
    useState(false);
  const [sensorSelected, setSensorSelected] = useState(null);

  const [diseaseState, setDiseaseState] = useState(1);
  const [symptomState, setSymptomState] = useState(1);

  const [diseasesText, setDiseasesText] = useState([]);
  const [symptomsText, setSymptomsText] = useState([]);

  const [listArea, setListArea] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [listLevelCare, setListLevelCare] = useState([]);
  const [livingSituationList, setLivingSituationList] = useState([]);
  const [diseasesOpts, setDiseasesOpts] = useState([]);
  const [diseasesOptsAll, setDiseasesOptsAll] = useState([]);
  const [symptomsOpts, setSymptomsOpts] = useState([]);
  const [symptomsOptsAll, setSymptomsOptsAll] = useState([]);

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [diaperTypeList, setDiaperTypeList] = useState([]);
  const [displayGroups, setDisplayGroups] = useState([]);


  const [roleFacility, setRoleFacility] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const userInfoString = Cookies.get("USER_INFO");



  const setShowModalUpdatePatient = (value) => {
    history.goBack();
  }

  useEffect(() => {
    if (userInfoString) {
      setUserInfo(JSON.parse(userInfoString));
    }
  }, [userInfoString]);

  useEffect(() => {
    if (userInfo) {
      setRoleFacility(userInfo && !userInfo.is_sys_admin && userInfo.role == 'ROLE_FACILITY');
    }
  }, [userInfo]);

  // console.log(displayGroups);

  useEffect(() => {
    if (masterData) {
      const masterDataObj = JSON.parse(masterData);
      if (master?.symptoms?.length > 0) {
        setSymptomsOpts(convertOptionMaster(master?.symptoms));
        setSymptomsOptsAll(convertOptionMaster(master?.symptoms));
      } else {
        setSymptomsOptsAll([]);
      }
      if (masterDataObj?.diseases?.length > 0) {
        setDiseasesOpts(
          convertOptionMaster(
            masterDataObj?.diseases.filter((item) => item.code !== 99)
          )
        );
        setDiseasesOptsAll(
          convertOptionMaster(
            masterDataObj?.diseases.filter((item) => item.code !== 99)
          )
        );
      } else {
        setDiseasesOpts([]);
        setDiseasesOptsAll([]);
      }
      if (masterDataObj?.living_situations?.length > 0) {
        setLivingSituationList(
          convertOptionMaster(masterDataObj?.living_situations)
        );
      } else {
        setLivingSituationList([]);
      }
      if (masterDataObj?.level_cares?.length > 0) {
        setListLevelCare(convertOptionMaster(masterDataObj?.level_cares));
      } else {
        setListLevelCare([]);
      }
    }
    return () => {
      setPatientEditting(null);
    };
  }, []);
  useEffect(() => {
    if (diapers?.length > 0) {
      setDiaperTypeList(convertOptionDiaper(diapers));
    } else {
      setDiaperTypeList([]);
    }
  }, [diapers]);

  useEffect(() => {
    if (patientDetail) {
      const currentDevice =
        devices && devices.find((d) => d.patient_id === patientDetail.id);
      if (currentDevice)
        setPatientEditting({ ...patientDetail, device: currentDevice || null });
      else setPatientEditting({ ...patientDetail });
    }
  }, [patientDetail]);
  useEffect(() => {
    formik.setFieldValue("group", undefined);

    if (patientEditting) {
      formik.setFieldValue("id", patientEditting.code || "");
      formik.setFieldValue("firstName", patientEditting.name || "");
      formik.setFieldValue("lastName", patientEditting.last_name || "");

      formik.setFieldValue("group", patientEditting.group?.id || "");
      if (patientEditting.date_of_birth) {
        formik.setFieldValue(
          "birthday",
          moment(patientEditting.date_of_birth).format("YYYY/MM/DD") || ""
        );
      }
      formik.setFieldValue("gender", patientEditting.gender || "");
      formik.setFieldValue("area", patientEditting.area_id || "");
      if (patientEditting?.admin_id) {
        formik.setFieldValue("group", patientEditting.group?.id || "");
      } else {
        formik.setFieldValue("group", patientEditting.group?.id || "");
      }

      formik.setFieldValue("levelCare", patientEditting.level_care || 0);
      formik.setFieldValue(
        "livingSituation",
        patientEditting.living_situation || ""
      );
      formik.setFieldValue("diaperType", patientEditting?.diaper_id || null);

      if (patientEditting?.diseases?.length > 0) {
        const diseaseData = patientEditting?.diseases.map((d) => {
          return d.disease_code !== 99 ? d.disease_code : d.disease_text;
        });
        const diseaseTextList = [];
        patientEditting?.diseases.forEach((ele) => {
          const foundDisease = master?.diseases?.find(
            (item) => item.code === ele.disease_code
          );
          if (foundDisease && foundDisease.code !== 99) {
            diseaseTextList.push(foundDisease.value);
          } else {
            diseaseTextList.push(ele.disease_text);
          }
        });
        formik.setFieldValue("diseases", diseaseData);
        setDiseasesText(diseaseTextList);
      } else {
        formik.setFieldValue("diseases", []);
      }
      if (patientEditting?.symptoms?.length > 0) {
        const symptomsTextList = [];
        patientEditting?.symptoms?.forEach((ele) => {
          const foundSymptoms = master?.symptoms?.find(
            (item) => item.code === ele
          );
          if (foundSymptoms) {
            symptomsTextList.push(foundSymptoms.value);
          }
        });
        setSymptomsText(symptomsTextList);
        formik.setFieldValue("symptoms", patientEditting?.symptoms);
      } else {
        formik.setFieldValue("symptoms", []);
      }
    }
  }, [patientEditting, listGroup, lang]);
  useEffect(() => {
    if (master?.symptoms?.length > 0) {
      setSymptomsOpts(convertOptionMaster(master?.symptoms));

      if (patientEditting?.diseases?.length > 0) {
        const diseaseData = patientEditting?.diseases.map((d) => {
          return d.disease_code !== 99 ? d.disease_code : d.disease_text;
        });
        const diseaseTextList = [];
        patientEditting?.diseases.forEach((ele) => {
          const foundDisease = master?.diseases?.find(
            (item) => item.code === ele.disease_code
          );
          if (foundDisease && foundDisease.code !== 99) {
            diseaseTextList.push(foundDisease.value);
          } else {
            diseaseTextList.push(ele.disease_text);
          }
        });
        formik.setFieldValue("diseases", diseaseData);
        setDiseasesText(diseaseTextList);
      } else {
        formik.setFieldValue("diseases", []);
      }
      if (patientEditting?.symptoms?.length > 0) {
        const symptomsTextList = [];
        patientEditting?.symptoms?.forEach((ele) => {
          const foundSymptoms = master?.symptoms?.find(
            (item) => item.code === ele
          );
          if (foundSymptoms) {
            symptomsTextList.push(foundSymptoms.value);
          }
        });
        setSymptomsText(symptomsTextList);
        formik.setFieldValue("symptoms", patientEditting?.symptoms);
      } else {
        formik.setFieldValue("symptoms", []);
      }
    } else {
      setSymptomsOpts([]);
    }
  }, [master.symptoms, lang]);
  useEffect(() => {
    if (master?.diseases?.length > 0) {
      setDiseasesOpts(
        convertOptionMaster(master?.diseases.filter((item) => item.code !== 99))
      );
    } else {
      setDiseasesOpts([]);
    }
  }, [master.diseases]);

  useEffect(() => {
    if (master?.living_situations?.length > 0) {
      setLivingSituationList(convertOptionMaster(master?.living_situations));
    } else {
      setLivingSituationList([]);
    }
  }, [master.living_situations]);
  useEffect(() => {
    if (master?.level_cares?.length > 0) {
      setListLevelCare(convertOptionMaster(master?.level_cares));
    } else {
      setListLevelCare([]);
    }
  }, [master.level_cares]);
  useEffect(() => {
    if (areas?.length > 0 && area) {
      setListArea(convertOptionAreaEdit(areas, area, lang));
    } else {
      setListArea([]);
    }
  }, [areas, area, lang]);
  useEffect(() => {
    if (groups?.length > 0) {
      setListGroup(convertOption(groups.filter((g) => g.name !== "DEFAULT")));
    } else {
      setListGroup([]);
    }
  }, [groups]);
  const sensorsResponse = [
    { id: 1, mac: "AC:59:FD:40:1F:A6", status: 1 },
    { id: 2, mac: "BD:A1:36:0F:5Z:B7", status: 2 },
  ];
  const onChangeDiseases = (value) => {
    if (value?.length > diseases?.length) {
      if (value?.length === 1 && value[0] == "0") {
        formik.setFieldValue("diseases", value);
        setDiseasesOpts([{ label: diseasesOpts[0].label, value: 0 }]);
      } else if (value.includes(0)) {
        formik.setFieldValue("diseases", [0]);
        setDiseasesOpts([{ label: diseasesOpts[0].label, value: 0 }]);
      } else {
        setDiseasesOpts(diseasesOptsAll);
        const newValue = value[value.length - 1];
        const found = diseasesOpts.find((item) => item.label === newValue);
        if (!found) {
          formik.setFieldValue("diseases", value);
        }
      }
    } else {
      setDiseasesOpts(diseasesOptsAll);
      formik.setFieldValue("diseases", value);
    }
  };
  const onChangeSymptoms = (value) => {
    if (value?.length === 1 && value[0] == "0") {
      formik.setFieldValue("symptoms", value);
      setSymptomsOpts([{ label: symptomsOpts[0].label, value: 0 }]);
    } else if (value.includes(0)) {
      formik.setFieldValue("symptoms", [0]);
      setSymptomsOpts([{ label: symptomsOpts[0].label, value: 0 }]);
    } else {
      setSymptomsOpts(symptomsOptsAll);
      const newValue = value[value.length - 1];
      const found = symptomsOpts.find((item) => item.label === newValue);
      if (!found) {
        formik.setFieldValue("symptoms", value);
      }
    }
  };
  const onCancelSearch = () => {
    setShowModalSearch(false);
  };
  const onRegisterSensor = () => {
    setShowModalSearchResult(false);
    setShowModalRegisterSuccess(true);
  };
  const modalSearch = () => {
    return (
      <div className="form-patient-detail-loading-sensor">
        <img
          src="/icons/loading.gif"
          className="form-patient-detail-loading-icon"
        />
        <div className="form-patient-detail-loading-message">
          {t("find_sensor")}
        </div>

        <Button
          key="back"
          text={t("button.cancel")}
          background="#0E6FBA"
          width={200}
          onClick={onCancelSearch}
        />
      </div>
    );
  };
  const modalSearchResult = () => {
    return (
      <div className="form-patient-detail-search-result">
        <div className="form-patient-detail-search-result-message">
          {t("result_find_sensor")}
        </div>
        <div className="form-patient-detail-search-result-list">
          {sensorsResponse?.map((sensor, index) => {
            return (
              <div
                key={index + "sensor"}
                className="form-patient-detail-search-result-item"
                style={{
                  background: sensor.status === 1 ? "#FDBA2C" : "#B4B2B2",
                }}
                onClick={() => setSensorSelected(sensor)}
              >
                <div className="form-patient-detail-search-result-item-text">
                  {sensor.mac}
                </div>
              </div>
            );
          })}
        </div>
        {roleFacility &&
          <Button
            key="back"
            text={t("button.register")}
            background="#0E6FBA"
            width={320}
            onClick={onRegisterSensor}
            disabled={!sensorSelected}
          />
        }
      </div>
    );
  };
  const modalConfirm = () => {
    return (
      <div className="modal-confirm-layout">
        <div className="modal-confirm-text">{t("confirm_delete")}</div>
        <div className="modal-confirm-buttons">
          {roleFacility &&
            <Button
              key="back"
              text={t("button.delete")}
              background="#0E6FBA"
              width={100}
              onClick={onDeletePatient}
              className="mr-20"
            />
          }
          {roleFacility &&
            <Button
              key="back"
              text={t("button.cancel")}
              background="#A6A6A6"
              width={110}
              onClick={() => setShowModalConfirm(false)}
            />
          }
        </div>
      </div>
    );
  };

  const modalRegisterSuccess = () => {
    return (
      <div className="form-patient-detail-search-result">
        <div className="form-patient-detail-search-result-message">
          {t("register_sensor_success")}
        </div>
        <div className="form-patient-detail-search-result-list">
          <div
            className="form-patient-detail-search-result-item"
            style={{
              background:
                sensorSelected && sensorSelected.status === 1
                  ? "#FDBA2C"
                  : "#B4B2B2",
            }}
          >
            <div className="form-patient-detail-search-result-item-text">
              {sensorSelected && sensorSelected.mac}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onCancelPatientForm = () => {
    setShowModalUpdatePatient(false);
    formik.resetForm();
    // setPatientEditting(null);
  };
  const onCheckDisease = () => {
    const diseaseTextList = [];
    diseases.forEach((ele) => {
      const foundDisease = master?.diseases?.find((item) => item.code === ele);
      if (foundDisease && foundDisease.code !== 99) {
        diseaseTextList.push(foundDisease.value);
      } else {
        diseaseTextList.push(ele);
      }
    });
    setDiseasesText(diseaseTextList);
    setDiseaseState(1);
  };
  const onCheckSymptom = () => {
    const symptomsTextList = [];
    symptoms?.forEach((ele) => {
      const foundSymptoms = master?.symptoms?.find((item) => item.code === ele);
      if (foundSymptoms) {
        symptomsTextList.push(foundSymptoms.value);
      }
    });
    setSymptomsText(symptomsTextList);
    setSymptomState(1);
  };
  const onDeletePatient = () => {
    callDeletePatient(patientEditting.id);
    setShowModalConfirm(false);
    setShowModalUpdatePatient(false);
  };
  const onViewChart = () => {
    history.push(`/${patientEditting.id}/analytic-urine`);
  };
  const onDeleteSensor = () => {
    callUnpairDevice(patientEditting.id, {
      mac: patientEditting?.device?.mac || "",
    });
    setShowModalUpdatePatient(false);
    let msg = t("msg.delete_device_success");
    showToastSuccess(msg);
    dispatch(resetMessages());
  };

  useEffect(() => {
    setDisplayGroups([
      ...(patientEditting.group != undefined && !isAdminFacility(userInfo)) ? [{ value: patientEditting?.group?.id, label: patientEditting.group.name }] : [],
      ...(isAdminFacility(userInfo) ? listGroup : []),
    ]);
  }, [listGroup, patientEditting, userInfo])

  return (
    <form
      className="form-patient-detail-container"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-patient-detail-rowid">
        <FormInput
          title={t("sub_title_userid")}
          width={160}
          value={id}
          titleSize={12}
          name="id"
          onChange={formik.handleChange}
          disabled={true}
        />
      </div>
      <div className="form-patient-detail-row">
        <div className="form-patient-detail-col">
          <FormInput
            title={t("first_name")}
            width={100}
            onChange={formik.handleChange}
            error={formik.errors.firstName}
            name="firstName"
            value={firstName}
          />
          <FormInput
            placeholder={t("name")}
            title={t("last_name")}
            width={70}
            name="lastName"
            value={lastName}
            onChange={formik.handleChange}
            error={formik.errors.lastName}
          />
        </div>
        <div className="form-patient-detail-col">
          <Select
            title={t("gender_label")}
            options={GENDER}
            name="gender"
            value={gender}
            onChange={(value) => formik.setFieldValue("gender", value)}
            error={formik.errors.gender}
          />
        </div>
        <div className="form-patient-detail-col date-picker">
          <DatePicker
            title={t("birthday")}
            placeholder="YYYY/MM/DD"
            name="birthday"
            id="detail-datepicker"
            value={new Date(birthday) || null}
            onChange={(date) => formik.setFieldValue("birthday", date)}
            error={formik.errors.birthday}
          />
        </div>
        <div className="form-patient-detail-col">
          <Select
            title={t("area")}
            options={listArea}
            name="area"
            value={area}
            onChange={(value) => formik.setFieldValue("area", value)}
            error={formik.errors.area}
          />
        </div>
        <div className="form-patient-detail-col">
          <Select
            title={t("group")}
            options={displayGroups}
            name="group"
            value={group}
            onChange={(value) => formik.setFieldValue("group", value)}
            error={formik.errors.group}
          />
        </div>
      </div>
      <div className="form-patient-detail-row">
        <div className="form-patient-detail-col-merge">
          <div className="form-patient-detail-form-multi">
            <div>{t("disease")}</div>
            {diseaseState === 1 ? (
              <div>
                <div className="row">
                  <img
                    src="/icons/editIcon.png"
                    className="form-patient-detail-edit-icon"
                    onClick={() => setDiseaseState(2)}
                  />
                  <div className="form-patient-detail-list-diseases">
                    {diseasesText?.length === 0
                      ? t("not_set")
                      : diseasesText.join(", ")}
                  </div>
                </div>
                {formik.errors.diseases && (
                  <div className="error">{formik.errors.diseases}</div>
                )}
              </div>
            ) : (
              <div className="row">
                <Select
                  value={diseases}
                  options={diseasesOpts}
                  mode="tags"
                  width={370}
                  name="diseases"
                  onChange={(value) => onChangeDiseases(value)}
                  error={formik.errors.diseases}
                />
                <span className="check-icon" onClick={onCheckDisease}>
                  <CheckOutlined />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="form-patient-detail-col">
          <Select
            title={t("level_care")}
            options={listLevelCare}
            name="levelCare"
            value={levelCare}
            onChange={(value) => formik.setFieldValue("levelCare", value)}
            error={formik.errors.levelCare}
          />
        </div>
        <div className="form-patient-detail-col">
          <Select
            title={t("living_situation")}
            name="livingSituation"
            value={livingSituation}
            options={livingSituationList}
            onChange={(value) => formik.setFieldValue("livingSituation", value)}
            error={formik.errors.livingSituation}
          />
        </div>
      </div>
      <div className="form-patient-detail-row">
        <div className="form-patient-detail-col-merge">
          <div className="form-patient-detail-form-multi">
            <div>{t("symptoms")}</div>
            {symptomState === 1 ? (
              <div>
                <div className="row">
                  <img
                    src="/icons/editIcon.png"
                    className="form-patient-detail-edit-icon"
                    onClick={() => setSymptomState(2)}
                  />
                  <div className="form-patient-detail-list-diseases">
                    {symptomsText.length === 0
                      ? t("not_set")
                      : symptomsText.join(", ")}
                  </div>
                </div>
                {formik.errors.symptoms && (
                  <div className="error">{formik.errors.symptoms}</div>
                )}
              </div>
            ) : (
              <div className="row">
                <Select
                  value={symptoms}
                  name="symptoms"
                  options={symptomsOpts}
                  mode="multiple"
                  error={formik.errors.symptoms}
                  width={370}
                  onChange={(value) => onChangeSymptoms(value)}
                />
                <span className="check-icon" onClick={onCheckSymptom}>
                  <CheckOutlined />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {patientEditting?.device?.patient_id && (
        <div className="form-patient-detail-row mt-20">
          <div className="form-patient-detail-sensor">
            {t("sensor_selection")} :
          </div>
          <div className="form-patient-detail-sensor-status">{t("done")}</div>
        </div>
      )}
      <div className="form-patient-detail-row-seach">
        {roleFacility && patientEditting &&
          patientEditting?.device &&
          patientEditting?.device?.patient_id &&
          patientDetail ? (
          <div className="delete-sensor-modal-button" onClick={onDeleteSensor}>
            {t("delete_sensor")}
          </div>
        ) : null}
        <Button
          key="back"
          text={t("view_analytic_chart")}
          background="rgb(253, 186, 44)"
          width={190}
          onClick={onViewChart}
          type="button"
        />
      </div>
      {roleFacility &&
        <div className="form-patient-footer-button">
          <Button
            key="back"
            type="button"
            text={t("button.delete")}
            background="#00BF63"
            className="mr-10"
            onClick={() => setShowModalConfirm(true)}
          />
          <Button
            key="submit"
            type="submit"
            text={t("button.edit")}
            background="#FDBA2C"
            className="mr-10"
          />
          {/* <Button
          className="mr-10"
          onClick={onCancelPatientForm}
          text={t("button.cancel")}
          background="#A6A6A6"
        /> */}
          <div className="cancel-modal-button" onClick={onCancelPatientForm}>
            {t("button.cancel")}
          </div>
        </div>
      }
      <Modal
        showModal={showModalSearch}
        content={modalSearch()}
        onCancel={() => setShowModalSearch(false)}
        title=""
        footer={[]}
        closeIcon={false}
      />
      <Modal
        showModal={showModalSearchResult}
        content={modalSearchResult()}
        onCancel={() => setShowModalSearchResult(false)}
        title=""
        footer={[]}
      />
      <Modal
        showModal={showModalRegisterSuccess}
        content={modalRegisterSuccess()}
        title=""
        footer={[]}
        onCancel={() => {
          setShowModalRegisterSuccess(false);
          setShowModalUpdatePatient(false);
          formik.resetForm();
        }}
      />
      <Modal
        showModal={showModalConfirm}
        width={500}
        content={modalConfirm()}
        onCancel={() => setShowModalConfirm(false)}
        title=""
        footer={[]}
      />
    </form>
  );
};
export default FormPatientDetail;
