import { get, patch, put, del, post } from "../../api_helper";
import * as url from "../../url_helper";
import { convertObjectToParams } from "utils";

export const apiGetPatients = (data) => {
  let urlApi = url.API_PATIENTS;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
// export const apiGetPatientFacility = (data) => {
//   let urlApi = url.API_PATIENT_FACILITY;
//   urlApi = urlApi + "/" + data.facility_id + `?group_id=${data.group_id}`;

//     if (data.group_id) {
//     urlApi = urlApi + "/" + data.facility_id + ?group_id=${data.group_id};
//     return get(urlApi);
//   }
//   return get(urlApi);
// };

export const apiGetPatientFacility = (data) => {
  let urlApi = url.API_PATIENT_FACILITY;
  urlApi = urlApi + `?partner_id=${data.partner_id == undefined ? '' : data.partner_id}&facility_id=${data.facility_id == undefined ? '' : (data.facility_id == "ALL_PERSONAL" ? "ALL" : data.facility_id)}&group_id=${data.group_id == undefined ? '' : data.group_id}&patient_id=${data.patient_id == undefined ? '' : data.patient_id}`;
  return get(urlApi);
}


export const apiPutPatient = (data) => put(url.API_PATIENTS, data);
export const apiGetDetailPatient = (id) => get(url.API_PATIENTS + id);
export const apiPatchPatient = (id, data) => patch(url.API_PATIENTS + id, data);
export const apiDelPatient = (id) => del(url.API_PATIENTS + id);

export const apiGetPatientsById = (id) => get(url.API_PATIENTS + id);
export const apiGetPatientsAnalyticDiaperResize = () =>
  get(url.API_ANALYTICS_DIAPER_RESIZE);
export const apiChangeSizeDiaperForPatient = (id, data) =>
  post(`${url.API_PATIENTS}/${id}/diaper-resize`, data);
export const apiGetAnalyticUrine = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_URINE}${id}/analytic-urine`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiGetAnalyticUrineExternal = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_URINE_EXTERNAL}${id}/analytic-urine`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi, {
    headers: {
      "x-api-key": "rSMtON95eiitIta7lD2x1g==",
    },
  });
};
export const apiGetAnalyticDiaperResize = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_DIAPER_RESIZE}${id}/analytics-diaper-resize`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiGetAnalyticDiaperResizeExternal = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_DIAPER_RESIZE}external/${id}/analytics-diaper-resize`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi, {
    headers: {
      "x-api-key": "rSMtON95eiitIta7lD2x1g==",
    },
  });
};
export const apiGetAnalyticDiaperInfo = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_DIAPER_INFO}${id}/analytics-diaper-info`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiGetAnalyticDiaperInfoExternal = (id, data) => {
  let urlApi = `${url.API_ANALYTIC_DIAPER_INFO}external/${id}/analytics-diaper-info`;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi, {
    headers: {
      "x-api-key": "rSMtON95eiitIta7lD2x1g==",
    },
  });
};
