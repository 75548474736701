export const PaymentState = {
    Pendding: 'NOT_CONFIRM',
    Paided: 'PAYMENT',
    Confirmed: 'CONFIRMED'
};



export const classPaymentState = (v) => {
    switch (v) {
        case PaymentState.Confirmed:
            return 'confirmed';
            break;

        case PaymentState.Paided:
            return 'paided';
            break;

        case PaymentState.Pendding:
        default:
            return 'pendding';
            break;
    }
};