import { useTranslation } from "react-i18next";
export const USER_LIST = "/";
export const PERSON_IN_CHARGE = "/pic";
export const GROUP_SETTING = "/group";
export const MASTER_SETTING = "/master-setting";
export const FACILITY_INFORMATION_SETTING = "/facility";
export const DIAPER_TYPE_SETTING = "/diaper-type";
export const DEVICE_SETTING = "/device";
export const DEG_SETTING = "/deg";
export const USER_SETTING = "/user";
export const PASSWORD_SETTING = "/password";
export const RESET_PASSWORD = "/reset-password";
export const PAYMENT = "/payment";
export const LOGOUT = "/logout";
export const CSVEXPORT = "/facility/csv/export"

const MenuList = () => {
  const { t } = useTranslation();
  return [
    {
      key: "USER_LIST",
      title: t("menus.user_list"),
      url: USER_LIST,
      childs: [],
    },
    {
      key: "PERSON_IN_CHARGE",
      title: t("menus.pic"),
      url: PERSON_IN_CHARGE,
      childs: [],
    },
    {
      key: "GROUP_SETTING",
      title: t("menus.group"),
      url: GROUP_SETTING,
      childs: [],
    },
    {
      key: "MASTER_SETTING",
      title: t("menus.master"),
      url: MASTER_SETTING,
      childs: [
        {
          key: "FACILITY_INFORMATION_SETTING",
          title: t("menus.facility_info"),
          url: FACILITY_INFORMATION_SETTING,
          childs: [],
        },
      ],
    },
    {
      key: "PASSWORD_SETTING",
      title: t("menus.password"),
      url: PASSWORD_SETTING,
      childs: [],
    },
    {
      key: "CSVEXPORT",
      title: t("menus.csv_export"),
      url: CSVEXPORT,
      childs: []
    },
    {
      key: "LOGOUT",
      title: t("menus.logout"),
      url: LOGOUT,
      childs: [],
    },
  ];
};
export default MenuList;
