export const RESET_FACILITY_MESSAGE = "RESET_FACILITY_MESSAGE";

export const GET_FACILITIES = "GET_FACILITIES";
export const GET_FACILITIES_SUCCESS = "GET_FACILITIES_SUCCESS";
export const GET_FACILITIES_FAIL = "GET_FACILITIES_FAIL";

export const GET_FACILITIES_BY_PARTNER = "GET_FACILITIES_BY_PARTNER";
export const GET_FACILITIES_BY_PARTNER_SUCCESS = "GET_FACILITIES_BY_PARTNER_SUCCESS";
export const GET_FACILITIES_BY_PARTNER_FAIL = "GET_FACILITIES_BY_PARTNER_FAIL";

export const UPDATE_FACILITY = "UPDATE_FACILITY";
export const UPDATE_FACILITY_SUCCESS = "UPDATE_FACILITY_SUCCESS";
export const UPDATE_FACILITY_FAIL = "UPDATE_FACILITY_FAIL";

export const ADD_FACILITY = "ADD_FACILITY";
export const ADD_FACILITY_SUCCESS = "ADD_FACILITY_SUCCESS";
export const ADD_FACILITY_FAIL = "ADD_FACILITY_FAIL";

export const UPDATE_FACILITY_PAYMENT = "UPDATE_FACILITY_PAYMENT";
export const UPDATE_FACILITY_PAYMENT_SUCCESS = "UPDATE_FACILITY_PAYMENT_SUCCESS";
export const UPDATE_FACILITY_PAYMENT_FAIL = "UPDATE_FACILITY_PAYMENT_FAIL";

