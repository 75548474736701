import {
  RESET_MASTER_MESSAGE,
  GET_GROUPS,
  GET_GROUPS2,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUPS_BY_FACILITY,
  GET_GROUPS_BY_FACILITY_SUCCESS,
  GET_GROUPS_BY_FACILITY_FAIL,
  GET_MASTER,
  GET_MASTER_SUCCESS,
  GET_MASTER_FAIL,
  GET_MASTER_AREA,
  GET_MASTER_AREA_SUCCESS,
  GET_MASTER_AREA_FAIL,
  GET_MASTER_DIAPER,
  GET_MASTER_DIAPER_SUCCESS,
  GET_MASTER_DIAPER_FAIL,
  GET_MASTER_STAFF,
  GET_MASTER_STAFF_SUCCESS,
  GET_MASTER_STAFF_FAIL,
  UPDATE_MASTER_STAFF,
  UPDATE_MASTER_STAFF_SUCCESS,
  UPDATE_MASTER_STAFF_FAIL,
  ADD_MASTER_STAFF,
  ADD_MASTER_STAFF_SUCCESS,
  ADD_MASTER_STAFF_FAIL,
  DELETE_MASTER_STAFF,
  DELETE_MASTER_STAFF_SUCCESS,
  DELETE_MASTER_STAFF_FAIL,
  ADD_MASTER_GROUP,
  ADD_MASTER_GROUP_SUCCESS,
  ADD_MASTER_GROUP_FAIL,
  UPDATE_MASTER_GROUP,
  UPDATE_MASTER_GROUP_SUCCESS,
  UPDATE_MASTER_GROUP_FAIL,
  UPDATE_MASTER_DIAPER,
  UPDATE_MASTER_DIAPER_SUCCESS,
  UPDATE_MASTER_DIAPER_FAIL,
  ADD_MASTER_DIAPER,
  ADD_MASTER_DIAPER_SUCCESS,
  ADD_MASTER_DIAPER_FAIL,
  DELETE_MASTER_DIAPER,
  DELETE_MASTER_DIAPER_SUCCESS,
  DELETE_MASTER_DIAPER_FAIL,
  GET_MASTER_DEG,
  GET_MASTER_DEG_SUCCESS,
  GET_MASTER_DEG_FAIL,
  UPDATE_MASTER_DEG,
  UPDATE_MASTER_DEG_SUCCESS,
  UPDATE_MASTER_DEG_FAIL,
  GET_GROUPS_SUCCESS2,
} from "./actionTypes";

export const resetMasterMessage = () => {
  return {
    type: RESET_MASTER_MESSAGE,
  };
};

export const getGroups = (payload) => {
  return {
    type: GET_GROUPS,
    payload,
  };
};

export const getGroups2 = (payload) => {
  return {
    type: GET_GROUPS2,
    payload,
  };
};

export const getGroupsSuccess = (group) => {
  return {
    type: GET_GROUPS_SUCCESS,
    payload: group,
  };
};

export const getGroupsSuccess2 = (group) => {
  return {
    type: GET_GROUPS_SUCCESS2,
    payload: group,
  };
};

export const getGroupsFail = (error) => {
  return {
    type: GET_GROUPS_FAIL,
    payload: error,
  };
};
export const getGroupByFacility = (payload) => {
  return {
    type: GET_GROUPS_BY_FACILITY,
    payload,
  };
};

export const getGroupByFacilitySuccess = (group) => {
  return {
    type: GET_GROUPS_BY_FACILITY_SUCCESS,
    payload: group,
  };
};

export const getGroupByFacilityFail = (error) => {
  return {
    type: GET_GROUPS_BY_FACILITY_FAIL,
    payload: error,
  };
};
export const addMasterGroup = (payload) => {
  return {
    type: ADD_MASTER_GROUP,
    payload,
  };
};

export const addMasterGroupSuccess = (payload) => {
  return {
    type: ADD_MASTER_GROUP_SUCCESS,
    payload,
  };
};

export const addMasterGroupFail = (error) => {
  return {
    type: ADD_MASTER_GROUP_FAIL,
    payload: error,
  };
};
export const updateMasterGroup = (payload) => {
  return {
    type: UPDATE_MASTER_GROUP,
    payload,
  };
};

export const updateMasterGroupSuccess = (payload) => {
  return {
    type: UPDATE_MASTER_GROUP_SUCCESS,
    payload,
  };
};

export const updateMasterGroupFail = (error) => {
  return {
    type: UPDATE_MASTER_GROUP_FAIL,
    payload: error,
  };
};
export const getMaster = () => {
  return {
    type: GET_MASTER,
  };
};

export const getMasterSuccess = (group) => {
  return {
    type: GET_MASTER_SUCCESS,
    payload: group,
  };
};

export const getMasterFail = (error) => {
  return {
    type: GET_MASTER_FAIL,
    payload: error,
  };
};

export const getMasterArea = () => {
  return {
    type: GET_MASTER_AREA,
  };
};

export const getMasterAreaSuccess = (group) => {
  return {
    type: GET_MASTER_AREA_SUCCESS,
    payload: group,
  };
};

export const getMasterAreaFail = (error) => {
  return {
    type: GET_MASTER_AREA_FAIL,
    payload: error,
  };
};

export const getMasterDiaper = (payload) => {
  return {
    type: GET_MASTER_DIAPER,
    payload,
  };
};

export const getMasterDiaperSuccess = (group) => {
  return {
    type: GET_MASTER_DIAPER_SUCCESS,
    payload: group,
  };
};

export const getMasterDiaperFail = (error) => {
  return {
    type: GET_MASTER_DIAPER_FAIL,
    payload: error,
  };
};
export const getMasterStaff = (payload) => {
  return {
    type: GET_MASTER_STAFF,
    payload,
  };
};

export const getMasterStaffSuccess = (payload) => {
  return {
    type: GET_MASTER_STAFF_SUCCESS,
    payload,
  };
};

export const getMasterStaffFail = (error) => {
  return {
    type: GET_MASTER_STAFF_FAIL,
    payload: error,
  };
};
export const addMasterStaff = (payload) => {
  return {
    type: ADD_MASTER_STAFF,
    payload,
  };
};

export const addMasterStaffSuccess = (payload) => {
  return {
    type: ADD_MASTER_STAFF_SUCCESS,
    payload,
  };
};

export const addMasterStaffFail = (error) => {
  return {
    type: ADD_MASTER_STAFF_FAIL,
    payload: error,
  };
};

export const updateMasterStaff = (payload) => {
  return {
    type: UPDATE_MASTER_STAFF,
    payload,
  };
};

export const updateMasterStaffSuccess = (payload) => {
  return {
    type: UPDATE_MASTER_STAFF_SUCCESS,
    payload,
  };
};

export const updateMasterStaffFail = (error) => {
  return {
    type: UPDATE_MASTER_STAFF_FAIL,
    payload: error,
  };
};

export const deleteMasterStaff = (payload) => {
  return {
    type: DELETE_MASTER_STAFF,
    payload,
  };
};

export const deleteMasterStaffSuccess = (payload) => {
  return {
    type: DELETE_MASTER_STAFF_SUCCESS,
    payload,
  };
};

export const deleteMasterStaffFail = (error) => {
  return {
    type: DELETE_MASTER_STAFF_FAIL,
    payload: error,
  };
};
export const addMasterDiaper = (payload) => {
  return {
    type: ADD_MASTER_DIAPER,
    payload,
  };
};

export const addMasterDiaperSuccess = (payload) => {
  return {
    type: ADD_MASTER_DIAPER_SUCCESS,
    payload,
  };
};

export const addMasterDiaperFail = (error) => {
  return {
    type: ADD_MASTER_DIAPER_FAIL,
    payload: error,
  };
};

export const updateMasterDiaper = (payload) => {
  return {
    type: UPDATE_MASTER_DIAPER,
    payload,
  };
};

export const updateMasterDiaperSuccess = (payload) => {
  return {
    type: UPDATE_MASTER_DIAPER_SUCCESS,
    payload,
  };
};

export const updateMasterDiaperFail = (error) => {
  return {
    type: UPDATE_MASTER_DIAPER_FAIL,
    payload: error,
  };
};

export const deleteMasterDiaper = (payload) => {
  return {
    type: DELETE_MASTER_DIAPER,
    payload,
  };
};

export const deleteMasterDiaperSuccess = (payload) => {
  return {
    type: DELETE_MASTER_DIAPER_SUCCESS,
    payload,
  };
};

export const deleteMasterDiaperFail = (error) => {
  return {
    type: DELETE_MASTER_DIAPER_FAIL,
    payload: error,
  };
};

export const getMasterDeg = () => {
  return {
    type: GET_MASTER_DEG,
  };
};

export const getMasterDegSuccess = (payload) => {
  return {
    type: GET_MASTER_DEG_SUCCESS,
    payload,
  };
};

export const getMasterDegFail = (error) => {
  return {
    type: GET_MASTER_DEG_FAIL,
    payload: error,
  };
};
export const updateMasterDeg = (payload) => {
  return {
    type: UPDATE_MASTER_DEG,
    payload,
  };
};

export const updateMasterDegSuccess = (payload) => {
  return {
    type: UPDATE_MASTER_DEG_SUCCESS,
    payload,
  };
};

export const updateMasterDegFail = (error) => {
  return {
    type: UPDATE_MASTER_DEG_FAIL,
    payload: error,
  };
};