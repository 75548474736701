import Button from "components/button/Button";
import FormInput from "components/formInput/Input";
import { useFormik } from "formik";
import useNotify from "hooks/useNotify";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import React from 'react';
import { Container, Row, Col } from 'react-grid';

import * as Yup from "yup";
import "./styles.scss";
import Lable from "components/label/label";
import DatePickerComponent from "components/datepicker/Datepicker";
import Select from "components/select/Select";
import Cookies from "js-cookie";
import FacilityApiHook from "hooks/facility/facilityApiHook";
import FacilityHook from "hooks/facility/facilityHook";
import GroupApiHook from "hooks/group/groupApiHook";
import GroupHook from "hooks/group/groupHook";
import PartnerApiHook from "hooks/partner/partnerApiHook";
import PartnerHook from "hooks/partner/partnerHook";
import { Input } from "antd";
import HomeHook from "hooks/home/homeHook";
import HomeApiHook from "hooks/home/homeApiHook";
import CSVApiHook from "hooks/csv/csvApiHook";
import CSVHook from "hooks/csv/csvHook";
import moment from "moment/moment";
import { resetMessages } from "store/csv/actions";
import { getPatientByFacilities } from "store/patients/actions";


const CsvExportSanwa = () => {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const { showToastSuccess, showToastError } = useNotify();

    const { exportListFacilities, exportListPatients, exportDataSensor } = CSVApiHook();

    const [isAuthen, setIsAuthen] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [dataTypeOptions, setDataTypeOptions] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState({});
    const { getListFacility } = FacilityApiHook();
    const { getListGroup2 } = GroupApiHook();
    const { getListPartner } = PartnerApiHook();

    const [disableExport, setDEX] = useState(false);
    const [invalidRangeDate, setIRD] = useState(true);

    const { facilityList } = FacilityHook();
    const { groupList2 } = GroupHook();
    const { partnerList } = PartnerHook();
    const { callGetPatientByFacility } = HomeApiHook();
    const { patientList } = HomeHook();

    const { exportedLink, inProgressExport, success, error, } = CSVHook();

    const [externalLink, setEL] = useState('');

    useEffect(() => {
        setEL(exportedLink);
    }, [exportedLink]);

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            startDate: Date.now(),
            endDate: Date.now(),
            partnerId: 'DEFAULT_SANWA',
            groupId: undefined,
            facilityId: undefined,
            dataType: undefined,
            patientExprotId: undefined,
            patientName: '',
            patientCode: '',
            facilityName: '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values, { setErrors, resetForm }) => {
            if (dataType == '0') {
                if (values && values.partnerId && values.partnerId != 'DEFAULT_SANWA') {
                    exportListFacilities({ ...values });
                } else {
                    exportListFacilities();
                }
            }

            if (dataType == '1') {

                const submitPartnerId = partnerId == "DEFAULT_SANWA" ? "SANWA" : partnerId;
                console.log(submitPartnerId);

                exportListPatients({ ...values, ...{ partnerId: submitPartnerId }, ...{ groupId: groupId == undefined ? 'ALL' : groupId }, ...(isAdminFacility(userInfo) ? { facilityId: userInfo.id, facilityName: userInfo.name } : {}) });
            }

            if (dataType == '2') {
                if (isAdminFacility(userInfo) && (!groupId && !patientExprotId)) {
                    showToastError(t('msg.export_invalid_data'));
                    return
                }

                if (isAdminSanwa(userInfo) && (partnerId == '' && facilityId == '' && groupId == '' && patientExprotId == '')) {
                    showToastError(t('msg.export_invalid_data'));
                    return
                }

                if (isPartner(userInfo) && (facilityId == '' && groupId == '' && patientExprotId == '')) {
                    showToastError(t('msg.export_invalid_data'));
                    return
                }

                const submitPartnerId = partnerId == "DEFAULT_SANWA" ? "SANWA" : partnerId;
                console.log(submitPartnerId);

                exportDataSensor({ ...values, ...{ partnerId: submitPartnerId }, ...(isAdminFacility(userInfo) ? { facilityName: userInfo.name, facilityId: facilityId, group_id: groupId, partner_id: submitPartnerId } : {}) });
            }
        },
        onChange: () => {

        }
    });

    const {
        startDate,
        endDate,
        groupId,
        staffId,
        facilityId,
        dataType,
        partnerId,
        patientExprotId,
    } = formik.values;

    useEffect(() => {
        setEL('')
        setDEX(false);
        formik.setFieldValue('patientExprotId', undefined)

        if ((facilityList.length <= 0 || patientList.length <= 0) && (dataType == undefined)) {
            return
        }


        if (isAdminSanwa(userInfo) || isAdminFacility(userInfo)) {
            if ((dataType == '0')) {
                formik.setFieldValue('patientExprotId', 'ALL')
                console.log(partnerList, patientList, groupList2)
                if (partnerList == undefined) {
                    return;
                }

                if (partnerList.length <= 0) {
                    setDEX(true);
                } else {
                    setDEX(false);
                }
                return
            } else {
                if (dataType == '1') {
                    if (((groupList2.length <= 0))) {
                        setDEX(true);
                    } else {
                        setDEX(false)
                    }
                    return

                }
                if (((groupList2.length <= 0 || patientList.length <= 0))) {
                    setDEX(true);
                } else {
                    setDEX(false)
                }
            }
        }


        if (isPartner(userInfo)) {
            if ((dataType == '0')) {
                setDEX(false);
                return;
            } else
                if (dataType == '1') {
                    if (((groupList2.length <= 0))) {
                        setDEX(true);
                    } else {
                        setDEX(false)
                    }
                    return
                }
            if (((groupList2.length <= 0 || patientList.length <= 0))) {
                setDEX(true);
            } else {
                setDEX(false)
            }
        }
    }, [dataType]);


    useEffect(() => {
        const userInfoString = Cookies.get("USER_INFO");
        setIsAuthen(!!userInfoString);
        if (userInfoString) {
            const userInfomation = JSON.parse(userInfoString)
            setUserInfo(userInfomation);

            console.log("userInfo");
            console.log(userInfomation);
            const options = [
                ...!isAdminFacility(userInfomation) ? [{ label: t('export_page.options.facility'), value: '0' }] : [],
                ...[
                    { label: t('export_page.options.group_n_user'), value: '1' }, // {/* danh sach benh nhan */}
                    { label: t('export_page.options.data_sensor'), value: '2' }, // data benh nhan (semsor)
                ],
            ];
            setDataTypeOptions(options)
            formik.setFieldValue('dataType', options[0].value);

            if (dataTypeOptions.length == 0) {
                if (isAdminSanwa(userInfomation)) {
                    getListPartner();
                }
            }

            if (isAdminFacility(userInfomation)) {
                formik.setFieldValue('facilityId', userInfomation.id);
                getListGroup2({ facility_id: userInfomation.id, partner_id: partnerId });

            }


            if (isPartner(userInfomation)) {
                formik.setFieldValue('partnerId', userInfomation.id);
            }

        }
    }, []);

    useEffect(() => {
        setEL('')
        formik.setFieldValue('patientExprotId', undefined)

        if (groupId == undefined || groupId == '') {
            return
        }

        const partnerIdSubmit = partnerId == 'DEFAULT_SANWA' ? "SANWA" : partnerId;

        if ((facilityId != undefined || isAdminFacility(userInfo)) && groupId) {
            dispatch(getPatientByFacilities({ facility_id: facilityId, group_id: groupId, partner_id: partnerIdSubmit, patient_id: facilityId === "ALL_PERSONAL" ? "ALL" : undefined }));
        } else
            if (facilityId && !groupId) {
                dispatch(getPatientByFacilities({ facility_id: facilityId != '' ? facilityId : userInfo.id, group_id: groupId, partner_id: partnerIdSubmit, patient_id: facilityId === "ALL_PERSONAL" ? "ALL" : undefined }));
            }
    }, [groupId]);

    useEffect(() => {
        setEL('')
        formik.setFieldValue('patientExprotId', undefined);

        if (partnerId == undefined) {
            return
        }


        if (isAdminSanwa(userInfo) || isPartner(userInfo)) {
            getListFacility({ partner_id: partnerId });
        }
    }, [partnerId, partnerList]);


    useEffect(() => {
        setEL('')
        if (facilityId == undefined) {
            return
        }

        // formik.setFieldValue('patientExprotId', undefined);
        if (facilityId == 'ALL') {
            formik.setFieldValue('facilityName', t('all'))
        }

        const selectedF = facilityList.find((e) => e.id == facilityId);
        //  check selected Partner
        getListGroup2({ facility_id: facilityId, partner_id: partnerId });

        if (!selectedF) {
            return
        }
        formik.setFieldValue('facilityName', isAdminFacility(userInfo) ? userInfo.name : selectedF.name)
    }, [facilityId]);

    useEffect(() => {
        if (dataType == '0' || dataType == undefined) {
            return;
        }
        formik.setFieldValue('groupId', undefined);

        console.log(groupList2?.length, groupList2[0]?.id, facilityId);

        if (groupList2?.length > 0 && groupList2[0]?.id) {
            if ((facilityId && isAdminSanwa(userInfo))) {
                formik.setFieldValue('groupId', groupList2[0].id);
                setDEX(false);
            }

            if (isAdminFacility(userInfo)) {
                formik.setFieldValue('groupId', groupList2[0].id);
                setDEX(false);
            }

            if (isPartner(userInfo)) {
                formik.setFieldValue('groupId', groupList2[0].id);
                setDEX(false);
            }

        } else {
            if (facilityId == 'ALL') {
                formik.setFieldValue('groupId', undefined);
                // setDEX(false);
                return;
            }

            if (dataType == '0') {
                return;
            }
            formik.setFieldValue('patientExprotId', undefined);

            setDEX(true);
        }
    }, [groupList2, dataType]);

    useEffect(() => {
        formik.setFieldValue('patientExprotId', undefined);

        if (groupList2.length <= 0) {
            return
        }

        if (patientList?.length > 0) {
            formik.setFieldValue('patientExprotId', patientList[0].id);
            additionalFieldsPatient(patientList[0].id)
            // setDEX(false);
        } else {

            if (groupId == 'ALL') {
                formik.setFieldValue('patientExprotId', undefined);
                additionalFieldsPatient(undefined);
                if (isAdminSanwa(userInfo)) {
                    console.log('patientExprotId');
                    setDEX(dataType != '1');
                } else {
                    setDEX(dataType != '1');
                }
                return
            }

            if (dataType == '2') {
                setDEX(true);
                return
            }
        }
    }, [patientList, dataType]);

    useEffect(() => {
        if (isAdminSanwa(userInfo) || isPartner(userInfo)) {
            formik.setFieldValue('facilityId', undefined);
        }

        if (facilityList?.length > 0) {
            formik.setFieldValue('facilityId', facilityList[0].id);
            setDEX(false);
        } else {
            setDEX(true);
        }
    }, [facilityList]);

    useEffect(() => {
        console.log('export_csv_successfully or fail', success, error);
        if (success?.type === "GET") {
            showToastSuccess(t("msg.export_csv_successfully"));
            dispatch(resetMessages())
        }
        if (error?.code === 400) {
            showToastError(t("msg.export_csv_failed"));
            dispatch(resetMessages())

        }

    }, [success, error]);

    const additionalFieldsPatient = (v) => {
        if (v == 'ALL') {
            formik.setFieldValue('patientName', 'ALL')
            formik.setFieldValue('patientCode', 'ALL')

            return;
        }


        const selectedPatient = patientList.find((e) => e.id == v);

        if (!selectedPatient) {
            formik.setFieldValue('patientName', '')
            formik.setFieldValue('patientCode', '')
            return;
        }
        formik.setFieldValue('patientName', `${selectedPatient.name} ${selectedPatient.last_name}`)
        formik.setFieldValue('patientCode', selectedPatient.code)
    }

    useEffect(() => {
        setIRD(false)

        if (!startDate && !endDate) {
            return
        }

        if (dataType != '2') {
            return;
        }

        const start = moment(startDate).startOf('day');
        const maxRange = moment(start).add(1, 'M').format();
        const end = moment(endDate).startOf('day');
        setIRD(false)
        console.log("change date", start.isSameOrAfter(end));

        if (!start.isSameOrBefore(end)) {
            showToastError(t('msg.export_csv_date_time_invalid_before_start'));
            setIRD(true)
            return
        } else
            if (end.isAfter(maxRange)) {
                showToastError(t('msg.export_csv_date_time_invalid_out_of_range'));
                setIRD(true)
                return
            }
    }, [startDate, endDate]);


    return (<div>
        <form className="csv-optional-container" onSubmit={formik.handleSubmit}>
            <Container fluid >
                <Input type="hidden" value={isAdminFacility(userInfo) ? 'ADMIN_FACILITY' : isAdminSanwa(userInfo) ? 'ADMIN_SANWA' : 'PARTNER'}></Input>
                <div style={{ marginBlock: 20 }}>
                    <Row>
                        <Col offset={{ xs: 3 }} md={2}>
                            <Lable label={t('export_page.data_type')}></Lable> {/* Loai du lieu */}
                        </Col>
                        <Col>
                            <div style={{ marginInline: 10 }}>
                                <Select
                                    placeholder={t('export_page.data_type')}
                                    name='dataType'
                                    id='dataType'
                                    value={formik.values.dataType}
                                    onChange={(v) => {
                                        formik.setFieldValue('dataType', v);
                                    }}
                                    options={dataTypeOptions}
                                    width={445}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                {dataType == '2' && <Row>
                    <Col offset={{ xs: 3 }} md={2}>
                        <Lable label={t('export_page.range_date')}></Lable>
                    </Col>
                    <Col md={7}>

                        <div style={{ marginInline: 12 }}>
                            <Row>
                                <DatePickerComponent
                                    placeholder="2024/02/12"
                                    value={startDate}
                                    onChange={(v) => formik.setFieldValue('startDate', Date.parse(v))}
                                />
                                ~
                                <DatePickerComponent
                                    placeholder="2024/03/12"
                                    value={endDate}
                                    onChange={(v) => formik.setFieldValue('endDate', Date.parse(v))}
                                />
                            </Row>
                        </div>
                    </Col>
                </Row>}

                {(isAdminSanwa(userInfo)) ?
                    <div style={{ marginBlock: 20 }}>
                        <Row >
                            <Col offset={{ xs: 3 }} md={2}>
                                <Lable label={t('export_page.partner')}></Lable>
                            </Col>
                            <Col>
                                <div style={{ marginInline: 10 }}>
                                    <Select
                                        placeholder={t('export_page.partner')}
                                        value={partnerId}
                                        options={[{ label: t('no_partner'), value: 'DEFAULT_SANWA' }, ...(partnerList ?? []).map((e) => {
                                            return { label: e.name, value: e.id };
                                        })]}
                                        onChange={(v) => {
                                            console.log("formik.setFieldValue('partnerId', v)", v);
                                            formik.setFieldValue('partnerId', v);
                                        }}
                                        width={445}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div> : <div></div>
                }
                {
                    <div style={{ marginBlock: 20, display: ((isAdminSanwa(userInfo)) && dataType != '0') || (isPartner(userInfo) && (dataType != '0')) ? 'block' : 'none' }}>
                        <Row>
                            <Col offset={{ xs: 3 }} md={2}>
                                <Lable label={t('export_page.facility')}></Lable> {/* Ten co so */}
                            </Col>
                            <Col>
                                <div style={{ marginInline: 10 }}>
                                    <Select
                                        placeholder={t('export_page.facility')}
                                        value={facilityId}
                                        options={[...(facilityList.length > 0) ? [{ label: t('all'), value: 'ALL' }] : [], ...facilityList.map((e) => { return { label: e.name, value: e.id }; })]}
                                        onChange={(v) => {
                                            formik.setFieldValue('facilityId', v);
                                        }}
                                        width={445}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }

                {<div style={{ marginBlock: 20, display: ((dataType == '2' || dataType == '1')) ? 'block' : 'none' }}>
                    <Row>
                        <Col offset={{ xs: 3 }} md={2}>
                            <Lable label={t('export_page.group')}></Lable> {/* Ten group */}
                        </Col>
                        <Col>
                            <div style={{ marginInline: 10 }}>
                                <Select
                                    placeholder={t('empty_data')}
                                    value={(!isAdminFacility(userInfo) && facilityList.length <= 0) ? undefined : groupId}
                                    options={[...[(groupList2.length <= 0) ? {} : { label: t('all'), value: 'ALL' }], ...((!isAdminFacility(userInfo) && facilityList.length <= 0) ? [] : groupList2).map((e) => { return { label: e.name, value: e.id }; })]}
                                    onChange={(v) => { formik.setFieldValue('groupId', v) }}
                                    width={445}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>}

                {<div style={{ marginBlock: 20, display: (dataType == '2') ? 'block' : 'none' }}>
                    <Row>
                        <Col offset={{ xs: 3 }} md={2}>
                            <Lable label={t('export_page.staff')}></Lable> {/* Benh nhan */}
                        </Col>
                        <Col>
                            <div style={{ marginInline: 10 }}>
                                <Select
                                    placeholder={t('empty_data')}
                                    value={patientExprotId}
                                    options={[...[((isAdminSanwa(userInfo) && facilityList.length <= 0) || groupList2.length <= 0 || patientList.length <= 0) ? {} : { label: t('all'), value: 'ALL' }], ...(((groupList2.length <= 0 || (isAdminSanwa(userInfo) && facilityList.length <= 0)) ? [] : patientList ?? [])).map((e) => { return { label: `${e.name} ${e.last_name}`, value: e.id }; })]}
                                    onChange={(v) => {
                                        formik.setFieldValue('patientExprotId', v)
                                        additionalFieldsPatient(v);
                                        setEL('')
                                    }}
                                    width={445}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>}

                <div style={{ alignItems: 'center', paddingLeft: 40 }}>
                    <Row>
                        <Col offset={{ xs: 5 }} md={3} >
                            <Button
                                key="back"
                                text={t('export_page.btn')}
                                className="csv-submit-button"
                                background="#0E6FBA"
                                disabled={invalidRangeDate}
                                loading={inProgressExport}
                                type="submit"
                                width={400}
                            />

                        </Col>
                    </Row>
                    <center>
                        <div style={{ margin: 20 }}>
                            <a
                                className="csv-exported-link"
                                href={externalLink}
                                target="_blank"
                            >
                                {externalLink.split('/')[externalLink.split('/').length - 1]}
                            </a>
                        </div>
                    </center>
                </div>
            </Container>
        </form>
    </div>);
};

const isAdminSanwa = (userInfo) => userInfo.role == "ROLE_FACILITY" && userInfo.is_sys_admin;
const isPartner = (userInfo) => userInfo.role == "ROLE_PARTNER" && !userInfo.is_sys_admin;
const isAdminFacility = (userInfo) => userInfo.role == "ROLE_FACILITY" && !userInfo.is_sys_admin;


export default CsvExportSanwa;
