import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const PartnerHook = () => {
  const { partners, success, error } = useSelector(
    (state) => state.PartnerReducer
  );
  const [partnerList, setPartnerList] = useState([]);

  useEffect(() => {
    setPartnerList(partners);
  }, [partners]);

  return { partnerList, success, error };
};
export default PartnerHook;
