import { get, post, put, del, patch } from "../api_helper";
import * as url from "../url_helper";
import { convertObjectToParams } from "utils";

export const apiGetFacility = (data) => {
  let urlApi = url.API_FACILITY;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiPatchFacility = (id, data) =>
  patch(url.API_FACILITY + id, data);
export const apiPutFacility = (data) => put(url.API_FACILITY, data);
export const apiGetFacilityShareCode = () =>
  get(url.API_ACCOUNT_FACILITTIES_SHARE_CODE);
export const apiGetFacilityJoinRequest = () =>
  get(url.API_ACCOUNT_FACILITTIES_JOIN_REQUEST);

export const apiApprovalFacilityJoinRequest = (data) =>
  post(url.API_ACCOUNT_FACILITTIES_APPRROVAL_REQUEST, data);
export const apiCreateFacility = (data) => put(url.API_FACILITY, data);
export const apiDeleteFacility = (id) => del(url.API_FACILITY + id);

export const apiMaintenancePayment = (id, data) => patch(url.API_PAYMENT_MAINTAINCE.replace(':id', id), data);
