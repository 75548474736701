import {
  EXPORT_FOR_FACILITIES,
  EXPORT_FOR_PATIENTS_FACILITY,
  EXPORT_FOR_SENSOR,
  GET_EXPORTED_LINK_FAILED,
  GET_EXPORTED_LINK_SUCCESSFULLY,
  RESET_DEVICE_MESSAGE
} from "./actionTypes";

export const resetMessages = () => {
  return {
    type: RESET_DEVICE_MESSAGE,
  };
};

export const exportDataByListFacilities = (payload) => {
  return {
    type: EXPORT_FOR_FACILITIES,
    payload,
  };
};

export const exportDataByListPatients = (payload) => {
  return {
    type: EXPORT_FOR_PATIENTS_FACILITY,
    payload,
  };
};

export const exportDataByDataSensor = (payload) => {
  return {
    type: EXPORT_FOR_SENSOR,
    payload,
  };
};


export const getExportedLinkSuccess = (payload) => {
  return {
    type: GET_EXPORTED_LINK_SUCCESSFULLY,
    payload,
  };
};



export const getExportedLinkFailed = (payload) => {
  return {
    type: GET_EXPORTED_LINK_FAILED,
    payload,
  };
};