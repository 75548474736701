import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_PATIENT,
  ANALYTIC_DIAPER_INFO,
  ANALYTIC_DIAPER_INFO_EXTERNAL,
  ANALYTIC_DIAPER_RESIZE,
  ANALYTIC_DIAPER_RESIZE_EXTERNAL,
  ANALYTIC_URINE,
  ANALYTIC_URINE_EXTERNAL,
  DELETE_PATIENT,
  GET_PATIENTS,
  GET_PATIENTS_BY_FACILITY,
  GET_PATIENT_DETAIL,
  UPDATE_PATIENT,
} from "./actionTypes";
import {
  getPatientsSuccess,
  getPatientsFail,
  getPatientByFacilitySuccess,
  getPatientByFacilityFail,
  addPatientSuccess,
  addPatientFail,
  updatePatientSuccess,
  updatePatientFail,
  getPatientDetailSuccess,
  getPatientDetailFail,
  deletePatientSuccess,
  deletePatientFail,
  analyticUrineSuccess,
  analyticUrineFail,
  analyticDiaperResizeSuccess,
  analyticDiaperResizeFail,
  analyticDiaperInfoSuccess,
  analyticDiaperInfoFail,
} from "./actions";

import {
  apiGetPatients,
  apiGetPatientFacility,
  apiGetDetailPatient,
  apiPutPatient,
  apiPatchPatient,
  apiDelPatient,
  apiGetAnalyticUrine,
  apiGetAnalyticDiaperResize,
  apiGetAnalyticDiaperInfo,
  apiGetAnalyticUrineExternal,
  apiGetAnalyticDiaperResizeExternal,
  apiGetAnalyticDiaperInfoExternal,
} from "../../helpers/api/master/patients";

function* onGetPatients(payload) {
  try {
    const response = yield call(apiGetPatients, payload?.payload);
    if (response) yield put(getPatientsSuccess({ data: response.data, usageLisences: response.total_use_licenses }));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetPatients(payload);
    }
    yield put(getPatientsFail(error.response));
  }
}
function* onGetPatientFacilities(payload) {
  try {
    const response = yield call(apiGetPatientFacility, payload?.payload);
    if (response) yield put(getPatientByFacilitySuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetPatientFacilities(payload);
    }
    yield put(getPatientByFacilityFail(error.response));
  }
}
function* onGetDetailPatient(payload) {
  try {
    const response = yield call(apiGetDetailPatient, payload?.payload);
    if (response) yield put(getPatientDetailSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetDetailPatient(payload);
    }
    yield put(getPatientDetailFail(error.response));
  }
}
function* onAddPatient(payload) {
  try {
    const response = yield call(apiPutPatient, payload?.payload);
    if (response)
      yield put(
        addPatientSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
    const responseGet = yield call(apiGetPatients);
    if (responseGet) yield put(getPatientsSuccess(responseGet.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddPatient(payload);
    }
    yield put(addPatientFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdatePatient({ payload }) {
  try {
    const response = yield call(apiPatchPatient, payload?.id, payload?.data);
    const responseGet = yield call(apiGetPatients);
    if (response && responseGet) {
      yield put(getPatientsSuccess(responseGet.data));
      yield put(
        updatePatientSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdatePatient(payload);
    }
    yield put(updatePatientFail(error?.data?.error || { code: 400 }));
  }
}
function* onDeletePatient({ payload }) {
  try {
    const response = yield call(apiDelPatient, payload);
    if (response)
      yield put(
        deletePatientSuccess({
          message: response.data?.message || "",
          type: "DELETE",
        })
      );
  } catch (error) {
    if (error?.response?.status === 401) {
      onDeletePatient(payload);
    }
    yield put(deletePatientFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticUrine({ payload }) {
  try {
    const response = yield call(apiGetAnalyticUrine, payload.id, payload.data);
    if (response) yield put(analyticUrineSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticUrine(payload);
    }
    yield put(analyticUrineFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticUrineExternal({ payload }) {
  try {
    const response = yield call(
      apiGetAnalyticUrineExternal,
      payload.id,
      payload.data
    );
    if (response) yield put(analyticUrineSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticUrineExternal(payload);
    }
    yield put(analyticUrineFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticDiaperResize({ payload }) {
  try {
    const response = yield call(
      apiGetAnalyticDiaperResize,
      payload.id,
      payload.data
    );
    if (response) yield put(analyticDiaperResizeSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticDiaperResize(payload);
    }
    yield put(analyticDiaperResizeFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticDiaperResizeExternal({ payload }) {
  try {
    const response = yield call(
      apiGetAnalyticDiaperResizeExternal,
      payload.id,
      payload.data
    );
    if (response) yield put(analyticDiaperResizeSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticDiaperResizeExternal(payload);
    }
    yield put(analyticDiaperResizeFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticDiaperInfo({ payload }) {
  try {
    const response = yield call(
      apiGetAnalyticDiaperInfo,
      payload.id,
      payload.data
    );
    if (response) yield put(analyticDiaperInfoSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticDiaperInfo(payload);
    }
    yield put(analyticDiaperInfoFail(error?.data?.error || { code: 400 }));
  }
}
function* onAnalyticDiaperInfoExternal({ payload }) {
  try {
    const response = yield call(
      apiGetAnalyticDiaperInfoExternal,
      payload.id,
      payload.data
    );
    if (response) yield put(analyticDiaperInfoSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onAnalyticDiaperInfoExternal(payload);
    }
    yield put(analyticDiaperInfoFail(error?.data?.error || { code: 400 }));
  }
}
function* PatientSaga() {
  yield takeLatest(GET_PATIENTS, onGetPatients);
  yield takeLatest(GET_PATIENTS_BY_FACILITY, onGetPatientFacilities);
  yield takeLatest(ADD_PATIENT, onAddPatient);
  yield takeLatest(UPDATE_PATIENT, onUpdatePatient);
  yield takeLatest(GET_PATIENT_DETAIL, onGetDetailPatient);
  yield takeLatest(DELETE_PATIENT, onDeletePatient);
  yield takeLatest(ANALYTIC_URINE, onAnalyticUrine);
  yield takeLatest(ANALYTIC_DIAPER_RESIZE, onAnalyticDiaperResize);
  yield takeLatest(ANALYTIC_DIAPER_INFO, onAnalyticDiaperInfo);
  yield takeLatest(ANALYTIC_URINE_EXTERNAL, onAnalyticUrineExternal);
  yield takeLatest(
    ANALYTIC_DIAPER_RESIZE_EXTERNAL,
    onAnalyticDiaperResizeExternal
  );
  yield takeLatest(ANALYTIC_DIAPER_INFO_EXTERNAL, onAnalyticDiaperInfoExternal);
}

export default PatientSaga;
