import { get, patch, put, del } from "../../api_helper";
import { convertObjectToParams } from "utils";
import * as url from "../../url_helper";

export const apiGetGroup = (data) => {
  let urlApi = url.API_MASTER_GROUPS;
  if (data) {
    urlApi = urlApi + `?partner_id=${data.partner_id == undefined ? '' : data.partner_id}&facility_id=${data.facility_id == undefined ? '' : data.facility_id}`;
  }
  return get(urlApi);
};
export const apiGetGroupByFacility = (data) => {
  let urlApi = url.API_MASTER_GROUPS_BY_FACILITY;
  urlApi = urlApi.replace(':partnerId', data.partnerId).replace(':facilityId', data.facilityId);
  return get(urlApi);
};
export const apiPutGroup = (data) => put(url.API_MASTER_GROUPS, data);
export const apiPatchGroup = (id, data) =>
  patch(`${url.API_MASTER_GROUPS}${id}`, data);
export const apiDelGroup = (id) => del(`${url.API_MASTER_GROUPS}${id}`);
