import {
  RESET_PATIENT_MESSAGE,
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  ADD_PATIENT,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_FAIL,
  UPDATE_PATIENT,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  GET_PATIENT_DETAIL,
  GET_PATIENT_DETAIL_SUCCESS,
  GET_PATIENT_DETAIL_FAIL,
  DELETE_PATIENT,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAIL,
  ANALYTIC_URINE,
  ANALYTIC_URINE_SUCCESS,
  ANALYTIC_URINE_FAIL,
  ANALYTIC_DIAPER_RESIZE,
  ANALYTIC_DIAPER_RESIZE_SUCCESS,
  ANALYTIC_DIAPER_RESIZE_FAIL,
  ANALYTIC_DIAPER_INFO,
  ANALYTIC_DIAPER_INFO_SUCCESS,
  ANALYTIC_DIAPER_INFO_FAIL,
  ANALYTIC_DIAPER_RESIZE_EXTERNAL,
  ANALYTIC_DIAPER_INFO_EXTERNAL,
  ANALYTIC_URINE_EXTERNAL,
  GET_PATIENTS_BY_FACILITY,
  GET_PATIENTS_BY_FACILITY_SUCCESS,
  GET_PATIENTS_BY_FACILITY_FAIL,
  RESET_LIST_PATIENT
} from "./actionTypes";

export const resetPatientMessage = () => {
  return {
    type: RESET_PATIENT_MESSAGE,
  };
};

export const resetPatientList = () => {
  return {
    type: RESET_LIST_PATIENT,
  };
};

export const getPatients = (payload) => {
  return {
    type: GET_PATIENTS,
    payload,
  };
};

export const getPatientsSuccess = (patients) => {
  return {
    type: GET_PATIENTS_SUCCESS,
    payload: patients,
  };
};

export const getPatientsFail = (error) => {
  return {
    type: GET_PATIENTS_FAIL,
    payload: error,
  };
};
export const getPatientByFacilities = (payload) => {
  return {
    type: GET_PATIENTS_BY_FACILITY,
    payload,
  };
};

export const getPatientByFacilitySuccess = (patients) => {
  return {
    type: GET_PATIENTS_BY_FACILITY_SUCCESS,
    payload: patients,
  };
};

export const getPatientByFacilityFail = (error) => {
  return {
    type: GET_PATIENTS_BY_FACILITY_FAIL,
    payload: error,
  };
};
export const addPatient = (payload) => {
  return {
    type: ADD_PATIENT,
    payload,
  };
};

export const addPatientSuccess = (message) => {
  return {
    type: ADD_PATIENT_SUCCESS,
    payload: message,
  };
};

export const addPatientFail = (error) => {
  return {
    type: ADD_PATIENT_FAIL,
    payload: error,
  };
};
export const updatePatient = (payload) => {
  return {
    type: UPDATE_PATIENT,
    payload,
  };
};

export const updatePatientSuccess = (payload) => {
  return {
    type: UPDATE_PATIENT_SUCCESS,
    payload: payload,
  };
};

export const updatePatientFail = (error) => {
  return {
    type: UPDATE_PATIENT_FAIL,
    payload: error,
  };
};

export const deletePatient = (payload) => {
  return {
    type: DELETE_PATIENT,
    payload,
  };
};

export const deletePatientSuccess = (message) => {
  return {
    type: DELETE_PATIENT_SUCCESS,
    payload: message,
  };
};

export const deletePatientFail = (error) => {
  return {
    type: DELETE_PATIENT_FAIL,
    payload: error,
  };
};
export const getPatientDetail = (payload) => {
  return {
    type: GET_PATIENT_DETAIL,
    payload,
  };
};

export const getPatientDetailSuccess = (payload) => {
  return {
    type: GET_PATIENT_DETAIL_SUCCESS,
    payload: payload,
  };
};

export const getPatientDetailFail = (error) => {
  return {
    type: GET_PATIENT_DETAIL_FAIL,
    payload: error,
  };
};
export const analyticUrine = (payload) => {
  return {
    type: ANALYTIC_URINE,
    payload,
  };
};
export const analyticUrineExternal = (payload) => {
  return {
    type: ANALYTIC_URINE_EXTERNAL,
    payload,
  };
};

export const analyticUrineSuccess = (payload) => {
  return {
    type: ANALYTIC_URINE_SUCCESS,
    payload: payload,
  };
};

export const analyticUrineFail = (error) => {
  return {
    type: ANALYTIC_URINE_FAIL,
    payload: error,
  };
};
export const analyticDiaperResize = (payload) => {
  return {
    type: ANALYTIC_DIAPER_RESIZE,
    payload,
  };
};
export const analyticDiaperResizeExternal = (payload) => {
  return {
    type: ANALYTIC_DIAPER_RESIZE_EXTERNAL,
    payload,
  };
};
export const analyticDiaperResizeSuccess = (payload) => {
  return {
    type: ANALYTIC_DIAPER_RESIZE_SUCCESS,
    payload: payload,
  };
};

export const analyticDiaperResizeFail = (error) => {
  return {
    type: ANALYTIC_DIAPER_RESIZE_FAIL,
    payload: error,
  };
};
export const analyticDiaperInfo = (payload) => {
  return {
    type: ANALYTIC_DIAPER_INFO,
    payload,
  };
};
export const analyticDiaperInfoExternal = (payload) => {
  return {
    type: ANALYTIC_DIAPER_INFO_EXTERNAL,
    payload,
  };
};
export const analyticDiaperInfoSuccess = (payload) => {
  return {
    type: ANALYTIC_DIAPER_INFO_SUCCESS,
    payload: payload,
  };
};

export const analyticDiaperInfoFail = (error) => {
  return {
    type: ANALYTIC_DIAPER_INFO_FAIL,
    payload: error,
  };
};
