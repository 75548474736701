import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import transJP from "./trans/JP.json";
import transKR from "./trans/KR.json";

// the translations
const resources = {
  JP: {
    translation: transJP,
  },
  KR: {
    translation: transKR,
  },
};
let lang = localStorage.getItem("LANG");
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: lang,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
