import { get, put, patch, del } from "../../api_helper";
import * as url from "../../url_helper";
import { convertObjectToParams } from "utils";
export const apiGetStaff = (data) => {
  let urlApi = url.API_MASTER_STAFFS;
  if (data) {
    const params = convertObjectToParams(data);
    urlApi = urlApi + "?" + params;
  }
  return get(urlApi);
};
export const apiPutStaff = (data) => put(url.API_MASTER_STAFFS, data);
export const apiPatchStaff = (id, data) =>
  patch(`${url.API_MASTER_STAFFS}${id}`, data);
export const apiDelStaff = (id) => del(`${url.API_MASTER_STAFFS}${id}`);
