import moment from "moment";
import { get, post, put, del, patch } from "../api_helper";
import * as url from "../url_helper";
import { convertObjectToParams } from "utils";

export const apiExportForListFacilities = (data) => {
    let urlApi = url.EXPORT_FACILITIES_BY_PARTNER;
    if (data && data.partnerId !== '') {
        urlApi = urlApi + `?id=${data.partnerId}`;
    }
    return get(urlApi);
};

export const apiExportForListPatients = (data) => {
    let urlApi = url.EXPORT_PATIENTS_BY_FACILITY;
    urlApi = urlApi + `?partner_id=${data.partnerId}&group_id=${data.groupId}&facility_id=${data.facilityId}`;
    return get(urlApi);
};
export const apiExportForDefinedSensor = (data) => {
    let urlApi = url.EXPORT_PATIENTS_BY_SENSOR;
    if (data) {
        const params = convertObjectToParams(data);
        var start = moment.utc(moment(data.startDate).startOf('day')).format('yyyy-MM-DD HH:mm');
        var end = moment.utc(moment(data.endDate).endOf('day')).format('yyyy-MM-DD HH:mm');
        console.log(start);
        console.log(end);
        urlApi = urlApi + `?patient_id=${data.patientExprotId}&group_id=${data.groupId}&facility_id=${data.facilityId}&partner_id=${data.partnerId}&from=${start}&to=${end}&patient_name=${data.patientName}&patient_code=${data.patientCode}&facility_name=${data.facilityName}`;
    }
    return get(urlApi);
};
