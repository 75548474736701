export const convertOption = (array) => {
  return array.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};
export const convertOptionArea = (array, lang) => {
  let langSelected = lang;
  if (!lang) {
    langSelected = 'JP'
  }
  const arrFilter = array.filter(item => item.code === langSelected)
  return arrFilter.map((item, index) => {
    return {
      value: item.id,
      label: langSelected === 'KR' ? item.name_kr : item.name,
    };
  });
};

export const convertOptionAreaEdit = (array, areaId, lang) => {
  const arrFilter = array.filter(item => item.id === areaId)
  const codeNa = arrFilter[0]?.code ?? 'JP'
  const areaFilter = array.filter(item => item.code === codeNa)
  return areaFilter.map((item, index) => {
    return {
      value: item.id,
      label: lang && lang === 'KR' ? item.name_kr : item.name,
    };
  });
};

export const convertOptionMaster = (array) => {
  return array.map((item, index) => {
    return {
      value: item.code,
      label: item.value,
    };
  });
};
export const convertOptionDiaper = (array) => {
  return array.map((item, index) => {
    return {
      value: item.id,
      label: item.size,
    };
  });
};
export const convertObjectToParams = (obj) => {
  return new URLSearchParams(obj).toString();
};

export const convertPartnerListSelected = (array) => {
  return array.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};


export const isAdminSanwa = (userInfo) => userInfo.role == "ROLE_FACILITY" && userInfo.is_sys_admin;
export const isPartner = (userInfo) => userInfo.role == "ROLE_PARTNER" && !userInfo.is_sys_admin;
export const isAdminFacility = (userInfo) => userInfo.role == "ROLE_FACILITY" && !userInfo.is_sys_admin;