import { useTranslation } from "react-i18next";
export const USER_LIST = "/";
export const PERSON_IN_CHARGE = "/pic";
export const GROUP_SETTING = "/group";
export const MASTER_SETTING = "/master-setting";
export const FACILITY_INFORMATION_SETTING = "/facility";
export const PARTNER_INFORMATION_SETTING = "/partner";
export const DIAPER_TYPE_SETTING = "/diaper-type";
export const DEVICE_SETTING = "/device";
export const DEG_SETTING = "/deg";
export const BAT_VOL_SETTING = "/batvol";
export const ACCELERATION_SETTING = "/acceleration";
export const USER_SETTING = "/user";
export const PASSWORD_SETTING = "/password";
export const RESET_PASSWORD = "/reset-password";
export const PAYMENT = "/payment";
export const LOGOUT = "/logout";
export const CSVEXPORT = "/admin/csv/export"


const MenuListAdmin = () => {
  const { t } = useTranslation();
  return [
    {
      key: "USER_LIST",
      title: t("menusAdmin.user_list"),
      url: USER_LIST,
      childs: [],
    },
    // {
    //   key: "PERSON_IN_CHARGE",
    //   title: t("menusAdmin.pic"),
    //   url: PERSON_IN_CHARGE,
    //   childs: [],
    // },
    // {
    //   key: "GROUP_SETTING",
    //   title: t("menusAdmin.group"),
    //   url: GROUP_SETTING,
    //   childs: [],
    // },
    {
      key: "MASTER_SETTING",
      title: t("menusAdmin.master"),
      url: MASTER_SETTING,
      childs: [
        {
          key: "FACILITY_INFORMATION_SETTING",
          title: t("menusAdmin.facility_info"),
          url: FACILITY_INFORMATION_SETTING,
          childs: [],
        },
        {
          key: "PARTNER_INFORMATION_SETTING",
          title: t("menusAdmin.partner"),
          url: PARTNER_INFORMATION_SETTING,
          childs: [],
        },
        {
          key: "DIAPER_TYPE_SETTING",
          title: t("menusAdmin.diaper"),
          url: DIAPER_TYPE_SETTING,
          childs: [],
        },
        {
          key: "DEVICE_SETTING",
          title: t("menusAdmin.device"),
          url: DEVICE_SETTING,
          childs: [],
        },
        {
          key: "DEG_SETTING",
          title: t("menusAdmin.deg"),
          url: DEG_SETTING,
          childs: [],
        },
        {
          key: "ACCELERATION_SETTING",
          title: t("menusAdmin.acceleration"),
          url: ACCELERATION_SETTING,
          childs: [],
        },
        {
          key: "BAT_VOL_SETTING",
          title: t("menusAdmin.batvol"),
          url: BAT_VOL_SETTING,
          childs: [],
        },
      ],
    },
    {
      key: "PASSWORD_SETTING",
      title: t("menusAdmin.password"),
      url: PASSWORD_SETTING,
      childs: [],
    },
    {
      key: "PAYMENT",
      title: t("menusAdmin.payment"),
      url: PAYMENT,
      childs: [],
    },
    {
      key: "CSVEXPORT",
      title: t("menus.csv_export"),
      url: CSVEXPORT,
      childs: []
    },
    {
      key: "LOGOUT",
      title: t("menusAdmin.logout"),
      url: LOGOUT,
      childs: [],
    },
  ];
};
export default MenuListAdmin;
