import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  RESET_USER_MESSAGE,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_BILLS_SUCCESS,
  GET_BILLS_FAIL,
  UPDATE_BILL_SUCCESS,
  UPDATE_BILL_FAIL,
  UPDATE_BILL,
} from "./actionTypes";

const initialState = {
  loginInfo: {},
  accountInfo: {},
  bills: {},
  loading: false,
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      state = { ...state, loading: true };
      break;
    case USER_LOGIN_SUCCESS:
      state = {
        ...state,
        loginInfo: action.payload,
        loading: false,
        error: { message: "" },
      };
      break;
    case USER_LOGIN_FAIL:
      state = {
        ...state,
        error: action.payload,
        loginInfo: {},
        loading: false,
      };
      break;
    case GET_ACCOUNT_INFO:
      state = { ...state, loading: true };
      break;
    case GET_ACCOUNT_INFO_SUCCESS:
      state = {
        ...state,
        accountInfo: action.payload,
        loading: false,
        error: { message: "" },
      };
      break;
    case GET_ACCOUNT_INFO_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        accountInfo: {},
        loading: false,
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    case RESET_USER_MESSAGE:
      state = {
        ...state,
        loading: false,
        error: { message: "" },
        success: { message: "" },
      };
      break;
    case SEND_OTP_SUCCESS:
      state = {
        ...state,
        success: {
          message: action.payload,
        },
        error: { message: "" },
        loading: false,
      };
      break;
    case SEND_OTP_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        success: { message: "" },
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        error: { message: "" },
        loading: false,
      };
      break;
    case RESET_PASSWORD_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        success: { message: "" },
      };
      break;
    case GET_BILLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        bills: action.payload,
      };
      break;
    case GET_BILLS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case UPDATE_BILL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_BILL_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case UPDATE_BILL_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserReducer;
