import { all, fork } from "redux-saga/effects";

import PatientSaga from "./patients/saga";
import DeviceSaga from "./devices/saga";
import MasterSaga from "./master/saga";
import UserSaga from "./user/saga";
import FacilitySaga from "./facilities/saga";
import PartnerSaga from "./partners/saga";
import CSVSaga from "./csv/saga";

export default function* rootSaga() {
  yield all([fork(PatientSaga)]);
  yield all([fork(DeviceSaga)]);
  yield all([fork(MasterSaga)]);
  yield all([fork(UserSaga)]);
  yield all([fork(FacilitySaga)]);
  yield all([fork(PartnerSaga)]);
  yield all([fork(CSVSaga)]);
}
