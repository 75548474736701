import { useTranslation } from "react-i18next";
export const USER_LIST = "/";
export const MASTER_SETTING = "/master-setting";
export const FACILITY_INFORMATION_SETTING = "/facility";
export const PARTNER_INFORMATION_SETTING = "/partner";
export const PASSWORD_SETTING = "/password";
export const RESET_PASSWORD = "/reset-password";
export const PAYMENT = "/payment";
export const LOGOUT = "/logout";
export const CSVEXPORT = "/partner/csv/export"

const MenuListPartner = () => {
    const { t } = useTranslation();
    return [
        {
            key: "USER_LIST",
            title: t("menus.user_list"),
            url: USER_LIST,
            childs: [],
        },
        {
            key: "MASTER_SETTING",
            title: t("menus.master"),
            url: MASTER_SETTING,
            childs: [
                {
                    key: "PARTNER_INFORMATION_SETTING",
                    title: t("page.partner_profile"),
                    url: PARTNER_INFORMATION_SETTING,
                    childs: [],
                },
                {
                    key: "FACILITY_INFORMATION_SETTING",
                    title: t("page.partner_list_facility"),
                    url: FACILITY_INFORMATION_SETTING,
                    childs: [],
                }
            ],
        },
        {
            key: "PASSWORD_SETTING",
            title: t("menus.password"),
            url: PASSWORD_SETTING,
            childs: [],
        },
        {
            key: "CSVEXPORT",
            title: t("menus.csv_export"),
            url: CSVEXPORT,
            childs: []
        },
        {
            key: "LOGOUT",
            title: t("menus.logout"),
            url: LOGOUT,
            childs: [],
        },
    ];
};
export default MenuListPartner;
