import { takeLatest, put, call } from "redux-saga/effects";

import {
  EXPORT_FOR_PATIENTS_FACILITY,
  EXPORT_FOR_FACILITIES,
  EXPORT_FOR_SENSOR
} from "./actionTypes";
import {
  exportDataByListFacilities,
  exportDataByListPatients,
  exportDataByDataSensor,
  getExportedLinkSuccess,
  getExportedLinkFailed
} from "./actions";
import { apiExportForDefinedSensor, apiExportForListFacilities, apiExportForListPatients } from "helpers/api/export_data";

function* onExportForFacilities(payload) {
  try {
    const response = yield call(apiExportForListFacilities, payload?.payload);
    if (response) yield put(getExportedLinkSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      // onExportForFacilities(payload);
    }
    yield put(getExportedLinkFailed(error.response));
  }
}
function* onExportForPatientsOfFacility(payload) {
  try {
    const response = yield call(apiExportForListPatients, payload?.payload);
    if (response) yield put(getExportedLinkSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      // onExportForPatientsOfFacility(payload);
    }
    yield put(getExportedLinkFailed(error.response));
  }
}
function* onExportForSensorOfPatient(payload) {
  try {
    const response = yield call(apiExportForDefinedSensor, payload?.payload);
    if (response) yield put(getExportedLinkSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      // onExportForSensorOfPatient(payload);
    }
    yield put(getExportedLinkFailed(error.response));

  }
}
function* CSVSaga() {
  yield takeLatest(EXPORT_FOR_FACILITIES, onExportForFacilities);
  yield takeLatest(EXPORT_FOR_SENSOR, onExportForSensorOfPatient);
  yield takeLatest(EXPORT_FOR_PATIENTS_FACILITY, onExportForPatientsOfFacility);
}

export default CSVSaga;
