export const USER_LIST = "/";
export const PERSON_IN_CHARGE = "/pic";
export const GROUP_SETTING = "/group";
export const MASTER_SETTING = "/master-setting";
export const FACILITY_INFORMATION_SETTING = "/facility";
export const DIAPER_TYPE_SETTING = "/diaper-type";
export const DEVICE_SETTING = "/device";
export const DEG_SETTING = "/deg";
export const USER_SETTING = "/user";
export const PASSWORD_SETTING = "/password";
export const RESET_PASSWORD = "/reset-password";
export const PAYMENT = "/payment";
export const LOGOUT = "/logout";
export const CSVEXPORT = "/admin/csv/export"

