import { toast } from "react-toastify";

const useNotify = () => {
  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };
  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return {
    showToastSuccess,
    showToastError,
  };
};

export default useNotify;
