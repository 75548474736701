import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_DEVICES,
  GET_DEVICES,
  GET_DEVICES_BY_PATIENT,
  GET_DEVICES_LIST,
  UNPAIR_DEVICES,
  UPDATE_DEVICES,
  GET_DEVICES_SETTING_BATVOL,
  UPDATE_DEVICES_SETTING_BATVOL,
  UPDATE_DEVICES_SETTING_ACCELERATION
} from "./actionTypes";
import {
  getDevicesSuccess,
  getDevicesFail,
  addDevicesSuccess,
  addDevicesFail,
  unpairDevicesSuccess,
  unpairDevicesFail,
  getDeviceSettingBatvol,
  getDeviceSettingBatvolSuccess,
  getDeviceSettingBatvolFail,
  updateDeviceSettingBatvolSucess,
  updateDeviceSettingBatvolFail,
  updateDeviceSettingAccelerationSucess,
  updateDeviceSettingAccelerationFail,
  getDevicesListSuccess,
  getDevicesListFail,
} from "./actions";

import {
  apiGetDevices,
  apiPutDevices,
  apiPatchDevices,
  apiUnPairDevices,
  apiGetDevicesByPatient,
  apiGetSettingBatVol,
  apiUpdateSettingBatVol,
  apiUpdateSettingAcceleration
} from "../../helpers/api/devices";

function* onGetDevicesList(payload) {
  try {
    const response = yield call(apiGetDevices, payload?.payload);
    if (response) yield put(getDevicesListSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetDevicesList(payload);
    }
    yield put(getDevicesListFail(error.response));
  }
}
function* onGetDevices(payload) {
  try {
    const response = yield call(apiGetDevices, payload?.payload);
    if (response) yield put(getDevicesSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetDevices(payload);
    }
    yield put(getDevicesFail(error.response));
  }
}
function* onGetDevicesByPatient(payload) {
  try {
    const response = yield call(apiGetDevicesByPatient, payload?.payload);
    if (response) yield put(getDevicesSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetDevicesByPatient(payload);
    }
    yield put(getDevicesFail(error.response));
  }
}
function* onAddDevices(payload) {
  try {
    const response = yield call(apiPutDevices, payload?.payload);
    console.log("responsexxx", response);
    const responseGet = yield call(apiGetDevices);
    if (response && responseGet) {
      yield put(
        addDevicesSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );

      yield put(getDevicesSuccess(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddDevices(payload);
    }
    yield put(addDevicesFail(error?.data?.error || { type: "ADD", code: 400 }));
  }
}
function* onUpdateDevices(payload) {
  try {
    const response = yield call(
      apiPatchDevices,
      payload?.payload.id,
      payload?.payload?.data
    );

    const responseGet = yield call(apiGetDevices);
    if (response && responseGet) {
      yield put(
        addDevicesSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getDevicesSuccess(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddDevices(payload);
    }
    yield put(
      addDevicesFail(error?.data?.error || { type: "UPDATE", code: 400 })
    );
  }
}
function* onUnpairDevices(payload) {
  try {
    const response = yield call(
      apiUnPairDevices,
      payload?.payload.id,
      payload?.payload?.data
    );
    const responseGet = yield call(apiGetDevices);
    if (response && responseGet) {
      yield put(
        unpairDevicesSuccess({
          message: response.data?.message || "",
          type: "UNPAIR",
        })
      );
      yield put(getDevicesSuccess(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddDevices(payload);
    }
    yield put(
      unpairDevicesFail(error?.data?.error || { type: "UNPAIR", code: 400 })
    );
  }
}
function* onGetDeviceSettingBatvol() {
  try {
    const response = yield call(apiGetSettingBatVol);
    if (response) yield put(getDeviceSettingBatvolSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetDeviceSettingBatvol();
    }
    yield put(getDeviceSettingBatvolFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdateDeviceSettingBatvol(payload) {
  try {
    const response = yield call(apiUpdateSettingBatVol, payload?.payload);
    const responseGet = yield call(apiGetSettingBatVol);
    if (response && responseGet) {
      yield put(
        updateDeviceSettingBatvolSucess({
          message: response.data || "",
          type: "UPDATE",
        })
      );
      yield put(getDeviceSettingBatvol(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateDeviceSettingBatvol(payload);
    }
    yield put(updateDeviceSettingBatvolFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdateDeviceSettingAcceleration(payload) {
  try {
    const response = yield call(apiUpdateSettingAcceleration, payload?.payload);
    const responseGet = yield call(apiGetSettingBatVol);
    if (response && responseGet) {
      yield put(
        updateDeviceSettingAccelerationSucess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getDeviceSettingBatvol(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateDeviceSettingAcceleration(payload);
    }
    yield put(updateDeviceSettingAccelerationFail(error?.data?.error || { code: 400 }));
  }
}
function* DeviceSaga() {
  yield takeLatest(GET_DEVICES_LIST, onGetDevicesList);
  yield takeLatest(GET_DEVICES, onGetDevices);
  yield takeLatest(GET_DEVICES_BY_PATIENT, onGetDevicesByPatient);
  yield takeLatest(ADD_DEVICES, onAddDevices);
  yield takeLatest(UPDATE_DEVICES, onUpdateDevices);
  yield takeLatest(UNPAIR_DEVICES, onUnpairDevices);
  yield takeLatest(GET_DEVICES_SETTING_BATVOL, onGetDeviceSettingBatvol);
  yield takeLatest(UPDATE_DEVICES_SETTING_BATVOL, onUpdateDeviceSettingBatvol);
  yield takeLatest(UPDATE_DEVICES_SETTING_ACCELERATION, onUpdateDeviceSettingAcceleration);
}

export default DeviceSaga;
