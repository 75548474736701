import {
  RESET_USER_MESSAGE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  GET_BILLS,
  GET_BILLS_SUCCESS,
  GET_BILLS_FAIL,
  UPDATE_BILL,
  UPDATE_BILL_SUCCESS,
  UPDATE_BILL_FAIL,
} from "./actionTypes";

export const resetMessage = () => {
  return {
    type: RESET_USER_MESSAGE,
  };
};
export const login = (payload) => {
  return {
    type: USER_LOGIN,
    payload: payload,
  };
};
export const loginSuccess = (payload) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: payload,
  };
};
export const loginFail = (payload) => {
  return {
    type: USER_LOGIN_FAIL,
    payload: payload,
  };
};

export const getAccountInfo = (payload) => {
  return {
    type: GET_ACCOUNT_INFO,
    payload: payload,
  };
};
export const getAccountInfoSuccess = (payload) => {
  return {
    type: GET_ACCOUNT_INFO_SUCCESS,
    payload: payload,
  };
};
export const getAccountInfoFail = (payload) => {
  return {
    type: GET_ACCOUNT_INFO_FAIL,
    payload: payload,
  };
};

export const changePassword = (payload) => {
  return {
    type: CHANGE_PASSWORD,
    payload: payload,
  };
};
export const changePasswordSuccess = (payload) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: payload,
  };
};
export const changePasswordFail = (payload) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: payload,
  };
};
export const resetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload: payload,
  };
};
export const resetPasswordSuccess = (payload) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: payload,
  };
};
export const resetPasswordFail = (payload) => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: payload,
  };
};
export const sendOtp = (payload) => {
  return {
    type: SEND_OTP,
    payload: payload,
  };
};
export const sendOtpSuccess = (payload) => {
  return {
    type: SEND_OTP_SUCCESS,
    payload: payload,
  };
};
export const sendOtpFail = (payload) => {
  return {
    type: SEND_OTP_FAIL,
    payload: payload,
  };
};

export const getBills = (payload) => {
  return {
    type: GET_BILLS,
    payload: payload,
  };
};
export const getBillsSuccess = (payload) => {
  return {
    type: GET_BILLS_SUCCESS,
    payload: payload,
  };
};
export const getBillsFail = (payload) => {
  return {
    type: GET_BILLS_FAIL,
    payload: payload,
  };
};
export const updateBill = (payload) => {
  return {
    type: UPDATE_BILL,
    payload: payload,
  };
};
export const updateBillSuccess = (payload) => {
  return {
    type: UPDATE_BILL_SUCCESS,
    payload: payload,
  };
};
export const updateBillFail = (payload) => {
  return {
    type: UPDATE_BILL_FAIL,
    payload: payload,
  };
};
