export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const RESET_USER_MESSAGE = "RESET_USER_MESSAGE";

export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_FAIL = "GET_ACCOUNT_INFO_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const GET_BILLS = "GET_BILLS";
export const GET_BILLS_SUCCESS = "GET_BILLS_SUCCESS";
export const GET_BILLS_FAIL = "GET_BILLS_FAIL";

export const UPDATE_BILL = "UPDATE_BILL";
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS";
export const UPDATE_BILL_FAIL = "UPDATE_BILL_FAIL";
