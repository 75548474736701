export const RESET_MASTER_MESSAGE = "RESET_MASTER_MESSAGE";

export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS2 = "GET_GROUPS2";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_SUCCESS2 = "GET_GROUPS_SUCCESS2";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";

export const GET_GROUPS_BY_FACILITY = "GET_GROUPS_BY_FACILITY";
export const GET_GROUPS_BY_FACILITY_SUCCESS = "GET_GROUPS_BY_FACILITY_SUCCESS";
export const GET_GROUPS_BY_FACILITY_FAIL = "GET_GROUPS_BY_FACILITY_FAIL";

export const ADD_MASTER_GROUP = "ADD_MASTER_GROUP";
export const ADD_MASTER_GROUP_SUCCESS = "ADD_MASTER_GROUP_SUCCESS";
export const ADD_MASTER_GROUP_FAIL = "ADD_MASTER_GROUP_FAIL";

export const UPDATE_MASTER_GROUP = "UPDATE_MASTER_GROUP";
export const UPDATE_MASTER_GROUP_SUCCESS = "UPDATE_MASTER_GROUP_SUCCESS";
export const UPDATE_MASTER_GROUP_FAIL = "UPDATE_MASTER_GROUP_FAIL";

export const GET_MASTER = "GET_MASTER";
export const GET_MASTER_SUCCESS = "GET_MASTER_SUCCESS";
export const GET_MASTER_FAIL = "GET_MASTER_FAIL";

export const GET_MASTER_AREA = "GET_MASTER_AREA";
export const GET_MASTER_AREA_SUCCESS = "GET_MASTER_AREA_SUCCESS";
export const GET_MASTER_AREA_FAIL = "GET_MASTER_AREA_FAIL";

export const GET_MASTER_DIAPER = "GET_MASTER_DIAPER";
export const GET_MASTER_DIAPER_SUCCESS = "GET_MASTER_DIAPER_SUCCESS";
export const GET_MASTER_DIAPER_FAIL = "GET_MASTER_DIAPER_FAIL";

export const GET_MASTER_STAFF = "GET_MASTER_STAFF";
export const GET_MASTER_STAFF_SUCCESS = "GET_MASTER_STAFF_SUCCESS";
export const GET_MASTER_STAFF_FAIL = "GET_MASTER_STAFF_FAIL";

export const ADD_MASTER_STAFF = "ADD_MASTER_STAFF";
export const ADD_MASTER_STAFF_SUCCESS = "ADD_MASTER_STAFF_SUCCESS";
export const ADD_MASTER_STAFF_FAIL = "ADD_MASTER_STAFF_FAIL";

export const UPDATE_MASTER_STAFF = "UPDATE_MASTER_STAFF";
export const UPDATE_MASTER_STAFF_SUCCESS = "UPDATE_MASTER_STAFF_SUCCESS";
export const UPDATE_MASTER_STAFF_FAIL = "UPDATE_MASTER_STAFF_FAIL";

export const DELETE_MASTER_STAFF = "DELETE_MASTER_STAFF";
export const DELETE_MASTER_STAFF_SUCCESS = "DELETE_MASTER_STAFF_SUCCESS";
export const DELETE_MASTER_STAFF_FAIL = "DELETE_MASTER_STAFF_FAIL";

export const ADD_MASTER_DIAPER = "ADD_MASTER_DIAPER";
export const ADD_MASTER_DIAPER_SUCCESS = "ADD_MASTER_DIAPER_SUCCESS";
export const ADD_MASTER_DIAPER_FAIL = "ADD_MASTER_DIAPER_FAIL";

export const UPDATE_MASTER_DIAPER = "UPDATE_MASTER_DIAPER";
export const UPDATE_MASTER_DIAPER_SUCCESS = "UPDATE_MASTER_DIAPER_SUCCESS";
export const UPDATE_MASTER_DIAPER_FAIL = "UPDATE_MASTER_DIAPER_FAIL";

export const DELETE_MASTER_DIAPER = "DELETE_MASTER_DIAPER";
export const DELETE_MASTER_DIAPER_SUCCESS = "DELETE_MASTER_DIAPER_SUCCESS";
export const DELETE_MASTER_DIAPER_FAIL = "DELETE_MASTER_DIAPER_FAIL";

export const GET_MASTER_DEG = "GET_MASTER_DEG";
export const GET_MASTER_DEG_SUCCESS = "GET_MASTER_DEG_SUCCESS";
export const GET_MASTER_DEG_FAIL = "GET_MASTER_DEG_FAIL";

export const UPDATE_MASTER_DEG = "UPDATE_MASTER_DEG";
export const UPDATE_MASTER_DEG_SUCCESS = "UPDATE_MASTER_DEG_SUCCESS";
export const UPDATE_MASTER_DEG_FAIL = "UPDATE_MASTER_DEG_FAIL";
