import {
  getFacilities,
  updateFacility,
  resetFacilityMessage,
  getFacilityByPartner,
  updatePaymentStatus,
} from "store/facilities/actions";
import { useDispatch } from "react-redux";

const FacilityApiHook = () => {
  let dispatch = useDispatch();
  const pageInit = () => {
    dispatch(getFacilities());
  };
  const getListFacility = (data) => {
    dispatch(getFacilities(data));
  };
  const getListFacilityByPartner = (data) => {
    dispatch(getFacilityByPartner(data));
  };
  const apiUpdateFacility = (data) => {
    dispatch(updateFacility(data));
  };
  const resetMessage = () => {
    dispatch(resetFacilityMessage());
  };

  const updatePaymentMaintenance = (data) => {
    dispatch(updatePaymentStatus(data));
  };

  return {
    pageInit,
    getListFacility,
    getListFacilityByPartner,
    apiUpdateFacility,
    resetMessage,
    updatePaymentMaintenance,
  };
};
export default FacilityApiHook;
