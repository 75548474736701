import {
  GET_DEVICES,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  ADD_DEVICES_SUCCESS,
  ADD_DEVICES_FAIL,
  UPDATE_DEVICES_SUCCESS,
  UPDATE_DEVICES_FAIL,
  RESET_DEVICE_MESSAGE,
  UNPAIR_DEVICES_SUCCESS,
  UNPAIR_DEVICES_FAIL,
  GET_DEVICES_SETTING_BATVOL_SUCCESS,
  GET_DEVICES_SETTING_BATVOL_FAIL,
  UPDATE_DEVICES_SETTING_BATVOL_SUCCESS,
  UPDATE_DEVICES_SETTING_BATVOL_FAIL,
  UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS,
  UPDATE_DEVICES_SETTING_ACCELERATION_FAIL,
  GET_DEVICES_LIST,
  GET_DEVICES_LIST_SUCCESS,
  GET_DEVICES_LIST_FAIL,
} from "./actionTypes";

const initialState = {
  devices: [],
  devicesList: [],
  loadingDevices: false,
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const DevicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DEVICE_MESSAGE:
      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case GET_DEVICES_LIST:
      state = { ...state, loadingDevices: true };
      break;
    case GET_DEVICES_LIST_SUCCESS:
      state = { ...state, devicesList: action.payload, loadingDevices: false };
      break;
    case GET_DEVICES_LIST_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingDevices: false,
      };
      break;
    case GET_DEVICES:
      state = { ...state, loadingDevices: true };
      break;
    case GET_DEVICES_SUCCESS:
      state = { ...state, devices: action.payload, loadingDevices: false };
      break;
    case GET_DEVICES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingDevices: false,
      };
      break;
    case ADD_DEVICES_SUCCESS:
      state = { ...state, success: action.payload, loadingDevices: false };
      break;
    case ADD_DEVICES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingDevices: false,
      };
      break;
    case UPDATE_DEVICES_SUCCESS:
      state = { ...state, success: action.payload, loadingDevices: false };
      break;
    case UPDATE_DEVICES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingDevices: false,
      };
      break;
    case UNPAIR_DEVICES_SUCCESS:
      state = { ...state, success: action.payload, loadingDevices: false };
      break;
    case UNPAIR_DEVICES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingDevices: false,
      };
      break;
    case GET_DEVICES_SETTING_BATVOL_SUCCESS:
      state = { ...state, batvol: action.payload || [] };
      break;
    case GET_DEVICES_SETTING_BATVOL_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_DEVICES_SETTING_BATVOL_SUCCESS:
      state = { ...state, success: action.payload || [] , error : {}};
      break;
    case UPDATE_DEVICES_SETTING_BATVOL_FAIL:
      state = {
        ...state,
        error: action.payload,
        success: {}
      };
      break;
    case UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_DEVICES_SETTING_ACCELERATION_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DevicesReducer;
