import {
  ADD_PATIENT_FAIL,
  ADD_PATIENT_SUCCESS,
  ANALYTIC_DIAPER_INFO_FAIL,
  ANALYTIC_DIAPER_INFO_SUCCESS,
  ANALYTIC_DIAPER_RESIZE_FAIL,
  ANALYTIC_DIAPER_RESIZE_SUCCESS,
  ANALYTIC_URINE_FAIL,
  ANALYTIC_URINE_SUCCESS,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_SUCCESS,
  GET_PATIENTS,
  GET_PATIENTS_FAIL,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_BY_FACILITY,
  GET_PATIENTS_BY_FACILITY_FAIL,
  GET_PATIENTS_BY_FACILITY_SUCCESS,
  GET_PATIENT_DETAIL,
  GET_PATIENT_DETAIL_FAIL,
  GET_PATIENT_DETAIL_SUCCESS,
  RESET_PATIENT_MESSAGE,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS,
  RESET_LIST_PATIENT,
} from "./actionTypes";

const initialState = {
  patients: [],
  patient: {},
  loadingPatients: false,
  analyticUrine: [],
  analyticDiaperResize: [],
  analyticDiapers: [],
  analyticDiaperInfo: {},
  totalUseLisences: 0,
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const PatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PATIENT_MESSAGE:
      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case GET_PATIENTS:
      state = {
        ...state,
        loadingPatients: true,
      };
      break;
    case GET_PATIENTS_SUCCESS:
      state = { ...state, patients: action.payload.data, loadingPatients: false, totalUseLisences: action.payload.usageLisences };
      break;
    case RESET_LIST_PATIENT:
      state = { ...state, patients: [], loadingPatients: false };
      break;
    case GET_PATIENTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPatients: false,
      };
      break;
    case GET_PATIENTS_BY_FACILITY:
      state = {
        ...state,
        loadingPatients: true,
      };
      break;
    case GET_PATIENTS_BY_FACILITY_SUCCESS:
      state = { ...state, patients: action.payload, loadingPatients: false, totalUseLisences: action.payload };
      break;
    case GET_PATIENTS_BY_FACILITY_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPatients: false,
      };
      break;
    case UPDATE_PATIENT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
      };
      break;
    case UPDATE_PATIENT_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        error: action.payload,
      };
      break;
    case ADD_PATIENT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
      };
      break;
    case ADD_PATIENT_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        error: action.payload,
      };
      break;
    case DELETE_PATIENT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
      };
      break;
    case DELETE_PATIENT_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        error: action.payload,
      };
      break;
    case GET_PATIENT_DETAIL:
      state = { ...state, patient: {} };
      break;
    case GET_PATIENT_DETAIL_SUCCESS:
      state = { ...state, patient: action.payload };
      break;
    case GET_PATIENT_DETAIL_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        error: {
          message: action.payload,
        },
      };
      break;
    case ANALYTIC_URINE_SUCCESS:
      state = { ...state, analyticUrine: action.payload };
      break;
    case ANALYTIC_URINE_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        analyticUrine: [],
        error: action.payload,
      };
      break;
    case ANALYTIC_DIAPER_RESIZE_SUCCESS:
      state = {
        ...state,
        analyticDiaperResize: action.payload?.data,
        analyticDiapers: action.payload?.diapers,
      };
      break;
    case ANALYTIC_DIAPER_RESIZE_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        analyticDiaperResize: [],
        error: action.payload,
      };
      break;
    case ANALYTIC_DIAPER_INFO_SUCCESS:
      state = { ...state, analyticDiaperInfo: action.payload };
      break;
    case ANALYTIC_DIAPER_INFO_FAIL:
      state = {
        ...state,
        loadingPatients: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PatientReducer;
