import {
  exportDataByListFacilities,
  exportDataByListPatients,
  exportDataByDataSensor,
  resetMessages
} from "store/csv/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const CSVApiHook = () => {
  let dispatch = useDispatch();

  const pageInit = () => {
  };

  const exportListFacilities = (data) => {
    dispatch(exportDataByListFacilities(data));
  }

  const exportListPatients = (data) => {
    dispatch(exportDataByListPatients(data));
  }

  const exportDataSensor = (data) => {
    dispatch(exportDataByDataSensor(data));
  }

  const doResetMessage = (data) => {
    dispatch(resetMessages(data));
  }

  return {
    pageInit,
    exportListFacilities,
    exportListPatients,
    exportDataSensor,
    doResetMessage,
  };
};
export default CSVApiHook;
