import Button from "components/button/Button";
import Input from "components/formInput/Input";
import { apiSendCodeDelete, apiSendPhoneDelete } from "helpers/api/accounts";
import useNotify from "hooks/useNotify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const status = {
  SEND_PHONE: "SEND_PHONE",
  SENT_OTP: "SENT_OTP",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
};

const DeletePassword = () => {
  const { t } = useTranslation();
  const { showToastError } = useNotify();

  const [passwordStatus, setPasswordStatus] = useState(status.SEND_PHONE);
  const [otp, setOtp] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  const [phone, setPhone] = useState("");

  const handleGetOtp = () => {
    apiSendPhoneDelete(phone)
      .then((resp) => {
        if (resp?.data) {
          if (passwordStatus !== status.SENT_OTP) {
            setPasswordStatus(status.SENT_OTP);
          }
        } else {
          showToastError(t("delete_account_page.user_not_exist"));
        }
      })
      .catch((err) => {
        showToastError(t('error_msg.account_not_found'));
      });
  };

  const onResend = () => {
    handleGetOtp();
  };

  const handleSendOtp = () => {
    apiSendCodeDelete({code: otp, phone})
      .then((resp) => {
        if (resp?.data) {
          setPasswordStatus(status.DELETE_ACCOUNT_SUCCESS);
          setPhone("");
        } else {
          setIsOtpError(true);
        }
      })
      .catch((err) => {
        showToastError(err.message);
        setIsOtpError(true);
      });
  };

  if (passwordStatus === status.SENT_OTP) {
    return (
      <form className="delete-account-container">
        <div className="header-container">
          <div className="header-container-left">
            <div className="header-item">
              <img src="/icons/logo.png" className="logo-icon" alt="" />
            </div>
            <div className="reset-header-container-item">
              <div className="reset-header-screen-name">
                {t("delete_account_page.title")}
              </div>
            </div>
          </div>
        </div>
        <div className="delete-account-col delete-account-content">
          <div className="delete-account-col">
            <div>
              <Input
                width={400}
                name="opt"
                value={otp}
                placeholder={t("delete_account_page.otp_placeholder")}
                onChange={(e) => setOtp(e.target.value)}
                error={
                  isOtpError ? (
                    <>
                      {t("delete_account_page.error_opt_wrong")}
                      <br />
                      {t("delete_account_page.re_send_opt")}
                    </>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>
          <Button
            key="back"
            text={t("delete_account_page.certificate")}
            type="button"
            background="#0E6FBA"
            width={400}
            disabled={!otp}
            style={{ marginTop: 50 }}
            onClick={handleSendOtp}
          />
          <div className="delete-account-row-link-center">
            <div className="delete-account-link" onClick={onResend}>
              {t("delete_account_page.re_send_otp")}
            </div>
          </div>
        </div>
      </form>
    );
  } else if (passwordStatus === status.DELETE_ACCOUNT_SUCCESS) {
    return (
      <div className="delete-account-container">
        <div className="header-container mb-30">
          <div className="header-container-left">
            <div className="header-item">
              <img src="/icons/logo.png" className="logo-icon" alt="" />
            </div>
            <div className="reset-header-container-item">
              <div className="reset-header-screen-name">
                {t("delete_account_page.title")}
              </div>
            </div>
          </div>
        </div>
        <div className="delete-account-content delete-account-success">
          {t("delete_account_page.delete_success")}
        </div>
      </div>
    );
  }

  return (
    <form className="delete-account-container">
      <div className="header-container">
        <div className="header-container-left">
          <div className="header-item">
            <img src="/icons/logo.png" className="logo-icon" alt="" />
          </div>
          <div className="reset-header-container-item">
            <div className="reset-header-screen-name">
              {t("delete_account_page.title")}
            </div>
          </div>
        </div>
      </div>
      <div className="delete-account-col">
        <div className="delete-account-col delete-account-content">
          <div className="facility-name-label">
            {t("delete_account_page.title_send_phone1")}
            <br />
            {t("delete_account_page.title_send_phone2")}
          </div>
          <div className="mt-20">
            <Input
              width={400}
              name="phone"
              placeholder={t("delete_account_page.phone_placeholder")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <Button
            key="back"
            text={t("delete_account_page.send")}
            type="button"
            background="#0E6FBA"
            width={400}
            disabled={!phone}
            style={{ marginTop: 50 }}
            onClick={handleGetOtp}
          />
        </div>
      </div>
    </form>
  );
};
export default DeletePassword;
