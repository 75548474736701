import { get, post, put, del, patch } from "../api_helper";
import * as url from "../url_helper";

export const apiGetDevices = (data) => {
  let urlApi = url.API_GET_DEVICES;
  if (data?.length > 0) {
    urlApi = urlApi + "?";
    data.forEach((ele) => {
      urlApi += `patient_id=${ele}&`;
    });
  }
  if(data && data.filter){
    urlApi = urlApi + "?filter="+ data.filter;
  }
  return get(urlApi);
};
export const apiGetDevicesByPatient = (data) => {
  let urlApi = url.API_GET_DEVICES_BY_PATIENT;
  if (data?.length > 0) {
    urlApi = urlApi + "?";
    data.forEach((ele) => {
      urlApi += `patient_id=${ele}&`;
    });
  }
  return get(urlApi);
};
export const apiPutDevices = (data) => put(url.API_GET_DEVICES, data);
export const apiPatchDevices = (id, data) =>
  patch(`${url.API_GET_DEVICES}${id}`, data);
export const apiDeleteDevices = (id) => del(`${url.API_GET_DEVICES}${id}`);
export const apiPairDevices = (data) => post(url.API_GET_DEVICES_PAIR, data);
export const apiUnPairDevices = (id, data) =>
  post(`${url.API_DELETE_SENSOR}${id}/unpair`, data);

export const apiGetSettingBatVol = () => get(url.API_GET_SETTING_BATVOL);
export const apiUpdateSettingBatVol = (data) => put(url.API_UPDATE_SETTING_BATVOL, data);
export const apiUpdateSettingAcceleration = (data) => put(url.API_UPDATE_SETTING_ACCELERATION, data);
