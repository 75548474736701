export const RESET_PATIENT_MESSAGE = "RESET_PATIENT_MESSAGE";

export const GET_PATIENTS = "GET_PATIENTS";
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL";

export const ADD_PATIENT = "ADD_PATIENT";
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL";

export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";

export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";

export const GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL";
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS";
export const GET_PATIENT_DETAIL_FAIL = "GET_PATIENT_DETAIL_FAIL";

export const GET_PATIENTS_BY_FACILITY = "GET_PATIENTS_BY_FACILITY";
export const GET_PATIENTS_BY_FACILITY_SUCCESS = "GET_PATIENTS_BY_FACILITY_SUCCESS";
export const GET_PATIENTS_BY_FACILITY_FAIL = "GET_PATIENTS_BY_FACILITY_FAIL";

export const ANALYTIC_URINE = "ANALYTIC_URINE";
export const ANALYTIC_URINE_EXTERNAL = "ANALYTIC_URINE_EXTERNAL";
export const ANALYTIC_URINE_SUCCESS = "ANALYTIC_URINE_SUCCESS";
export const ANALYTIC_URINE_FAIL = "ANALYTIC_URINE_FAIL";

export const ANALYTIC_DIAPER_RESIZE = "ANALYTIC_DIAPER_RESIZE";
export const ANALYTIC_DIAPER_RESIZE_EXTERNAL =
  "ANALYTIC_DIAPER_RESIZE_EXTERNAL";
export const ANALYTIC_DIAPER_RESIZE_SUCCESS = "ANALYTIC_DIAPER_RESIZE_SUCCESS";
export const ANALYTIC_DIAPER_RESIZE_FAIL = "ANALYTIC_DIAPER_RESIZE_FAIL";

export const ANALYTIC_DIAPER_INFO = "ANALYTIC_DIAPER_INFO";
export const ANALYTIC_DIAPER_INFO_EXTERNAL = "ANALYTIC_DIAPER_INFO_EXTERNAL";
export const ANALYTIC_DIAPER_INFO_SUCCESS = "ANALYTIC_DIAPER_INFO_SUCCESS";
export const ANALYTIC_DIAPER_INFO_FAIL = "ANALYTIC_DIAPER_INFO_FAIL";
export const RESET_LIST_PATIENT = "RESET_LIST_PATIENT";
