//Post
export const API_MASTER = "master/";
export const API_PATIENTS = "master/patients/";
export const API_PATIENT_FACILITY = "master/patients/condition";
export const API_ANALYTICS_DIAPER_RESIZE =
  "master/patients/analytics-diaper-resize";

export const API_POST_LOGIN = "auth/signin";

export const API_DIAPERS = "master/diapers/";
export const API_FACILITY = "account/facilities/";
export const API_PARTNER = "account/partners/";

export const API_PAYMENT_MAINTAINCE = "account/facilities/:id/payment-maintenance";

export const API_FACILITY_LINK_REQUEST =
  "master/accounts/facility-link-requests";

export const API_MASTER_AREAS = "master/areas";
export const API_MASTER_DEG = "master/deg/";
export const API_MASTER_STAFFS = "master/staffs/";
export const API_MASTER_GROUPS = "master/groups/";
export const API_MASTER_GROUPS_BY_FACILITY = "master/groups/condition?partner_id=:partnerId&facility_id=:facilityId";

export const API_AUTH_SIGNIN = "master/auth/signin";
export const API_AUTH_SIGNOUT = "master/auth/signout";
export const API_AUTH_UPDATE_SESSION = "master/auth/update-sesssion";
export const API_AUTH_SEND_OTP = "auth/send-otp";

export const API_ACCOUNT = "account/";
export const API_ACCOUNT_USERS = "account/users";
export const API_ACCOUNT_USERS_CHANGE_PHONE = "account/users/change-phone";
export const API_ACCOUNT_USERS_JOIN_FACILITY = "account/users/join-facility/";
export const API_ACCOUNT_FACILITTIES = "account/facilities/";
export const API_ACCOUNT_FACILITTIES_SHARE_CODE =
  "account/facilities/share-code";
export const API_ACCOUNT_FACILITTIES_JOIN_REQUEST =
  "account/facilities/join-request";
export const API_ACCOUNT_FACILITTIES_APPRROVAL_REQUEST =
  "account/facilities/approval-request";
export const API_ACCOUNT_SETTING = "account/setting";
export const API_ACCOUNT_VALIDATE = "account/validate";
export const API_ACCOUNT_RESET_PASSWORD = "account/reset-password";
export const API_ACCOUNT_VALIDATE_PASSWORD = "account/validate-password";
export const API_ACCOUNT_CHANGE_PASSWORD = "account/change-password";
export const API_ACCOUNT_BILLS = "account/bills/";
export const API_ACCOUNT_BILLS_EXPORT = "account/bills/export";

export const API_GET_DEVICES = "devices/";
export const API_GET_DEVICES_BY_PATIENT = "devices/find-by-patient";
export const API_GET_DEVICES_PAIR = "devices/pair";
export const API_DELETE_SENSOR = "devices/";
export const API_GET_SETTING_BATVOL = "devices/setting/";
export const API_UPDATE_SETTING_BATVOL = "devices/setting/bat-vol";
export const API_UPDATE_SETTING_ACCELERATION = "devices/setting/xyz";

export const API_ANALYTIC_URINE = "devices/patient/";
export const API_ANALYTIC_URINE_EXTERNAL = "devices/external/patient/";
export const API_ANALYTIC_DIAPER_RESIZE = "master/patients/";
export const API_ANALYTIC_DIAPER_INFO = "master/patients/";


export const EXPORT_PATIENTS_BY_FACILITY = "master/patients/export-csv";
export const EXPORT_FACILITIES_BY_SANWA = "account/facilities/export-facility-csv?id={id}";
export const EXPORT_FACILITIES_BY_PARTNER = "account/facilities/export-facility-csv";
export const EXPORT_PATIENTS_BY_SENSOR = "devices/export-csv";