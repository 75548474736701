import {
  GET_DEVICES,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  ADD_DEVICES,
  ADD_DEVICES_SUCCESS,
  ADD_DEVICES_FAIL,
  UPDATE_DEVICES,
  UPDATE_DEVICES_SUCCESS,
  UPDATE_DEVICES_FAIL,
  RESET_DEVICE_MESSAGE,
  UNPAIR_DEVICES,
  UNPAIR_DEVICES_SUCCESS,
  UNPAIR_DEVICES_FAIL,
  GET_DEVICES_BY_PATIENT,
  GET_DEVICES_BY_PATIENT_SUCCESS,
  GET_DEVICES_BY_PATIENT_FAIL,
  GET_DEVICES_SETTING_BATVOL,
  GET_DEVICES_SETTING_BATVOL_SUCCESS,
  GET_DEVICES_SETTING_BATVOL_FAIL,
  UPDATE_DEVICES_SETTING_BATVOL,
  UPDATE_DEVICES_SETTING_BATVOL_SUCCESS,
  UPDATE_DEVICES_SETTING_BATVOL_FAIL,
  UPDATE_DEVICES_SETTING_ACCELERATION,
  UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS,
  UPDATE_DEVICES_SETTING_ACCELERATION_FAIL,
  GET_DEVICES_LIST,
  GET_DEVICES_LIST_SUCCESS,
  GET_DEVICES_LIST_FAIL,
} from "./actionTypes";
export const resetMessages = () => {
  return {
    type: RESET_DEVICE_MESSAGE,
  };
};
export const getDevicesList = (payload) => {
  return {
    type: GET_DEVICES_LIST,
    payload,
  };
};

export const getDevicesListSuccess = (devices) => {
  return {
    type: GET_DEVICES_LIST_SUCCESS,
    payload: devices,
  };
};

export const getDevicesListFail = (error) => {
  return {
    type: GET_DEVICES_LIST_FAIL,
    payload: error,
  };
};
export const getDevices = (payload) => {
  return {
    type: GET_DEVICES,
    payload,
  };
};

export const getDevicesSuccess = (devices) => {
  return {
    type: GET_DEVICES_SUCCESS,
    payload: devices,
  };
};

export const getDevicesFail = (error) => {
  return {
    type: GET_DEVICES_FAIL,
    payload: error,
  };
};
export const getDevicesByPatient = (payload) => {
  return {
    type: GET_DEVICES_BY_PATIENT,
    payload,
  };
};

export const getDevicesByPatientSuccess = (devices) => {
  return {
    type: GET_DEVICES_BY_PATIENT_SUCCESS,
    payload: devices,
  };
};

export const getDevicesByPatientFail = (error) => {
  return {
    type: GET_DEVICES_BY_PATIENT_FAIL,
    payload: error,
  };
};
export const addDevices = (payload) => {
  return {
    type: ADD_DEVICES,
    payload,
  };
};

export const addDevicesSuccess = (devices) => {
  return {
    type: ADD_DEVICES_SUCCESS,
    payload: devices,
  };
};

export const addDevicesFail = (error) => {
  return {
    type: ADD_DEVICES_FAIL,
    payload: error,
  };
};
export const updateDevices = (payload) => {
  return {
    type: UPDATE_DEVICES,
    payload,
  };
};

export const updateDevicesSuccess = (devices) => {
  return {
    type: UPDATE_DEVICES_SUCCESS,
    payload: devices,
  };
};

export const updateDevicesFail = (error) => {
  return {
    type: UPDATE_DEVICES_FAIL,
    payload: error,
  };
};
export const unpairDevices = (payload) => {
  return {
    type: UNPAIR_DEVICES,
    payload,
  };
};

export const unpairDevicesSuccess = (payload) => {
  return {
    type: UNPAIR_DEVICES_SUCCESS,
    payload: payload,
  };
};

export const unpairDevicesFail = (error) => {
  return {
    type: UNPAIR_DEVICES_FAIL,
    payload: error,
  };
};

export const getDeviceSettingBatvol = () => {
  return {
    type: GET_DEVICES_SETTING_BATVOL,
  };
};

export const getDeviceSettingBatvolSuccess = (payload) => {
  return {
    type: GET_DEVICES_SETTING_BATVOL_SUCCESS,
    payload,
  };
};

export const getDeviceSettingBatvolFail = (error) => {
  return {
    type: GET_DEVICES_SETTING_BATVOL_FAIL,
    payload: error,
  };
};
export const updateDeviceSettingBatvol = (payload) => {
  return {
    type: UPDATE_DEVICES_SETTING_BATVOL,
    payload,
  };
};

export const updateDeviceSettingBatvolSucess = (payload) => {
  return {
    type: UPDATE_DEVICES_SETTING_BATVOL_SUCCESS,
    payload,
  };
};

export const updateDeviceSettingBatvolFail = (error) => {
  return {
    type: UPDATE_DEVICES_SETTING_BATVOL_FAIL,
    payload: error,
  };
};

export const updateDeviceSettingAcceleration = (payload) => {
  return {
    type: UPDATE_DEVICES_SETTING_ACCELERATION,
    payload,
  };
};

export const updateDeviceSettingAccelerationSucess = (payload) => {
  return {
    type: UPDATE_DEVICES_SETTING_ACCELERATION_SUCCESS,
    payload,
  };
};

export const updateDeviceSettingAccelerationFail = (error) => {
  return {
    type: UPDATE_DEVICES_SETTING_ACCELERATION_FAIL,
    payload: error,
  };
};

