import React from "react";
import "./styles.scss";
import { Loading3QuartersOutlined, LoadingOutlined } from "@ant-design/icons";

const ButtonComponent = (props) => {
  const {
    text,
    onClick,
    color,
    shape,
    styles,
    background,
    borderColor,
    disabled,
    className,
    width,
    style,
    type,
    loading
  } = props;
  return (
    <button
      htmlType={type}
      defaultColor={color}
      shape={shape}
      styles={styles}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={className + " button-common"}
      style={{
        ...style,
        background: background,
        borderColor: borderColor,
        width: width,
      }}
    >
      <span className="button-text">{text}</span>
      {(loading ?? false) && <span style={{ marginInline: 20 }}>
        <LoadingOutlined />
      </span>}
    </button>
  );
};
export default ButtonComponent;
