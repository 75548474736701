import axios from "axios";
import Cookies from "js-cookie";

//apply base url for axios
// const REACT_APP_APP_URL = "https://mock.apidog.com/m1/412751-0-default/api/";
const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
// const REACT_APP_APP_URL = "https://5e8f501dfe7f2a00165ef1cc.mockapi.io/cyan/";
let axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

axiosApi.interceptors.request.use(function (config) {
  const userInfoString = Cookies.get("USER_INFO");
  const lang = localStorage.getItem("LANG");
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    config.headers.Authorization = `Bearer ${userInfo.access_token}`;
  }
  if (lang) {
    config.headers['X-Language'] = lang;
  } else {
    config.headers['X-Language'] = "JP";
  }
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status == 401 &&
      error?.config?.url !== "auth/signin"
    ) {
      const userInfoString = Cookies.get("USER_INFO");
      if (
        userInfoString &&
        !error?.response?.config?.url?.includes("update-session")
      ) {
        const userInfo = JSON.parse(userInfoString);
        console.log("UPDATE_SESSION");
        try {
          post(`${REACT_APP_APP_URL}auth/update-session`, {
            refresh_token: userInfo.refresh_token,
          })
            .then((res) => {
              if (res?.data) {
                let loginInfo = JSON.parse(Cookies.get("USER_INFO"));
                Cookies.set(
                  "USER_INFO",
                  JSON.stringify({
                    ...loginInfo,
                    access_token: res.data.access_token,
                    refresh_token: res.data?.refresh_token,
                  })
                );
                window.location.reload();
              } else {
                Cookies.remove("USER_INFO");
                window.location.href = "/login";
              }
            })
            .catch(async (err) => {
              // await Cookies.remove("USER_INFO");
              // window.location.href = "/login";
            });
        } catch (error) { }
      } else {
        // Cookies.remove("USER_INFO");
        // window.location.href = "/login";
        // return axiosApi.request(error.config);
      }
    } else {
      if (error.response) return Promise.reject(error.response);
      return Promise.reject(error);
    }
  }
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => {
      if (response?.data) return response.data;
    });
  // .catch((error) => {
  //   console.log("api-error", error);
  //   setTimeout(() => {
  //     return Promise.reject(error);
  //   }, 100);
  // });
}
export async function post(url, data, config) {
  return await axiosApi
    .post(url, data, {
      ...config,
    })
    .then((response) => {
      if (response) return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export async function put(url, data, config) {
  return await axiosApi
    .put(url, data, {
      ...config,
    })
    .then((response) => {
      if (response) return response.data;
    });
}
export async function patch(url, data, config) {
  return await axiosApi
    .patch(url, data, {
      ...config,
    })
    .then((response) => {
      if (response) return response.data;
    });
  // .catch((error) => {
  //   setTimeout(() => {
  //     return Promise.reject(error);
  //   }, 500);
  // });
}
export async function del(url, data, config) {
  return await axiosApi
    .delete(url, data, {
      ...config,
    })
    .then((response) => {
      if (response) return response.data;
    });
  // .catch((error) => {
  //   setTimeout(() => {
  //     return Promise.reject(error);
  //   }, 500);
  // });
}
