import {
  RESET_PARTNER_MESSAGE,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAIL,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAIL,
} from "./actionTypes";

const initialState = {
  facilities: [],
  loadingFacilities: false,
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PARTNER_MESSAGE:
      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case GET_PARTNER:
      state = { ...state, loadingPartner: true };
      break;
    case GET_PARTNER_SUCCESS:
      state = {
        ...state,
        partners: action.payload,
        loadingPartner: false,
      };
      break;
    case GET_PARTNER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingPartner: false,
      };
      break;
    case UPDATE_PARTNER_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_PARTNER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ADD_PARTNER_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case ADD_PARTNER_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PartnerReducer;
