import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const FacilityHook = () => {
  const { facilities, facilityByPartner, success, error } = useSelector(
    (state) => state.FacilityReducer
  );

  const [facilityList, setFacilityList] = useState([]);
  const [facilityListByPartner, setFacilityListByPartner] = useState([]);

  const [patientList, setPatientList] = useState([]);

  useEffect(() => {
    setFacilityList(facilities);
  }, [facilities]);

  useEffect(() => {
    setFacilityListByPartner(facilityByPartner);
  }, [facilityByPartner]);

  return { facilityList, facilityListByPartner, patientList, success, error };
};
export default FacilityHook;
