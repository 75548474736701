import React, { useEffect, useState } from "react";
import "./styles.scss";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = (props) => {
  const {
    onChange,
    title,
    placeholder,
    error,
    name,
    value,
    id,
    minDate,
    maxDate,
  } = props;
  let datepicker = document.getElementById(id);
  useEffect(() => {
    if (datepicker) {
      datepicker.style.height = "28px";
      datepicker.style.border = "1px solid #0e6fba";
      datepicker.style.borderRadius = "6px";
      datepicker.style.paddingLeft = "6px";
    }
  }, [datepicker]);
  return (
    <div className="datepicker-container">
      {title && <div className="datepicker-title">{title}</div>}
      <DatePicker
        id={id}
        name={name}
        className={error ? "error-text" : ""}
        selected={value}
        dateFormat="yyyy/MM/dd"
        onChange={onChange}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
      {error ? <span className="datepicker-error-message">{error}</span> : null}
    </div>
  );
};
export default DatePickerComponent;
