
import './styles.scss';

const Lable = (props) => {
    const {
        label
    } = props;

    return <div class="component-label">
        {label} :
    </div>
}

export default Lable;