import { useTranslation } from "react-i18next";

export const LOGOUT = "/logout";

export const MenuOnlyFirst = () => {
    const { t } = useTranslation();
    return [
        {
            key: "LOGOUT",
            title: t("menus.logout"),
            url: LOGOUT,
            childs: [],
        },
    ];
}