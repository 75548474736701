import {
  addMasterGroup,
  getGroups,
  getGroups2,
  resetMasterMessage,
  updateMasterGroup,
} from "store/master/actions";
import { useDispatch } from "react-redux";

const GroupApiHook = () => {
  let dispatch = useDispatch();

  const getListGroup = (data) => {
    dispatch(getGroups(data));
  };

  const getListGroup2 = (data) => {
    dispatch(getGroups2(data));
  };

  const addGroup = (data) => {
    dispatch(addMasterGroup(data));
  };
  const updateGroup = (data) => {
    dispatch(updateMasterGroup(data));
  };
  const resetMessage = () => {
    dispatch(resetMasterMessage());
  };
  return {
    getListGroup,
    getListGroup2,
    resetMessage,
    addGroup,
    updateGroup,
  };
};
export default GroupApiHook;
