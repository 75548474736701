export const RESET_PARTNER_MESSAGE = "RESET_PARTNER_MESSAGE";

export const GET_PARTNER = "GET_PARTNER";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_FAIL = "GET_PARTNER_FAIL";

export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAIL = "UPDATE_PARTNER_FAIL";

export const ADD_PARTNER = "ADD_PARTNER";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAIL = "ADD_PARTNER_FAIL";