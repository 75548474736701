import React, { useState } from "react";
import { Input, InputNumber } from "antd";

import "./styles.scss";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const InputComponent = (props) => {
  const {
    title,
    titleSize,
    width,
    placeholder,
    type,
    onEnter,
    error,
    subtitle,
    des,
    value,
    name,
    onChange,
    disabled,
    suffix,
    onlyInteger,
  } = props;
  const onKeyUp = (e) => {
    if (e.code === "Enter" && onEnter) {
      onEnter();
    }
  };

  const [passState, setPassState] = useState(false);
  const [inputType, setInputType] = useState(type);

  const changePassState = (value) => {
    setPassState(value);
    setInputType(value ? "input" : "password");
  };
  let className = type === "password" ? "pr-30" : "";
  const handleChange = (e) => {
    if (
      (type === "password" || name === "password") &&
      e.target.value?.includes(" ")
    ) {
      return;
    } else if (type === "number" && onlyInteger) {
      e.target.value = parseInt(e.target.value);
      onChange(e);
    } else {
      onChange(e);
    }
  };
  return (
    <div
      className="form-input-container"
      style={{ width: des ? width + 82 : width ? width : "100%" }}
    >
      <div
        className="input-title"
        style={{ fontSize: titleSize || 16, display: title ? "" : "none" }}
      >
        {title}
      </div>
      {subtitle && <div className="input-subtitle">{subtitle}</div>}

      <div className="input-and-description">
        <Input
          className={error ? `${className} error-text` : className}
          placeholder={placeholder}
          type={inputType}
          width={width || "100%"}
          onKeyUp={onKeyUp}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          suffix={suffix}
        />
        {type === "password" && !passState && (
          <div className="pass-eye">
            <EyeOutlined onClick={() => changePassState(true)} />
          </div>
        )}
        {type === "password" && passState && (
          <div className="pass-eye">
            <EyeInvisibleOutlined onClick={() => changePassState(false)} />
          </div>
        )}
        {des && <div className="des-text">({des})</div>}
      </div>
      {error ? <span className="input-error-message">{error}</span> : null}
    </div>
  );
};
export default InputComponent;
