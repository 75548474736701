import { get, post, patch } from "../api_helper";
import * as url from "../url_helper";

export const postLogin = (payload) => post(url.API_POST_LOGIN, payload);
export const apiGetAccountInfo = (config) => get(url.API_ACCOUNT, config);
export const apiChangePassword = (config) =>
  post(url.API_ACCOUNT_CHANGE_PASSWORD, config);
export const apiSendOtp = (payload) => post(url.API_AUTH_SEND_OTP, payload);
export const apiResetPassword = (payload) =>
  post(url.API_ACCOUNT_RESET_PASSWORD, payload);
export const apiGetBill = () => get(url.API_ACCOUNT_BILLS);
export const apiZipBill = (data) =>
  post(url.API_ACCOUNT_BILLS_EXPORT, data, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
    },
  });
export const apiUpdateBill = (id, data) =>
  patch(url.API_ACCOUNT_BILLS + id, data);
export const apiCheckUser = (id) =>
  get(`${url.API_ACCOUNT}validate?email=${id}`);

export const apiSendPhoneDelete = (phone) => 
  post(`${url.API_ACCOUNT_USERS}/delete-account-send-otp`, {phone});

export const apiSendCodeDelete = (body) => 
  post(`${url.API_ACCOUNT_USERS}/is-active-account`, body);

