import { EXPORT_PATIENTS_BY_FACILITY } from "helpers/url_helper";
import {
  EXPORT_FOR_FACILITIES,
  EXPORT_FOR_PATIENTS_FACILITY,
  EXPORT_FOR_SENSOR,
  GET_EXPORTED_LINK_FAILED,
  GET_EXPORTED_LINK_SUCCESSFULLY,
  RESET_DEVICE_MESSAGE
} from "./actionTypes";

const initialState = {
  inProgressExport: false,
  link: "",
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const ExportCSVReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DEVICE_MESSAGE:
      console.log('RESET_DEVICE_MESSAGE')

      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case EXPORT_FOR_FACILITIES:
      state = { ...state, inProgressExport: true };
      break;
    case EXPORT_FOR_PATIENTS_FACILITY:
      state = { ...state, inProgressExport: true };
      break;
    case EXPORT_FOR_SENSOR:
      state = { ...state, inProgressExport: true };
      break;
    case GET_EXPORTED_LINK_SUCCESSFULLY:
      state = {
        ...state, success: {
          type: "GET",
          message: 'exported successfully'
        }, link: action.payload, inProgressExport: false
      }
      break;
    case GET_EXPORTED_LINK_FAILED:
      state = {
        ...state, error: { messgae: action, code: 400 }, inProgressExport: false
      }
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default ExportCSVReducer;
