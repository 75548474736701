import {
  getPartner,
  updatePartner,
  resetPartnerMessage,
} from "store/partners/actions";
import { useDispatch } from "react-redux";

const PartnerApiHook = () => {
  let dispatch = useDispatch();
  const pageInit = () => {
    dispatch(getPartner());
  };
  const getListPartner = (data) => {
    dispatch(getPartner(data));
  };
  const apiUpdatePartner = (data) => {
    dispatch(updatePartner(data));
  };
  const resetMessage = () => {
    dispatch(resetPartnerMessage());
  };
  return {
    pageInit,
    getListPartner,
    apiUpdatePartner,
    resetMessage,
  };
};
export default PartnerApiHook;
