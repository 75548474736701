import {
  RESET_FACILITY_MESSAGE,
  GET_FACILITIES,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAIL,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  ADD_FACILITY_SUCCESS,
  ADD_FACILITY_FAIL,
  GET_FACILITIES_BY_PARTNER,
  GET_FACILITIES_BY_PARTNER_FAIL,
  GET_FACILITIES_BY_PARTNER_SUCCESS,
  UPDATE_FACILITY_PAYMENT,
  UPDATE_FACILITY_PAYMENT_FAIL,
  UPDATE_FACILITY_PAYMENT_SUCCESS,
} from "./actionTypes";

const initialState = {
  facilities: [],
  loadingFacilities: false,
  success: {
    message: "",
  },
  error: {
    message: "",
  },
};

const FacilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FACILITY_MESSAGE:
      state = {
        ...state,
        success: {
          message: "",
        },
        error: {
          message: "",
        },
      };
      break;
    case GET_FACILITIES:
      state = { ...state, loadingFacilities: true };
      break;
    case GET_FACILITIES_SUCCESS:
      state = {
        ...state,
        facilities: action.payload,
        loadingFacilities: false,
      };
      break;
    case GET_FACILITIES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingFacilities: false,
      };
      break;
    case GET_FACILITIES_BY_PARTNER:
      state = { ...state, loadingFacilities: true };
      break;
    case GET_FACILITIES_BY_PARTNER_SUCCESS:
      state = {
        ...state,
        facilityByPartner: action.payload,
        loadingFacilities: false,
      };
      break;
    case GET_FACILITIES_BY_PARTNER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingFacilities: false,
      };
      break;
    case UPDATE_FACILITY_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case UPDATE_FACILITY_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ADD_FACILITY_SUCCESS:
      state = { ...state, success: action.payload || [] };
      break;
    case ADD_FACILITY_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;

    case UPDATE_FACILITY_PAYMENT:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPDATE_FACILITY_PAYMENT_SUCCESS:
      state = {
        ...state,
        success: {
          message: action.payload,
        },
      };
      break;
    case UPDATE_FACILITY_PAYMENT_FAIL:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FacilityReducer;
