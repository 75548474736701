/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { Menu } from "antd";
import { LOGOUT } from "./Constant";
import MenuList from "./MenuList";
import MenuListAdmin from "./MenuListAdmin";
import MenuListPartner from "./MenuListPartner";
import "./styles.scss";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { MenuOnlyFirst } from "./MenuOnlyFirst";
import { isAdminSanwa } from "utils";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const menus = () => {
  const userInfoString = Cookies.get("USER_INFO");
  const menuList = MenuList();
  const menuListAdmin = MenuListAdmin();
  const menuListPartner = MenuListPartner();
  const menuFirstOnly = MenuOnlyFirst();

  let userInfo = null;
  userInfo = JSON.parse(userInfoString);
  let menus = null;

  if (!userInfo.confirm_info && !isAdminSanwa(userInfo)) {
    menus = menuFirstOnly;
  } else
    if (userInfo.role == "ROLE_FACILITY" && userInfo.is_sys_admin) {
      menus = menuListAdmin;
    } else if (userInfo.role == "ROLE_FACILITY" && !userInfo.is_sys_admin) {
      menus = menuList;
    } else if (userInfo.role == "ROLE_PARTNER" && !userInfo.is_sys_admin) {
      menus = menuListPartner;
    }
  return menus.map((menu) => {
    if (menu.childs?.length === 0) {
      return getItem(menu.title, menu.url, null);
    }
    return getItem(
      menu.title,
      menu.url,
      null,
      menu.childs.map((child) => {
        return getItem(child.title, child.url, null);
      })
    );
  });
};
const App = () => {
  const history = useHistory();
  const location = useLocation();
  const isMainScreen = location.pathname === "/";
  const [showMenu, setShowMenu] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => { }, []);

  const onClickMenu = ({ keyPath }) => {
    if (keyPath[0] === LOGOUT) {
      localStorage.clear()
      Cookies.remove("USER_INFO");
      window.location.href = "/login";
      return;
    }
    const reversed = keyPath.reverse();
    const path = reversed.join("");
    history.push(path);
    setShowMenu(false);
  };
  return (
    <div className="menu-containner" ref={wrapperRef}>
      <img
        src="/icons/menu.png"
        className="menu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div style={{ display: !showMenu ? "none" : "" }}>
        <div
          style={{
            width: "65.1347px",
            height: "27.9855px",
            marginLeft: "0px",
            marginTop: "0px",
            top: isMainScreen ? "70px" : "55px",
            left: "25px",
            position: "absolute",
          }}
        >
          <div
            style={{
              clipPath: 'path("M 128 0 L 256 256 L 0 256 L 128 0 Z")',
              background: "rgb(230, 230, 230)",
              width: "256px",
              height: "256px",
              transform: "scale(0.254432, 0.109318)",
              transformOrigin: "0px 0px",
              touchAction: "pan-x pan-y pinch-zoom",
            }}
          ></div>
        </div>
        <Menu
          style={{
            width: 256,
          }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode={"inline"}
          theme={"light"}
          items={menus()}
          onClick={onClickMenu}
        />
      </div>
    </div>
  );
};
export default App;
