import { takeLatest, put, call } from "redux-saga/effects";

import {
  CHANGE_PASSWORD,
  GET_ACCOUNT_INFO,
  GET_BILLS,
  RESET_PASSWORD,
  SEND_OTP,
  UPDATE_BILL,
  USER_LOGIN,
} from "./actionTypes";
import {
  loginSuccess,
  loginFail,
  getAccountInfoSuccess,
  getAccountInfoFail,
  changePasswordSuccess,
  changePasswordFail,
  sendOtpSuccess,
  sendOtpFail,
  resetPasswordSuccess,
  resetPasswordFail,
  getBillsSuccess,
  getBillsFail,
  updateBillSuccess,
  updateBillFail,
} from "./actions";

import {
  postLogin,
  apiGetAccountInfo,
  apiChangePassword,
  apiSendOtp,
  apiResetPassword,
  apiGetBill,
  apiUpdateBill,
} from "../../helpers/api/accounts";

function* onLogin({ payload }) {
  try {
    const response = yield call(postLogin, payload);
    if (response?.data) {
      yield put(loginSuccess(response.data));
    } else {
      yield put(loginFail({ code: 401 }));
    }
  } catch (error) {
    yield put(loginFail(error?.data?.error || { code: 400 }));
  }
}
function* onGetAccountInfo({ payload }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${payload}`,
      },
    };
    const response = yield call(apiGetAccountInfo, config);
    if (response?.data) {
      yield put(getAccountInfoSuccess(response.data));
    } else {
      yield put(getAccountInfoFail("get account failed"));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetAccountInfo(payload);
    }
    yield put(getAccountInfoFail(error.response));
  }
}
function* onChangePassword({ payload }) {
  try {
    const response = yield call(apiChangePassword, payload);
    if (response?.data) {
      yield put(changePasswordSuccess({ code: 200 }));
    } else {
      yield put(changePasswordFail({ code: 400 }));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onChangePassword(payload);
    }
    yield put(changePasswordFail(error?.data?.error));
  }
}
function* onSendOtp({ payload }) {
  try {
    const response = yield call(apiSendOtp, payload);

    if (response?.data) {
      yield put(sendOtpSuccess("OTPコードが送信されました。"));
    } else {
      yield put(sendOtpFail("サーバーへ転送したデータが無効です。"));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onSendOtp(payload);
    }
    yield put(sendOtpFail(error?.data?.error));
  }
}
function* onResetPassword({ payload }) {
  try {
    const response = yield call(apiResetPassword, payload);
    console.log("responseresponse", response);
    if (response?.data) {
      yield put(resetPasswordSuccess(response?.data));
    } else {
      yield put(resetPasswordFail("Reset password failed"));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onResetPassword(payload);
    }
    yield put(resetPasswordFail(error?.data?.error || { code: 422 }));
  }
}
function* onGetBills({ payload }) {
  try {
    const response = yield call(apiGetBill, payload);
    console.log("response get bills:", response);
    if (response?.data) {
      yield put(getBillsSuccess(response?.data));
    } else {
      yield put(getBillsFail("Get bills failed"));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetBills(payload);
    }
    yield put(getBillsFail(error?.data?.error?.message || "Get bills failed"));
  }
}
function* onUpdateBill({ payload }) {
  try {
    const response = yield call(apiUpdateBill, payload.id, payload.data);
    if (response?.data) {
      yield put(
        updateBillSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      const responseGet = yield call(apiGetBill, payload);
      if (response?.data) {
        yield put(getBillsSuccess(responseGet?.data));
      } else {
        yield put(getBillsFail("Get bills failed"));
      }
    } else {
      yield put(updateBillFail({ code: 400 }));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateBill(payload);
    }
    yield put(updateBillFail(error?.data?.error || { code: 400 }));
  }
}
function* UserSaga() {
  yield takeLatest(USER_LOGIN, onLogin);
  yield takeLatest(GET_ACCOUNT_INFO, onGetAccountInfo);
  yield takeLatest(CHANGE_PASSWORD, onChangePassword);
  yield takeLatest(SEND_OTP, onSendOtp);
  yield takeLatest(RESET_PASSWORD, onResetPassword);
  yield takeLatest(GET_BILLS, onGetBills);
  yield takeLatest(UPDATE_BILL, onUpdateBill);
}

export default UserSaga;
