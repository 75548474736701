
import FormPatientDetail from "pages/home/components/formPatientDetail/FormPatientDetail";
import "./styles.scss";
import useNotify from "hooks/useNotify";
import { useDispatch } from "react-redux";
import HomeHook from "hooks/home/homeHook";
import { useEffect, useState, useTransition } from "react";
import HomeApiHook from "hooks/home/homeApiHook";
import { getGroupByFacility, getGroups, getGroups2 } from "store/master/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import GroupHook from "hooks/group/groupHook";
import { apiGetDetailPatient, apiGetPatientFacility } from "helpers/api/master/patients";
// import find from "local-devices";

// var postal_code = require("japan-postal-code");
const PatientDetail = () => {
  const { pageInit, pageInitAdmin, callGetPatients, callGetDevices, callGetPatientByFacility } = HomeApiHook();
  const [patientEditting, setPatientEditting] = useState({});
  const { patientList, state, usageLisences } = HomeHook();
  let dispatch = useDispatch();
  const { showToastSuccess, showToastError } = useNotify();
  const { devices, groups, areas, master, diapers } = state;
  const { groupList2 } = GroupHook();
  const [facilityId, setFacilityId] = useState('');
  const [partnerId, setPartnerId] = useState('');



  useEffect(() => {
    const paramFilter = localStorage.getItem("PATIENT_PARAM_ADMIN2");

    const data = JSON.parse(paramFilter)

    dispatch(getGroups2({ facility_id: data?.facility_id, partner_id: data?.partner_id }));

    const id = window.location.pathname.split('/')[2];

    apiGetDetailPatient(id)
      .then((res) => {
        if (res) {
          setPatientEditting(res.data)
        }
      })
    pageInitAdmin();
  }, [])
  const history = useHistory()

  const { t } = useTranslation();
  return (
    <div className="detail-container">
      <div className="detail-header">
        <a onClick={history.goBack}>
          <LeftOutlined />
          <span className="detail-header-back">{t('button.back')}</span>
        </a>
      </div>
      <div className="detail-content">
        <FormPatientDetail
          setShowModalUpdatePatient={true}
          areas={areas}
          devices={devices}
          master={master}
          groups={groupList2}
          diapers={diapers}
          patientEditting={patientEditting}
          setPatientEditting={setPatientEditting}
        />
      </div>
    </div>

  )
};
export default PatientDetail;
