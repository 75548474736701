import { takeLatest, put, call } from "redux-saga/effects";
import { isEmpty } from "lodash";
import {
  ADD_MASTER_DIAPER,
  ADD_MASTER_GROUP,
  ADD_MASTER_STAFF,
  DELETE_MASTER_STAFF,
  GET_GROUPS,
  GET_GROUPS2,
  GET_GROUPS_BY_FACILITY,
  GET_MASTER,
  GET_MASTER_AREA,
  GET_MASTER_DEG,
  GET_MASTER_DIAPER,
  GET_MASTER_STAFF,
  UPDATE_MASTER_DEG,
  UPDATE_MASTER_DIAPER,
  UPDATE_MASTER_GROUP,
  UPDATE_MASTER_STAFF,
} from "./actionTypes";
import {
  getGroupsSuccess,
  getGroupsFail,
  getGroupByFacilitySuccess,
  getGroupByFacilityFail,
  getMasterSuccess,
  getMasterFail,
  getMasterAreaSuccess,
  getMasterAreaFail,
  getMasterDiaperSuccess,
  getMasterDiaperFail,
  getMasterStaffSuccess,
  getMasterStaffFail,
  updateMasterStaffSuccess,
  updateMasterStaffFail,
  addMasterStaffSuccess,
  addMasterStaffFail,
  deleteMasterStaffSuccess,
  addMasterGroupSuccess,
  addMasterGroupFail,
  updateMasterGroupFail,
  updateMasterGroupSuccess,
  updateMasterDiaperSuccess,
  addMasterDiaperSuccess,
  addMasterDiaperFail,
  getMasterDegSuccess,
  getMasterDegFail,
  updateMasterDegSuccess,
  updateMasterDegFail,
  getGroupsSuccess2,
} from "./actions";

import {
  apiGetGroup,
  apiPutGroup,
  apiPatchGroup,
  apiGetGroupByFacility
} from "../../helpers/api/master/group";
import {
  apiGetMaster,
  apiGetMasterArea,
  apiGetMasterDeg,
  apiUpdateMasterDeg,
} from "../../helpers/api/master/master";
import {
  apiGetDiapers,
  apiPatchDiapers,
  apiPutDiapers,
} from "../../helpers/api/master/diapers";
import {
  apiGetStaff,
  apiPatchStaff,
  apiPutStaff,
  apiDelStaff,
} from "../../helpers/api/master/staff";

function* onGetGroups(payload) {
  try {
    const response = yield call(apiGetGroup, payload?.payload);
    if (response) yield put(getGroupsSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetGroups(payload);
    }
    yield put(getGroupsFail(error?.data?.error || { code: 400 }));
  }
}



function* onGetGroups2(payload) {
  try {
    const response = yield call(apiGetGroup, payload?.payload);
    if (response) yield put(getGroupsSuccess2(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetGroups(payload);
    }
    yield put(getGroupsFail(error?.data?.error || { code: 400 }));
  }
}

function* onGetGroupByFacility(payload) {
  try {
    const response = yield call(apiGetGroupByFacility, payload?.payload);
    if (response) yield put(getGroupByFacilitySuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetGroupByFacility(payload);
    }
    yield put(getGroupByFacilityFail(error?.data?.error || { code: 400 }));
  }
}

function* onGetMaster() {
  try {
    const response = yield call(apiGetMaster);
    if (response) {
      yield put(getMasterSuccess(response));
      if (response?.data && !isEmpty(response?.data)) {
        localStorage.setItem("MASTER", JSON.stringify(response.data));
      }
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetMaster();
    }
    yield put(getMasterFail(error?.data?.error || { code: 400 }));
  }
}
function* onGetMasterArea() {
  try {
    const response = yield call(apiGetMasterArea);
    if (response) yield put(getMasterAreaSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetMasterArea();
    }
    yield put(getMasterAreaFail(error?.data?.error || { code: 400 }));
  }
}
function* onGetMasterDiaper(payload) {
  try {
    const response = yield call(apiGetDiapers, payload?.payload);
    if (response) yield put(getMasterDiaperSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetMasterDiaper(payload);
    }
    yield put(getMasterDiaperFail(error?.data?.error || { code: 400 }));
  }
}
function* onGetMasterStaffs(payload) {
  try {
    const response = yield call(apiGetStaff, payload?.payload);
    if (response) yield put(getMasterStaffSuccess(response));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetMasterStaffs(payload);
    }
    yield put(getMasterStaffFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdateMasterStaffs(payload) {
  try {
    const response = yield call(
      apiPatchStaff,
      payload?.payload.id,
      payload?.payload.data
    );
    const responseGet = yield call(apiGetStaff);
    if (response && responseGet) {
      yield put(
        updateMasterStaffSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getMasterStaffSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateMasterStaffs(payload);
    }
    yield put(updateMasterStaffFail(error?.data?.error || { code: 400 }));
  }
}
function* onAddMasterStaffs(payload) {
  try {
    const response = yield call(apiPutStaff, payload?.payload);
    const responseGet = yield call(apiGetStaff);
    if (response && responseGet) {
      yield put(
        addMasterStaffSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
      yield put(getMasterStaffSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddMasterStaffs(payload);
    }
    yield put(addMasterStaffFail(error?.data?.error || { code: 400 }));
  }
}
function* onDeleteMasterStaffs(payload) {
  try {
    const response = yield call(apiDelStaff, payload?.payload);
    const responseGet = yield call(apiGetStaff);
    if (response && responseGet) {
      yield put(
        deleteMasterStaffSuccess({
          message: response.data?.message || "",
          type: "DELETE",
        })
      );
      yield put(getMasterStaffSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onDeleteMasterStaffs(payload);
    }
    yield put(addMasterStaffFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdateMasterGroups(payload) {
  try {
    const response = yield call(
      apiPatchGroup,
      payload?.payload.id,
      payload?.payload.data
    );
    const responseGet = yield call(apiGetGroup);
    if (response && responseGet) {
      yield put(
        updateMasterGroupSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getGroupsSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateMasterGroups(payload);
    }
    yield put(updateMasterGroupFail(error?.data?.error || { code: 400 }));
  }
}
function* onAddMasterGroups(payload) {
  try {
    const response = yield call(apiPutGroup, payload?.payload);
    const responseGet = yield call(apiGetGroup);
    if (response && responseGet) {
      yield put(
        addMasterGroupSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
      yield put(getGroupsSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddMasterGroups(payload);
    }
    yield put(
      addMasterGroupFail(error?.data?.error || { type: "ADD", code: 400 })
    );
  }
}
function* onUpdateMasterDiaper(payload) {
  try {
    const response = yield call(
      apiPatchDiapers,
      payload?.payload.id,
      payload?.payload.data
    );
    const responseGet = yield call(apiGetDiapers);
    if (response && responseGet) {
      yield put(
        updateMasterDiaperSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getMasterDiaperSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateMasterDiaper(payload);
    }
    yield put(
      updateMasterGroupFail(error?.data?.error || { type: "UPDATE", code: 400 })
    );
  }
}
function* onAddMasterDiaper(payload) {
  try {
    const response = yield call(apiPutDiapers, payload?.payload);
    const responseGet = yield call(apiGetDiapers);
    if (response && responseGet) {
      yield put(
        addMasterDiaperSuccess({
          message: response.data?.message || "",
          type: "ADD",
        })
      );
      yield put(getMasterDiaperSuccess(responseGet));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onAddMasterDiaper(payload);
    }
    console.log("error add", error);
    yield put(addMasterDiaperFail(error?.data?.error || { code: 400 }));
  }
}
function* onGetMasterDeg() {
  try {
    const response = yield call(apiGetMasterDeg);
    if (response) yield put(getMasterDegSuccess(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      onGetMasterDeg();
    }
    yield put(getMasterDegFail(error?.data?.error || { code: 400 }));
  }
}
function* onUpdateMasterDeg(payload) {
  try {
    const response = yield call(apiUpdateMasterDeg, payload?.payload);
    const responseGet = yield call(apiGetMasterDeg);
    if (response && responseGet) {
      yield put(
        updateMasterDegSuccess({
          message: response.data?.message || "",
          type: "UPDATE",
        })
      );
      yield put(getMasterDegSuccess(responseGet?.data));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      onUpdateMasterDeg(payload);
    }
    yield put(updateMasterDegFail(error?.data?.error || { code: 400 }));
  }
}
function* MasterSaga() {
  yield takeLatest(GET_GROUPS, onGetGroups);
  yield takeLatest(GET_GROUPS2, onGetGroups2);
  yield takeLatest(GET_GROUPS_BY_FACILITY, onGetGroupByFacility);
  yield takeLatest(GET_MASTER, onGetMaster);
  yield takeLatest(GET_MASTER_AREA, onGetMasterArea);
  yield takeLatest(GET_MASTER_DIAPER, onGetMasterDiaper);
  yield takeLatest(GET_MASTER_STAFF, onGetMasterStaffs);
  yield takeLatest(UPDATE_MASTER_STAFF, onUpdateMasterStaffs);
  yield takeLatest(ADD_MASTER_STAFF, onAddMasterStaffs);
  yield takeLatest(DELETE_MASTER_STAFF, onDeleteMasterStaffs);
  yield takeLatest(UPDATE_MASTER_GROUP, onUpdateMasterGroups);
  yield takeLatest(ADD_MASTER_GROUP, onAddMasterGroups);
  yield takeLatest(UPDATE_MASTER_DIAPER, onUpdateMasterDiaper);
  yield takeLatest(ADD_MASTER_DIAPER, onAddMasterDiaper);
  yield takeLatest(GET_MASTER_DEG, onGetMasterDeg);
  yield takeLatest(UPDATE_MASTER_DEG, onUpdateMasterDeg);
}

export default MasterSaga;
