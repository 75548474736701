import {
  RESET_FACILITY_MESSAGE,
  GET_FACILITIES,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAIL,
  UPDATE_FACILITY,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  ADD_FACILITY,
  ADD_FACILITY_SUCCESS,
  ADD_FACILITY_FAIL,
  GET_FACILITIES_BY_PARTNER,
  GET_FACILITIES_BY_PARTNER_SUCCESS,
  GET_FACILITIES_BY_PARTNER_FAIL,
  UPDATE_FACILITY_PAYMENT,
  UPDATE_FACILITY_PAYMENT_FAIL,
  UPDATE_FACILITY_PAYMENT_SUCCESS,
} from "./actionTypes";

export const resetFacilityMessage = () => {
  return {
    type: RESET_FACILITY_MESSAGE,
  };
};

export const getFacilities = (payload) => {
  return {
    type: GET_FACILITIES,
    payload,
  };
};

export const getFacilitiesSuccess = (Facilities) => {
  return {
    type: GET_FACILITIES_SUCCESS,
    payload: Facilities,
  };
};

export const getFacilitiesFail = (error) => {
  return {
    type: GET_FACILITIES_FAIL,
    payload: error,
  };
};

export const getFacilityByPartner = (payload) => {
  return {
    type: GET_FACILITIES_BY_PARTNER,
    payload,
  };
};

export const getFacilitiesByPartnerSuccess = (Facilities) => {
  return {
    type: GET_FACILITIES_BY_PARTNER_SUCCESS,
    payload: Facilities,
  };
};

export const getFacilityByPartnerFail = (error) => {
  return {
    type: GET_FACILITIES_BY_PARTNER_FAIL,
    payload: error,
  };
};

export const updateFacility = (payload) => {
  return {
    type: UPDATE_FACILITY,
    payload,
  };
};

export const updateFacilitySuccess = (facility) => {
  return {
    type: UPDATE_FACILITY_SUCCESS,
    payload: facility,
  };
};

export const updateFacilityFail = (error) => {
  return {
    type: UPDATE_FACILITY_FAIL,
    payload: error,
  };
};

export const addFacility = (payload) => {
  return {
    type: ADD_FACILITY,
    payload,
  };
};

export const addFacilitySuccess = (facility) => {
  return {
    type: ADD_FACILITY_SUCCESS,
    payload: facility,
  };
};

export const addFacilityFail = (error) => {
  return {
    type: ADD_FACILITY_FAIL,
    payload: error,
  };
};
export const updatePaymentStatus = (payload) => {
  return {
    type: UPDATE_FACILITY_PAYMENT,
    payload,
  };
};

export const updatePaymentStatusSuccess = (payload) => {
  return {
    type: UPDATE_FACILITY_PAYMENT_SUCCESS,
    payload,
  };
};

export const updatePaymentStatusFail = (error) => {
  return {
    type: UPDATE_FACILITY_PAYMENT_FAIL,
    payload: error,
  };
};
